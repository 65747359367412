/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Transaction } from 'clientportalshared';
import { DateTime } from 'luxon';
import { MUIDataTableColumn as MUIDataTableColumnBase } from 'mui-datatables';
import {
  DateRangePicker,
  NumberRangePicker,
  customRender,
  customUpdate,
} from '@components/datatable/dataTableUtils';
import { DateTimeFormats } from '@enums/index';
import { dateFormatter, numberFormatterDollar, numberFormatterHoldings } from '@utils/GenericUtils';
import strings from '@strings/LocalisedStrings';
import { Typography } from '@mui/material';

interface MUIDataTableColumn extends MUIDataTableColumnBase {
  name: keyof Transaction;
}

const getColumns = (data: Transaction[]): MUIDataTableColumn[] => {
  const columns: MUIDataTableColumn[] = [
    {
      name: 'tradeDate',
      label: strings.date,
      options: {
        filterType: 'custom',
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <Typography variant="body1" align="left" style={{ width: '80px', textAlign: 'left' }}>
              {strings.date}
            </Typography>
          );
        },
        customBodyRenderLite: (dataIndex) => {
          return (
            <div>
              <Typography variant={'body2'} style={{ paddingRight: '20px' }}>
                {dateFormatter(data[dataIndex].tradeDate ?? '')}
              </Typography>
            </div>
          );
        },
        customBodyRender: (value) => dateFormatter(value),
        customFilterListOptions: {
          render: (filters) => customRender(filters, strings.date, dateFormatter),
          update: customUpdate,
        },
        filterOptions: {
          fullWidth: true,
          logic: (columnDate, filters) => {
            if (filters.length < 2 || !filters[0] || !filters[1]) return false;

            const columnDateConverted = DateTime.fromFormat(columnDate, DateTimeFormats.DateOnly);
            const dateRangeFrom = DateTime.fromISO(filters[0]);
            const dateRangeTo = DateTime.fromISO(filters[1]);

            if (columnDateConverted >= dateRangeFrom && columnDateConverted <= dateRangeTo)
              return false;

            return true;
          },
          display: (filterList, onChange, index, column) => (
            <DateRangePicker
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
            />
          ),
        },
      },
    },
    {
      name: 'description',
      label: strings.description,
      options: {
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return <Typography variant="body1">{strings.description}</Typography>;
        },
        customBodyRenderLite: (dataIndex) => {
          return (
            <div>
              <Typography variant={'body2'} style={{ paddingRight: '20px' }}>
                {data[dataIndex].description}
              </Typography>
            </div>
          );
        },
        filter: false,
        filterType: 'textField',
        filterOptions: {
          fullWidth: true,
        },
      },
    },
    {
      name: 'type',
      label: strings.transactionType,
      options: {
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return <Typography variant="body1">{strings.transactionType}</Typography>;
        },
        customBodyRenderLite: (dataIndex) => {
          return (
            <div>
              <Typography variant={'body2'} style={{ paddingRight: '20px' }}>
                {data[dataIndex].typeDescription}
              </Typography>
            </div>
          );
        },
        filterOptions: {
          fullWidth: true,
        },
      },
    },
    {
      name: 'units',
      label: strings.quantity,
      options: {
        filterType: 'custom',
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <Typography
              variant="body1"
              align="right"
              style={{ textAlign: 'right', width: '100px' }}
            >
              {strings.quantity}
            </Typography>
          );
        },
        customBodyRenderLite: (dataIndex) => {
          return (
            <div>
              <Typography variant={'body2'} align="right" style={{ paddingRight: '20px' }}>
                {`${numberFormatterHoldings(data[dataIndex].units, 4)}`}
              </Typography>
            </div>
          );
        },
        customFilterListOptions: {
          render: (filters) => customRender(filters, strings.quantity),
          update: customUpdate,
        },
        filterOptions: {
          fullWidth: true,
          logic: (value, filters) => {
            if (filters.length < 2 || !filters[0] || !filters[1]) return false;
            if (value >= filters[0] && value <= filters[1]) return false;
            return true;
          },
          display: (filterList, onChange, index, column) => (
            <NumberRangePicker
              title={strings.quantity}
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
            />
          ),
        },
      },
    },
    {
      name: 'price',
      label: strings.price,
      options: {
        filterType: 'custom',
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <Typography
              variant="body1"
              align="right"
              style={{ textAlign: 'right', width: '150px' }}
            >
              {strings.price}
            </Typography>
          );
        },
        customBodyRenderLite: (dataIndex) => {
          return (
            <div>
              <Typography variant={'body2'} align="right" style={{ paddingRight: '20px' }}>
                {numberFormatterDollar(data[dataIndex].price, 4)}
              </Typography>
            </div>
          );
        },
        customFilterListOptions: {
          render: (filters) => customRender(filters, strings.price),
          update: customUpdate,
        },
        filterOptions: {
          fullWidth: true,
          logic: (value, filters) => {
            if (filters.length < 2 || !filters[0] || !filters[1]) return false;
            if (value >= filters[0] && value <= filters[1]) return false;
            return true;
          },
          display: (filterList, onChange, index, column) => (
            <NumberRangePicker
              title={strings.price}
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
            />
          ),
        },
      },
    },
    {
      name: 'netTransactionValue',
      label: strings.value,
      options: {
        filterType: 'custom',
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <Typography
              variant="body1"
              align="right"
              style={{ textAlign: 'right', width: '200px' }}
            >
              {strings.value}
            </Typography>
          );
        },
        customBodyRenderLite: (dataIndex) => {
          return (
            <div>
              <Typography variant={'body2'} align="right" style={{ paddingRight: '20px' }}>
                {numberFormatterDollar(data[dataIndex].transactionValue)}
              </Typography>
            </div>
          );
        },
        customFilterListOptions: {
          render: (filters) => customRender(filters, strings.value),
          update: customUpdate,
        },
        filterOptions: {
          fullWidth: true,
          logic: (value, filters) => {
            if (filters.length < 2 || !filters[0] || !filters[1]) return false;
            if (value >= filters[0] && value <= filters[1]) return false;
            return true;
          },
          display: (filterList, onChange, index, column) => (
            <NumberRangePicker
              title={strings.value}
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
            />
          ),
        },
      },
    },
  ];

  return columns;
};

export default getColumns;
