/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Screen } from '@enums/index';
import onboardingMockData from './onboardingMockData';
import { FailureResponse } from './MockData';

const getApiMockDataForScreen = (screen: Screen | undefined) => {
  switch (screen) {
    case Screen.Onboarding:
      return { data: onboardingMockData, status: 200 };

    default:
      return FailureResponse;
  }
};

export default getApiMockDataForScreen;
