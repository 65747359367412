import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import AuthenticationStateEnum from '@services/auth/AuthenticationState.enum';
import { selectAuth } from '../../selectors';

// Define a type for the slice state
export interface IAuthState {
  state: AuthenticationStateEnum;
}

// Define the initial state using that type
export const initialState: IAuthState = {
  state: AuthenticationStateEnum.UNKNOWN,
};

export const authStateSlice = createSlice({
  name: 'authState',
  initialState,
  reducers: {
    setAuthState: (state, action: PayloadAction<AuthenticationStateEnum>) => {
      return { ...state, state: action.payload };
    },
  },
});

export const { setAuthState } = authStateSlice.actions;

export const selectAuthState = createSelector(selectAuth, (auth) => auth.state);

export default authStateSlice.reducer;
