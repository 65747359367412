import { interfaces } from 'inversify';

interface ISessionStorageService {
  get(key: string): string | null;
  set(key: string, value: string): void;
  clear(key: string): void;
  clearAll(): void;
}

export default ISessionStorageService;

const ISessionStorageService$: interfaces.ServiceIdentifier<ISessionStorageService> =
  Symbol('ISessionStorageService');

export { ISessionStorageService$ };
