/* eslint-disable @typescript-eslint/no-floating-promises */
import { FC, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { MUIDataTableOptions } from 'mui-datatables';
import { AssetClassInfo } from 'clientportalshared';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TypographyBase from '@mui/material/Typography';
import { selectHoldings, getHoldingsAsync } from '@store/reducers/holdings';
import { useAppSelector, useAppDispatch } from '@hooks/store';
import usePortfolioDetailData from '@hooks/usePortfolioDetailData';
import urlParamKeys from '@constants/urlParamKeys';
import useQuery from '@hooks/useQuery';
import {
  IconHeading,
  BackButton,
  Datatable,
  CustomSkeleton,
  PageNotFound,
} from '@components/index';
import { dateFormatter, numberFormatterDollar, percentageFormatter } from '@utils/GenericUtils';
import { portfolioDetailsUrl, holdingsTransactionsUrl } from '@utils/UrlBuilder';
import strings from '@strings/LocalisedStrings';
import reportImg from '@icons/report.svg';
import { ScreenState } from '@enums/index';
import getColumns from './datatableColumn';
import styles from './index.module.scss';

const Holdings: FC = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const query = useQuery();

  const dispatch = useAppDispatch();
  const { data, state } = useAppSelector(selectHoldings);
  const { isInvalidPortfolioId, isLoading, portfolioDetailsStore } = usePortfolioDetailData();
  const assetClasses = portfolioDetailsStore?.data?.assetClasses;

  const holdings = data?.data;
  const holdingsNoneZeroValue = holdings?.filter((item) => item.units && item.value);

  const marketValues: number[] = [];
  holdingsNoneZeroValue?.map((item) => marketValues.push(item.value ?? 0));
  const marketValue: number | undefined = marketValues.reduce((partialSum, a) => partialSum + a, 0);

  const assetClassKey = query.get(urlParamKeys.assetClassKey);
  const dateAsOf = query.get(urlParamKeys.dateAsOf);

  const [selectedAssetClass, setSelectedAssetClass] = useState<AssetClassInfo | null>(null);

  const getAssetClass = (newAssetClassKey?: string): AssetClassInfo | null => {
    if (newAssetClassKey) {
      const assetClass =
        assetClasses?.find((item) => item.assetClassKey === newAssetClassKey) || null;
      return assetClass;
    }
    if (assetClassKey) {
      const assetClass =
        assetClasses?.find((item) => item.assetClassKey! === assetClassKey) || null;
      return assetClass;
    }
    return null;
  };
  const getAssetClassFilterList = (): string[] => {
    if (selectedAssetClass) {
      return [selectedAssetClass.name!];
    }
    return [];
  };
  const getSecurityFilterNames = (): string[] | undefined => {
    const r = holdings?.map((item) => item.securityName!);
    return r;
  };

  useEffect(() => {
    dispatch(
      getHoldingsAsync({
        dateAsOf: dateAsOf!,
        portfolioKey: id,
      })
    );
    setSelectedAssetClass(getAssetClass());
  }, [getHoldingsAsync]);

  const getOptions = (holdingsDate: string) => {
    const options: MUIDataTableOptions = {
      onRowClick: (_rowData) => {
        history.push(holdingsTransactionsUrl(id, _rowData[3], holdingsDate));
      },
      onFilterChange: (_changedColumn, filterList, _type, changedColumnIndex, displayData) => {
        if (changedColumnIndex === 1) {
          // remove assetClassId param from url if the filter is removed
          if (filterList[changedColumnIndex].length === 0) {
            query.delete(urlParamKeys.assetClassKey);
            history.replace({
              search: query.toString(),
            });
            setSelectedAssetClass(null);
          }
          // add assetClassId param from url if the filter is added
          else {
            const newAssetClassKey = displayData[0].data[0] as string;
            query.set(urlParamKeys.assetClassKey, newAssetClassKey);
            history.replace({
              search: query.toString(),
            });
            setSelectedAssetClass(getAssetClass(newAssetClassKey));
          }
        }
      },
    };
    return options;
  };

  const Typography: FC = ({ children }): JSX.Element => (
    <TypographyBase variant="subtitle2">{children}</TypographyBase>
  );

  if (!dateAsOf) return <PageNotFound />;
  if (isInvalidPortfolioId)
    return <PageNotFound heading={`${strings.pageNotFound}, ${strings.invalidPortfolioId}`} />;
  if (isLoading)
    return (
      <Container sx={{ mb: 4 }}>
        <BackButton url={portfolioDetailsUrl(id)} />
        <IconHeading icon={reportImg} title={strings.holdings} style={{ marginBottom: '2rem' }} />
        <CustomSkeleton variant="rectangular" height={500} />
      </Container>
    );

  return (
    <Container sx={{ mb: 4 }}>
      <BackButton url={portfolioDetailsUrl(id)} />
      <IconHeading icon={reportImg} title={strings.holdings} style={{ marginBottom: '2rem' }} />
      <Grid container mb={4}>
        <Grid item xs={12} md={4} className={styles.infoContainer}>
          <Grid container>
            <Grid item>
              <Typography>{strings.holdingsAsOf}</Typography>
            </Grid>
            <Grid item>
              <Typography>{dateFormatter(dateAsOf)}</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <Typography>{strings.portfolioPercent}</Typography>
            </Grid>
            <Grid item>
              <Typography>
                {/* if assetClass not selected, percentage will be 100% */}
                {percentageFormatter(
                  selectedAssetClass ? selectedAssetClass.percentageOfPortfolio! : 1
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <Typography>{strings.marketValue}</Typography>
            </Grid>
            <Grid item>
              <Typography>
                {numberFormatterDollar(
                  selectedAssetClass ? selectedAssetClass.value! : marketValue
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0} md={4} />
        <Grid item xs={0} md={4} />
      </Grid>
      {state === ScreenState.Loading ? (
        <Container>
          <CustomSkeleton variant="rectangular" height={500} />
        </Container>
      ) : (
        <Datatable
          data={holdingsNoneZeroValue!}
          columns={getColumns(
            holdingsNoneZeroValue!,
            getAssetClassFilterList(),
            getSecurityFilterNames()
          )}
          options={getOptions(dateAsOf)}
          clickableRows
        />
      )}
    </Container>
  );
};

export default Holdings;
