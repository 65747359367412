/* eslint-disable no-debugger */
import { FC, useEffect, useState } from 'react';
import ReactFC from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import TimeSeries from 'fusioncharts/fusioncharts.timeseries';
import { ListBaseOfPerformanceItem } from 'clientportalshared';
import { dateFormatter } from '@utils/GenericUtils';
import { PerformanceChartData, PerformanceItem } from '@src/app/types';
import { DateTimeFormats, Screen } from '@src/app/enums';
import { getChartConfig, initialChartConfig } from './areaTimeChartConfig';
import styles from './index.module.scss';

ReactFC.fcRoot(FusionCharts, TimeSeries);

interface AreaTimeAxisProps {
  chartData: PerformanceChartData[];
  benchmarkPerformanceData?: ListBaseOfPerformanceItem['data'];
  screen: Screen;
}

const AreaTimeSeriesChart: FC<AreaTimeAxisProps> = ({
  chartData,
  benchmarkPerformanceData,
  screen,
}): JSX.Element => {
  const [fcChartConfig, setFcChartConfig] = useState(initialChartConfig(screen));

  useEffect(() => {
    const tempPerformanceData: PerformanceItem[] | undefined = benchmarkPerformanceData?.map(
      (item) => {
        return {
          date: dateFormatter(item.date!, false, DateTimeFormats.FcDateFormat),
          value: item.metric2!,
        };
      }
    );
    const finalChartConfig = getChartConfig(fcChartConfig, chartData, tempPerformanceData);
    setFcChartConfig(finalChartConfig);
  }, [chartData, benchmarkPerformanceData]);

  return <ReactFC className={styles.container} {...fcChartConfig} />;
};

AreaTimeSeriesChart.defaultProps = {
  benchmarkPerformanceData: undefined,
};

export default AreaTimeSeriesChart;
