import { FC } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { numberFormatterDollar } from '@utils/GenericUtils';
import { MoneysoftAssetCardProps } from '@src/app/types';
import styles from './AssetCard.module.scss';

const AssetCard: FC<MoneysoftAssetCardProps> = ({ color, assetClass, items }): JSX.Element => {
  let totalValue = 0;
  items.forEach((series) => {
    totalValue += series.data[0].value;
  });

  return (
    <Box className={styles.assetCard}>
      <Grid container spacing={1} wrap="nowrap">
        <Grid item xs={1} style={{ paddingLeft: '3px' }}>
          <Box width={18} height={18} bgcolor={color} borderRadius="50%" />
        </Grid>
        <Grid item xs={8} className={styles.assetPercentage}>
          <Typography variant="subtitle2" component="span" style={{ marginLeft: '0px' }}>
            {assetClass}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography
            variant="subtitle2"
            noWrap
            style={{
              paddingLeft: '0px',
            }}
          >
            {' '}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2" ml="auto">
            {numberFormatterDollar(totalValue)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AssetCard;
