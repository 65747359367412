export default {
  primary: '#3561ff',
  blackColor: '#000000',
  themeColor: '#FFFFFF',
  gray100: '#F8F8F8',
  gray200: '#E4E4E4',
  gray300: '#D7D6D6',
  gray400: '#C4C4C4',
  gray500: '#C3C6CB',
  gray600: '#9B9B9B',
  gray700: '#979797',
  gray800: '#747576',
  gray900: '#3e3e3e',
  green: '#05B38E',
  red: '#FA2E2E',
  cyanDark: '#04E3FF',
  cyan: '#04E2FF',
  btnActive: '#17B4FF',
  blue: '#2787FF',
  navy: '#3856FF',
  ocean: '#17B4FF',
  purple: '#4E19FF',
  statusBarColor: 'transparent',
  shadowColor: '#00000033',
  shadowColor100: '#00000080',
};
