import { FC, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MuiLink from '@mui/material/Link';
import strings from '@strings/LocalisedStrings';
import { appStoreUrl, playStoreUrl } from '@utils/UrlBuilder';
import { getMobileOS } from '@utils/DeviceInfoUtils';
import { DeviceOS } from '@enums/index';
import appStoreImage from '@images/download-app-store.png';
import appStoreLargeImage from '@images/download-app-store-large.png';
import playStoreImage from '@images/download-play-store.png';
import playStoreLargeImage from '@images/download-play-store-large.png';
import iphoneReflectionImg from '@images/app-in-iphone-with-reflection.png';
import androidPhone from '@images/partial-app-in-android.png';
import iphone12 from '@images/partial-app-in-iphone.png';
import styles from './index.module.scss';

interface MobileLinkImageProps {
  href: string;
  src: string;
  alt: string;
}

const OnboardingDone: FC = (): JSX.Element => {
  const { loginWithRedirect } = useAuth0();
  const [isMobile, setIsMobile] = useState(false);
  const mobileBreakpoint = 700;
  const isIOS = getMobileOS() === DeviceOS.iOS;
  const phoneImgUrl = isIOS ? iphone12 : androidPhone;
  const rightImgUrl = isMobile ? phoneImgUrl : iphoneReflectionImg;

  useEffect(() => {
    const handleWidthChange = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth > mobileBreakpoint && isMobile) setIsMobile(false);
      else if (windowWidth <= mobileBreakpoint && !isMobile) setIsMobile(true);
    };
    window.addEventListener('resize', handleWidthChange);
    return () => window.removeEventListener('resize', handleWidthChange);
  }, [isMobile, setIsMobile]);

  const onUseClientPortalPressed = () => {
    loginWithRedirect();
  };

  const MobileLinkImage: FC<MobileLinkImageProps> = ({ href, src, alt }) => (
    <MuiLink href={href} color="inherit" target="_blank">
      <img src={src} alt={alt} />
    </MuiLink>
  );

  return (
    <Container className={styles.onboardingDone}>
      <div className={styles.content}>
        <Typography variant="h4">{strings.youreAllSet}</Typography>
        <Typography>{strings.accountSetupSuccessfully}</Typography>
        <Typography variant="h6" mt={3}>
          {strings.downloadClientPortalApp}
        </Typography>
        <Typography mb={3}>{strings.easilyViewYourPortfolio}</Typography>
        {/* <TextField placeholder={strings.mobilePhone} fullWidth />
        <Button variant="contained" fullWidth>
          {strings.textMeALink}
        </Button> */}
        <div className={styles.mobileLinks}>
          {isMobile ? (
            isIOS && (
              <MobileLinkImage
                href={appStoreUrl()}
                src={appStoreLargeImage}
                alt={strings.appleLink}
              />
            )
          ) : (
            <MobileLinkImage href={appStoreUrl()} src={appStoreImage} alt={strings.appleLink} />
          )}
          {isMobile ? (
            !isIOS && (
              <MobileLinkImage
                href={playStoreUrl()}
                src={playStoreLargeImage}
                alt={strings.googlePlayLink}
              />
            )
          ) : (
            <MobileLinkImage
              href={playStoreUrl()}
              src={playStoreImage}
              alt={strings.googlePlayLink}
            />
          )}
        </div>
        <Typography className={styles.or}>{strings.or}</Typography>
        <Button
          variant="contained"
          className={styles.btn2}
          onClick={onUseClientPortalPressed}
          fullWidth
        >
          {strings.useWebsiteInstead}
        </Button>
      </div>
      <img src={rightImgUrl} alt={strings.mobilePhone} />
    </Container>
  );
};
export default OnboardingDone;
