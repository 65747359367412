import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import container from '@ioc';
import AuthenticationStateEnum from '@services/auth/AuthenticationState.enum';
import ILoggingService, { ILoggingService$ } from '@services/logging/ILoggingService.interface';
import {
  IAuthenticationService,
  IAuthenticationService$,
} from '@services/auth/IAuthentication.interface';
import RenderRoutes from '@src/RenderRoutes';
import routes from '@src/route.config';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch, useAppSelector } from '@hooks/store';
import { selectAuthState, setAuthState } from '@store/reducers/auth';
import { setInitialLocation } from '@store/reducers/location';
import { Severity } from '@sentry/types';
import Header from '@shell/header';
import Footer from '@shell/footer';
import { CommonLoader, ErrorFallbackComponent } from '@components/index';
import strings from '@localizedstrings/LocalisedStrings';
import { logoutCallbackUrl } from '@utils/UrlBuilder';
import checkBetweenDateRange from '@utils/DateRangeUtil';
import { SupersimplifierStore, selectSupersimplifier } from '@store/reducers/supersimplifier';

const App: FC = (): JSX.Element => {
  const history = useHistory();
  const initialLocation = useLocation();
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuthState);
  const { logout, isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();

  const loggingService = container.get<ILoggingService>(ILoggingService$);
  const authenticationService = container.get<IAuthenticationService>(IAuthenticationService$);

  const [isAuthServiceInitialized, setIsAuthServiceInitialized] = useState(false);
  const [isSetupComplete, setIsSetupComplete] = useState(false);
  const superSimplifierStore: SupersimplifierStore = useAppSelector(selectSupersimplifier);

  const logoutUser = () => {
    logout({ returnTo: logoutCallbackUrl() });
  };

  useEffect(() => {
    authenticationService.init().then(() => setIsAuthServiceInitialized(true));
    dispatch(setInitialLocation(initialLocation.pathname));
  }, []);

  // page logging
  useEffect(() => {
    history.listen((location) => loggingService.page(location.pathname, document.title));
  }, [history]);

  // logout whenever we get 401 from api
  useEffect(() => {
    if (authState === AuthenticationStateEnum.FORCED_LOGGED_OFF) {
      loggingService.log({
        message: strings.sessionExpiredBody,
        level: Severity.Info,
        error: undefined,
      });

      logoutUser();
    }
  }, [authState]);

  useEffect(() => {
    const setToken = async () => {
      const accessToken = await getAccessTokenSilently();
      dispatch(setAuthState(AuthenticationStateEnum.LOGGED_IN));
      authenticationService.setToken(accessToken);
    };

    if (!isLoading && isAuthServiceInitialized) {
      if (isAuthenticated) {
        setToken().then(() => {
          setIsSetupComplete(true);
        });
      } else {
        setIsSetupComplete(true);
      }
    }
  }, [getAccessTokenSilently, isLoading, isAuthenticated, isAuthServiceInitialized]);

  //todo more useful to specify dates as process env variables
  const displayBetweenDates: boolean = checkBetweenDateRange(
    new Date('2023-06-07T21:00:00'),
    new Date('2023-06-12T17:00:00')
  );

  return (
    <div className="app">
      {displayBetweenDates && superSimplifierStore.superSimplifierAccounts?.data?.length ? (
        <ErrorFallbackComponent
          error={new Error()}
          /* eslint-disable-next-line @typescript-eslint/no-empty-function */
          resetErrorBoundary={() => {}}
          customError={strings.dashMaintenanceBannerNotificationText}
        />
      ) : (
        <>
          <Header />
          <main>{isSetupComplete ? <RenderRoutes routes={routes} /> : <CommonLoader />}</main>
        </>
      )}
      <Footer />
    </div>
  );
};

export default App;
