import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PortfolioDetailsStore, selectPortfolioDetails } from '@store/reducers/portfolioDetails';
import { getPortfolioDetailsAsync } from '@store/reducers/portfolioDetails/thunks';
import { ScreenState } from '@enums/index';
import { useAppSelector, useAppDispatch } from './store';

interface PortfolioDetailsHookReturnType {
  isLoading: boolean;
  portfolioDetailsStore: PortfolioDetailsStore;
  isInvalidPortfolioId: boolean;
  loadPortfolioDetailsData: (retry?: boolean) => void;
}

const usePortfolioDetailData = (): PortfolioDetailsHookReturnType => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  const portfolioDetailsStore = useAppSelector(selectPortfolioDetails);

  const [isInvalidPortfolioId, setIsInvalidPortfolioId] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const loadPortfolioDetailsData = (retry = false) => {
    // only load data if api was not called before or it's a different portfolio
    if (
      portfolioDetailsStore.state === undefined ||
      portfolioDetailsStore.state !== ScreenState.SuccessDataLoaded ||
      portfolioDetailsStore.data.portfolioKey !== id ||
      retry
    ) {
      if (!isLoading) setIsLoading(true);
      dispatch(
        getPortfolioDetailsAsync({
          portfolioKey: id,
        })
      );
    }
  };

  useEffect(loadPortfolioDetailsData, []);

  useEffect(() => {
    if (
      portfolioDetailsStore.state === undefined ||
      portfolioDetailsStore.state === ScreenState.Loading
    ) {
      setIsLoading(true);
    } else {
      // may need to update this logic
      if (portfolioDetailsStore.isInvalidPortfolioId) setIsInvalidPortfolioId(true);
      setIsLoading(false);
    }
  }, [portfolioDetailsStore.state]);

  return {
    isLoading,
    portfolioDetailsStore,
    isInvalidPortfolioId,
    loadPortfolioDetailsData,
  };
};
export default usePortfolioDetailData;
