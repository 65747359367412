import { FC, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import container from '@ioc';
import { useAuth0 } from '@auth0/auth0-react';
import {
  IAuthenticationService,
  IAuthenticationService$,
} from '@services/auth/IAuthentication.interface';
import AuthenticationStateEnum from '@services/auth/AuthenticationState.enum';
import { setAuthState } from '@store/reducers/auth';
import { selectLocationStore } from '@store/reducers/location';
import { useAppDispatch, useAppSelector } from '@hooks/store';
import { CommonLoader } from '@components/index';
import { homeUrl, rootUrl } from '@utils/UrlBuilder';
import restrictedRedirectUrls from '@constants/restrictedRedirectUrls';

const Login: FC = (): JSX.Element => {
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();

  const authenticationService = container.get<IAuthenticationService>(IAuthenticationService$);
  const dispatch = useAppDispatch();
  const { initialLocation } = useAppSelector(selectLocationStore);

  const [isSetupComplete, setIsSetupComplete] = useState(false);

  useEffect(() => {
    const setToken = async () => {
      const accessToken = await getAccessTokenSilently();
      dispatch(setAuthState(AuthenticationStateEnum.LOGGED_IN));
      authenticationService.setToken(accessToken);
    };

    if (!isLoading && isAuthenticated)
      setToken().then(() => {
        setIsSetupComplete(true);
      });
  }, [getAccessTokenSilently, isLoading, isAuthenticated]);

  if (isLoading) return <CommonLoader />;
  if (!isAuthenticated) return <Redirect to={rootUrl()} />;
  if (isSetupComplete) {
    if (restrictedRedirectUrls.find((v) => v === initialLocation))
      return <Redirect to={homeUrl()} />;

    return <Redirect to={initialLocation} />;
  }
  return <CommonLoader />;
};

export default Login;
