import LocalizedStrings from 'react-localization';
import inputConfig from '@config/inputConfig';

const strings = new LocalizedStrings({
  en: {
    security: 'Security',
    date: 'Date',
    description: 'Description',
    transactionType: 'Transaction Type',
    price: 'Price',
    quantity: 'Quantity',
    reportTitle: 'Report Title',
    dateAdded: 'Date Added',
    action: 'Action',
    from: 'From',
    to: 'To',
    back: 'Back',
    login: 'Login',
    profile: 'Profile',
    pageNotFound: 'Page not found',
    invalidPortfolioId: 'Invalid Portfolio Id',
    invalidSecurityCode: 'Invalid Security Code',
    invalidInvitationLink: 'Invalid Invitation Link',
    detailsAsOf: 'Details as of',
    dashClientPortal:
      'The Dash Client Portal provides up-to-date, secure portfolio access. Your Super and Investment accounts, portfolio performance, holdings and transactions are available at your fingertips.',
    dashMaintenanceBannerNotificationText:
      '<div><h3>Our online services are currently unavailable due to planned maintenance. We are in the process of improving our service to Super Simplifier members and as a result this portal will be unavailable from 9pm (AEST) Wed 7 June until 5pm Monday 12 June.</h3></div>' +
      '<div>If you require information urgently, please contact your appointed financial adviser.</div>',
    dashMaintenanceBannerPreWarningHeader:
      '<h4>We are experiencing issues with the portfolio performance calculations for some clients which may result in nil performance data displayed on the portal. There is no impact to your underlying investments and their values. Our team is currently investigating a resolution. We apologise for any inconvenience.</h4>',
    dashClientPortalDesc:
      'The mobile application provides up-to-date secure portfolio access on your mobile device. Your Super and Investment accounts, portfolio performance, holdings, and transactions are available at your fingertips.',
    contactYourFinancialAdviser:
      'Contact your financial adviser for an invite to the Client Portal.',
    or: 'Or',
    invalidUrl: 'Invalid Url',
    dashClientPortalAccountSetup: 'Dash Client Portal Account Setup',
    yourFinancialAdviser: 'Your financial adviser',
    hasInvitedYou:
      'has invited you to the Dash Client Portal so you can see your portfolio and track your performance over time.',
    yourAccountDetails: 'Your Account Details',
    name: 'Name',
    emailAddress: 'Email Address',
    password: 'Password',
    newPassword: 'New Password',
    chooseStrongPassword: 'Choose strong, a unique password, at least 8 characters in length.',
    passwordTooWeak:
      'The password provided was too weak. It must be at least 8 characters in length and include at least one of each of the following types of characters: lower case (a-z), upper case (A-Z), numbers (0-9) and special characters (!@#$%^&*)',
    passwordMustBe: `Password must be at least ${inputConfig.minPasswordLength} characters in length`,
    createAccount: 'Create Account',
    byCreatingAccount: 'By creating an account, you agree to the Dash Technology Group',
    and: 'and',
    notYou: 'Not You?',
    youreAllSet: 'You’re All Set',
    accountSetupSuccessfully: 'Your account has been set up successfully.',
    downloadClientPortalApp: 'Download the Client Portal App',
    easilyViewYourPortfolio:
      'Easily view your portfolio on your mobile device with the Client Portal App available for iOS and Android.',
    mobilePhone: 'Mobile Phone ',
    textMeALink: 'Text Me a Link',
    useWebsiteInstead: 'Use Website Instead',
    logoutBeforeInvitation:
      'You are already logged in, you must logout before clicking on an invitation link',
    resolveErrorFirst: 'Resolve the error before proceeding',
    invitationLinkExpired: 'The invitation link has expired, please contact your Adviser',
    home: 'Home',
    benchmark: 'Benchmark',
    time: 'Time',
    noInternetAccess: 'No internet connection',
    toLogin: 'to login?',
    use: 'Use',
    download: 'Download',
    biometricPageDescriptionSegmentPrefix: 'You can use',
    receivePushNotifications: 'Receive Notifications for New Reports?',
    pushNotificationsDescription:
      'Stay on top of your portfolio and get notifications when new reports are available to download',
    enablePushNotifications: 'Enable Push Notifications',
    maybeLater: 'Maybe later',
    usePassword: 'No, use password each time',
    biometricPageDescriptionSegmentSuffix:
      " to access your account, so you won't need to type your password each time.",
    portfolio: 'Portfolio',
    next: 'Next',
    previous: 'Previous',
    chooseBio: 'Would you like to use Face ID or Touch ID for quicker login next time?',
    useFaceId: 'Use Face ID',
    useTouchId: 'Use Touch ID',
    logout: 'Log Out',
    email: 'Email',
    call: 'Call',
    scheduleAppointment: 'Schedule Appointment',
    totalReturnInception: 'Total Return Since Inception',
    totalReturnDate: 'Total Return Since',
    totalReturn: 'Total Return',
    yourNetWorth: 'Your Net worth',
    hi: 'Hi',
    settings: 'Settings',
    poweredBy: 'Powered By',
    lastSync: 'Last Sync',
    version: 'Version',
    adviser: 'Adviser',
    TouchID: 'Touch ID',
    FaceID: 'Face ID',
    Biometrics: 'Biometrics',
    holdings: 'Holdings',
    portfolioPercent: 'Portfolio %',
    value: 'Value',
    units: 'Units',
    lastPrice: 'Last Price',
    marketValue: 'Market Value',
    viewAllHoldings: 'View All Holdings',
    hundredPercent: '100%',
    weight: 'Weight',
    cost: 'Cost',
    estLossGain: 'Est. Gain/Loss',
    allAssetClasses: 'All Asset Classes',
    assetClasses: 'Asset Class',
    viewAllTransactions: 'View All Transactions',
    buy: 'Buy',
    inFees: 'in fees',
    appUpdateAlertTitle: 'Update Required',
    appUpdateButtonTextAndroid: 'Open Play Store',
    appUpdateButtonTextIos: 'Open App Store',
    appUpdateAlertMessage:
      "A new version of this app is available. You'll need to download the update from your app store to continue using the app.",
    errorBoundaryText: 'Something went wrong.',
    pleaseTryAgainLater: 'Please try again later',
    transactionsHeading: 'Transactions',
    allTransactions: ' All Transactions',
    recentTransactions: 'Your most recent transactions.',
    holdingsAsOf: 'Holdings As Of',
    reportsHeading: 'Reports',
    viewAllReports: 'View All Reports',
    added: 'Added',
    unableToLoadDataForGraph: 'Unable to load data for graph',
    unableToLoadData: 'Unable to load data',
    retry: 'Retry',
    couldNotLoadData: 'We couldn’t load your data at this time.',
    performance: 'Performance',
    assetAllocation: 'Asset Allocation',
    allocationsAsOf: 'Allocation As Of',
    recentReportHoldings: 'Recent reports related to your account.',
    currentValue: 'Current Value',
    capitalReturn: 'Capital Return',
    incomeReturn: 'Income Return',
    includeCashTransactions: 'Include Cash Transactions',
    noTransactionDataWithFilters: 'No data found with the selected Filters.',
    searchTransactions: 'Search transactions',
    noReports: 'There are no reports available at this time.',
    noTransactions: 'There are no transactions available at this time.',
    noAssetAllocations:
      'There is no asset allocation data available at this date. Please select another date.',
    noPerformance: 'There is no performance data available at this time.',
    nA: 'N/A',
    chooseBenchmark: 'Choose Benchmark',
    chooseBenchmarkDescription: 'Choose a benchmark to compare your portfolio performance against.',
    compareAgainstBenchmark: 'Compare Against Benchmark',
    none: 'None',
    indexReturn: 'Index Return',
    changePassword: 'Change Password',
    pushNotifications: 'Push Notifications',
    termsAndConditions: 'Terms and Conditions',
    appleLink: 'iOS apple store link',
    googlePlayLink: 'google play store link',
    privacyPolicy: 'Privacy Policy',
    contactUs: 'Contact Us',
    dashTechnologyGroup: ' DASH Technology Group.',
    devEnvironment: 'Dev',
    stagingEnvironment: 'Staging',
    productionEnvironment: 'Production',
    changeEnvironment: 'Change Environment',
    successMessage: 'Able to retrieve the data from the server.',
    errorMessage: 'Unable to retrieve data from the server. Please try again.',
    changeEnvironmentModalMessage:
      "Changing environments will log you out of your current session and change environments. You'll need to login again with a valid account for that new environment. Are you sure you want to logout and change environments?",
    cancel: 'Cancel',
    ok: 'Ok',
    changePasswordModalTitle: 'Change Password Link Sent',
    changePasswordModalMessage:
      "We've sent you an email with a link you can use to change your password. If you don't see it in your inbox in a couple of minutes, please check your junk email folder.",
    bundleId: 'BundleID',
    slot: 'Slot',
    total: 'total',
    fileDownloadedSuccessfully: 'File downloaded successfully',
    unableToDownloadFile: 'Unable to download file. Please try again',
    permissionToDownloadFile: 'Client Portal needs permission to download the file',
    permissionDeniedToDownloadFile: 'Permission denied, unable to download file',
    unableToObtainPermissionToDownloadFile: 'Unable to obtain permission to download file',
    selectContact: 'Select Contact',
    searchContact: 'Search Contact',
    noContactsAvailable: 'No contacts available',
    adviserMode: 'Adviser Mode',
    unableToLogin: 'Unable to Login',
    clockOutOfSyncErrorModalMessage:
      'Please ensure the date and time of your device is set correctly, or that your device is set to "automatic" mode and try again.',
    sessionExpiredTitle: 'Session Expired',
    sessionExpiredBody: 'Your session has expired. Please login again to continue.',
    currentServerEnvironmentAlreadyDev: 'You are already in the dev environment',
    currentServerEnvironmentAlreadyStaging: 'You are already in the staging environment',
    currentServerEnvironmentAlreadyProduction: 'You are already in the production environment',
    devTools: 'Dev Tools',
    expireAccessToken: 'Expire Access Token',
    tokenExpirationTitle: 'Token has expired',
    tokenExpirationDescription:
      'If you want to test the app behavior on token expiration (prompt biometric auth), please restart the app.',
    logoutMessage: 'Are you sure you want to logout?',
    whatsNew: `What's New`,
    v1_6_0ReleaseNotes:
      '- Added push notifications to allow users to be notified when new reports are available to download. \n' +
      '- Minor bug fixes and performance enhancements.',
    v1_7_0ReleaseNotes:
      "- What's New screen to announce new features\n" +
      '- Various bug fixes and performance improvements\n',
  },
});

export default strings;
