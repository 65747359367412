import { FC } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { percentageWithSign, getReturnColor, numberFormatterDollar } from '@utils/GenericUtils';
import colors from '@theme/Colors';
import strings from '@strings/LocalisedStrings';
import styles from './InvestmentPortfolio.module.scss';

interface InvestmentPortfolioProps {
  heading: string;
  currentValue: number;
  capitalReturn: number;
  capitalReturnPercentage: number;
  incomeReturn: number;
  incomeReturnPercentage: number;
  totalReturn: number;
  totalReturnPercentage: number;
}

const InvestmentPortfolio: FC<InvestmentPortfolioProps> = ({
  heading,
  currentValue,
  capitalReturn,
  capitalReturnPercentage,
  incomeReturn,
  totalReturn,
  totalReturnPercentage,
}): JSX.Element => {
  const calculatedIncomeReturn = `${numberFormatterDollar(
    totalReturn - capitalReturn
  )} (${percentageWithSign(totalReturnPercentage - capitalReturnPercentage)})`;
  return (
    <>
      <Typography
        style={{ textTransform: 'uppercase' }}
        variant="h4"
        fontWeight={700}
        color={colors.green}
        mt={4}
      >
        {heading}
      </Typography>

      <Grid container direction="row" justifyContent="space-between" alignItems="center" mt={3}>
        <Grid item xs={6}>
          <Typography
            style={{ textTransform: 'uppercase' }}
            variant="body2"
            color={colors.gray500}
            fontWeight={700}
            mb={2}
          >
            {strings.currentValue}
          </Typography>
          <Typography variant="h4" fontWeight={700}>
            {numberFormatterDollar(currentValue)}
          </Typography>
        </Grid>

        <Grid item xs={1} display="flex" justifyContent="center">
          <div className={styles.line} />
        </Grid>

        <Grid item xs={5}>
          <Grid container justifyContent="space-between" mt={1}>
            <Grid item xs={6}>
              <Typography variant="subtitle2">{strings.capitalReturn}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="subtitle2"
                textAlign="right"
                fontWeight={700}
                color={getReturnColor(capitalReturn)}
              >
                {`${numberFormatterDollar(capitalReturn)} (${percentageWithSign(
                  capitalReturnPercentage
                )})`}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justifyContent="space-between" mt={1}>
            <Grid item xs={6}>
              <Typography variant="subtitle2">{strings.incomeReturn}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="subtitle2"
                textAlign="right"
                fontWeight={700}
                color={getReturnColor(incomeReturn)}
              >
                {calculatedIncomeReturn}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justifyContent="space-between" mt={1}>
            <Grid item xs={6}>
              <Typography variant="subtitle2">{strings.totalReturn}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="subtitle2"
                textAlign="right"
                fontWeight={700}
                color={getReturnColor(totalReturn)}
              >
                {`${numberFormatterDollar(totalReturn)} (${percentageWithSign(
                  totalReturnPercentage
                )})`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InvestmentPortfolio;
