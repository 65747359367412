import { FC } from 'react';
import Dashboard from '@page/dashboard';
import PortfolioDetails from '@page/portfolioDetails';
import Transactions from '@page/transactions';
import Reports from '@page/reports';
import Holdings from '@page/holdings';
import HoldingsTransactions from '@page/holdingsTransactions';
import Login from '@page/login';
import Logout from '@page/logout';
import Adviser from '@page/adviser';
import Profile from '@page/profile';
import Onboarding from '@page/onboarding';
import OnboardingDone from '@page/onboardingDone';
import Home from '@page/home';
import MoneySoft from '@page/moneySoft';
import Documents from '@page/documents';
import Beneficiaries from '@src/app/page/beneficiaries';
import Insurance from '@src/app/page/insurance';
import Pension from '@src/app/page/pension';
import BenefitStatement from '@src/app/page/benefitStatement';
import Contributions from '@src/app/page/contributions';

import {
  homeUrl,
  loginUrl,
  logoutUrl,
  portfolioDetailsUrl,
  rootUrl,
  allReportsUrl,
  allTransactionsUrl,
  allHoldingsUrl,
  holdingsTransactionsUrl,
  adviserUrl,
  profileUrl,
  onboardingUrl,
  onboardingDoneUrl,
  moneySoftUrl,
  documentsUrl,
  beneficiariesUrl,
  insuranceUrl,
  pensionUrl,
  benefitStatementUrl,
  contributionsUrl,
} from '@utils/UrlBuilder';

interface RouteConfig {
  key?: string;
  path: string;
  exact: boolean;
  protected: boolean;
  component: FC<Record<string, unknown>>;
  routes?: RouteConfig[];
}

const routes: RouteConfig[] = [
  {
    key: 'dashboardPage',
    path: homeUrl(),
    exact: true,
    protected: true,
    component: Dashboard,
  },
  {
    key: 'moneySoft',
    path: moneySoftUrl(),
    exact: true,
    protected: true,
    component: MoneySoft,
  },
  {
    key: 'documents',
    path: documentsUrl(),
    exact: true,
    protected: true,
    component: Documents,
  },
  {
    key: 'beneficiaries',
    path: beneficiariesUrl(),
    exact: true,
    protected: true,
    component: Beneficiaries,
  },
  {
    key: 'insurance',
    path: insuranceUrl(),
    exact: true,
    protected: true,
    component: Insurance,
  },
  {
    key: 'pension',
    path: pensionUrl(),
    exact: true,
    protected: true,
    component: Pension,
  },
  {
    key: 'benefitStatement',
    path: benefitStatementUrl(),
    exact: true,
    protected: true,
    component: BenefitStatement,
  },
  {
    key: 'contributions',
    path: contributionsUrl(),
    exact: true,
    protected: true,
    component: Contributions,
  },
  {
    key: 'transactionsPage',
    path: allTransactionsUrl(':id'),
    exact: true,
    protected: true,
    component: Transactions,
  },
  {
    key: 'reportsPage',
    path: allReportsUrl(':id'),
    exact: true,
    protected: true,
    component: Reports,
  },
  {
    key: 'allHoldingsPage',
    path: allHoldingsUrl(':id'),
    exact: true,
    protected: true,
    component: Holdings,
  },
  {
    key: 'holdingsTransactionsPage',
    path: holdingsTransactionsUrl(':id', ':securityCode'),
    exact: true,
    protected: true,
    component: HoldingsTransactions,
  },
  {
    key: 'loginPage',
    path: loginUrl(),
    exact: false,
    protected: false,
    component: Login,
  },
  {
    key: 'logoutPage',
    path: logoutUrl(),
    exact: false,
    protected: false,
    component: Logout,
  },
  {
    key: 'portfolioDetailsPage',
    path: portfolioDetailsUrl(':id'),
    exact: true,
    protected: true,
    component: PortfolioDetails,
  },
  {
    key: 'adviserPage',
    path: adviserUrl(),
    exact: false,
    protected: true,
    component: Adviser,
  },
  {
    key: 'profilePage',
    path: profileUrl(),
    exact: false,
    protected: true,
    component: Profile,
  },
  {
    key: 'onboardingDonePage',
    path: onboardingDoneUrl(),
    exact: true,
    protected: false,
    component: OnboardingDone,
  },
  {
    key: 'onboardingPage',
    path: onboardingUrl(),
    exact: true,
    protected: false,
    component: Onboarding,
  },
  {
    key: 'homePage',
    path: rootUrl(),
    exact: true,
    protected: false,
    component: Home,
  },
];

export type { RouteConfig };
export default routes as ReadonlyArray<RouteConfig>;
