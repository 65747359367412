import { FC, useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@hooks/store';
import {
  getSuperSimplifierAccountsAsync,
  getSuperSimplifierAccountAsync,
} from '@store/reducers/supersimplifier/thunks';
import { ScreenState } from '@src/app/enums';
import {
  SupersimplifierStore,
  selectSupersimplifier,
  setSuperSimplifierAccountParameter,
} from '@store/reducers/supersimplifier';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { BackButton } from '@components/index';
import SupersimplifierTable, {
  SupersimplifierTableRow,
  TableHeaders,
} from '@components/superTables/supersimplifierTable';
import { homeUrl } from '@utils/UrlBuilder';
import { Alert, Stack, Typography } from '@mui/material';
import { SuperSimplifierAccountSummary } from 'clientportalshared';

const Pension: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const supersimplifierStore: SupersimplifierStore = useAppSelector(selectSupersimplifier);

  const [selectedAccount, setSelectedAccount] = useState('');

  const getSuperSimplifierAccount = async (
    accountList: SuperSimplifierAccountSummary[] | undefined
  ) => {
    if (accountList?.length) {
      const pensionAcc = accountList.find((acc) => acc.type === 'Pension');
      if (pensionAcc && pensionAcc.accountNumber) {
        await dispatch(
          getSuperSimplifierAccountAsync({
            accountNumber: pensionAcc.accountNumber,
          })
        );
      }
    }
  };

  const getPageData = () => {
    dispatch(getSuperSimplifierAccountsAsync())
      .unwrap()
      .then((responce) => {
        if (responce?.data?.length) {
          const pensionAccount = responce.data.find((acc) => acc.type === 'Pension');
          if (pensionAccount?.accountNumber && pensionAccount.type) {
            setSelectedAccount(pensionAccount.accountNumber);
          }
        }
        getSuperSimplifierAccount(responce.data);
      });
  };

  useEffect(() => {
    getPageData();
  }, []);

  const tableHeaders: TableHeaders = { header1: 'DESCRIPTION', header2: 'VALUE' };
  let pensionTable: SupersimplifierTableRow[] = [];
  let pensionBankTable: SupersimplifierTableRow[] = [];

  if (supersimplifierStore.superSimplifierAccount?.pensionDetails) {
    const {
      bankAccountBsb,
      bankAccountNumber,
      bankAccountName,
      financialYearPaymentAmountTillDate,
      minimumAnnualPaymentAmount,
      maximumAnnualPaymentAmount,
      nextPaymentDate,
      paymentFrequency,
      paymentOption,
      paymentAmount,
      pensionType,
    } = supersimplifierStore.superSimplifierAccount?.pensionDetails;

    let paymentFrequencyDsp = '';
    switch (paymentFrequency) {
      case 'H':
        paymentFrequencyDsp = 'Half-Yearly';
        break;
      case 'I':
        paymentFrequencyDsp = 'Monthly';
        break;
      case 'L':
        paymentFrequencyDsp = 'Monthly';
        break;
      case 'Q':
        paymentFrequencyDsp = 'Quarterly';
        break;
      case 'Y':
        paymentFrequencyDsp = 'Yearly';
        break;
      case 'F':
        paymentFrequencyDsp = 'Fortnightly';
        break;
      default:
        paymentFrequencyDsp = '';
    }

    const minimumAnnualPaymentAmountDsp: number | string = minimumAnnualPaymentAmount || '';
    const maximumAnnualPaymentAmountDsp: number | string = maximumAnnualPaymentAmount || '';

    pensionTable = [
      { label: 'Pension Type', value: pensionType },
      {
        label: 'Payment Option',
        value: paymentOption,
      },
      {
        label: 'Payment Amount',
        value: paymentAmount,
      },
      { label: 'Next Payment Date', value: nextPaymentDate },
      { label: 'Payment Frequency', value: paymentFrequencyDsp },
      { label: 'Total Paid Financial Year to Date', value: financialYearPaymentAmountTillDate },
      { label: 'Minimum Annual Payment Amount', value: minimumAnnualPaymentAmountDsp },
      { label: 'Maximum Annual Payment Amount *', value: maximumAnnualPaymentAmountDsp },
    ];

    pensionBankTable = [
      { label: 'BSB', value: bankAccountBsb },
      {
        label: 'Account Number',
        value: bankAccountNumber,
      },
      {
        label: 'Account Name',
        value: bankAccountName,
      },
    ];
  }

  const handleAccountChange = (event: SelectChangeEvent) => {
    setSelectedAccount(event.target.value);
    dispatch(setSuperSimplifierAccountParameter(event.target.value));
    dispatch(
      getSuperSimplifierAccountAsync({
        accountNumber: event.target.value,
      })
    );
  };

  let errorMessage = null;
  if (supersimplifierStore.superSimplifierAccountState === ScreenState.ErrorUnableToLoadData) {
    errorMessage =
      'There is currently no Pension information for this account. If the account has been recently established it may take a few days for this data to be populated. Please contact your adviser our or client service team for assistance.';
    pensionTable = [];
    pensionBankTable = [];
  } else if (supersimplifierStore.superSimplifierAccountState === ScreenState.Loading) {
    pensionTable = [];
    pensionBankTable = [];
  }

  return (
    <Container>
      <BackButton url={homeUrl()} />
      <Grid container className="portfolioContent" rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} rowSpacing={{ xs: 2, md: 2 }}>
              <p className="pageHeader">Pension Details</p>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} rowSpacing={{ xs: 2, md: 2 }} sx={{ marginTop: '20px' }}>
              <Select
                labelId="selected-account-select-label"
                id="selected-account-select"
                value={selectedAccount}
                defaultValue={selectedAccount}
                label="ACCOUNT"
                onChange={handleAccountChange}
              >
                {supersimplifierStore?.superSimplifierAccounts?.data
                  ?.filter((ii) => ii.type === 'Pension')
                  .map((i) => (
                    <MenuItem key={i.accountNumber} value={i.accountNumber ?? ''}>{`${
                      i.accountNumber ?? ''
                    } - ${i.name ?? ''}`}</MenuItem>
                  ))}
              </Select>
            </Grid>

            {errorMessage && (
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  rowSpacing={{ xs: 2, md: 2 }}
                  sx={{ marginTop: '20px', marginLeft: '17px' }}
                >
                  <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert variant="outlined" severity="info">
                      {errorMessage}
                    </Alert>
                  </Stack>
                </Grid>
              </Grid>
            )}
            {!errorMessage && (
              <>
                <SupersimplifierTable
                  tableHeaders={tableHeaders}
                  data={pensionTable}
                  header="Pension Details"
                />

                <Typography sx={{ paddingLeft: '20px' }}>
                  <i>
                    * Maximum payment limits only apply to Transition to Retirement (TTR) accounts
                  </i>
                </Typography>

                <SupersimplifierTable
                  tableHeaders={tableHeaders}
                  data={pensionBankTable}
                  header="Pension Bank Account"
                />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Pension;
