import User from '@services/user/User.model';
import { interfaces } from 'inversify';
import LogEntryModel from './LogEntry.model';

export default interface ILogTransport {
  init: () => void;
  log: (entry: LogEntryModel) => void;
  registerUser: (user: User) => void;
  page: (title: string, url: string) => void;
}

const ILogTransport$: interfaces.ServiceIdentifier<ILogTransport> = Symbol('ILogTransport');

export { ILogTransport$ };
