import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import strings from '@strings/LocalisedStrings';
import { ReactComponent as ButtonBackArrowImg } from '@icons/button_back_arrow.svg';
import styles from './index.module.scss';

interface BackButtonProps {
  url?: string;
}

const BackButton: FC<BackButtonProps> = ({ url }): JSX.Element => {
  const history = useHistory();

  return (
    <button
      type="button"
      onClick={() => (url ? history.push(url) : history.goBack())}
      className={styles.back}
    >
      <ButtonBackArrowImg />
      <div>{strings.back}</div>
    </button>
  );
};

BackButton.defaultProps = {
  url: undefined,
};

export default BackButton;
