import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ScreenState } from '@enums/index';
import {
  SuperSimplifierAccountList,
  SuperSimplifierAccount,
  DocumentSummaryList,
} from 'clientportalshared';
import {
  getDocumentsAsync,
  getContributionsAsync,
  getSuperSimplifierAccountAsync,
  getSuperSimplifierAccountsAsync,
} from './thunks';
import type { RootState } from '../../index';

export interface SupersimplifierStore {
  superSimplifierAccountsState: ScreenState;
  superSimplifierAccountState: ScreenState;
  superSimplifierContributionsState: ScreenState;
  superSimplifierDocumentsState: ScreenState;
  superSimplifierAccounts: SuperSimplifierAccountList | undefined;
  superSimplifierAccount: SuperSimplifierAccount | undefined;
  superSimplifierContributions: DocumentSummaryList | undefined;
  superSimplifierDocuments: DocumentSummaryList | undefined;
  superSimplifierSelectedAccount: string | null;
  error: string | undefined;
}

const initialState = {
  superSimplifierAccountsState: ScreenState.SuccessDataLoaded,
  superSimplifierAccountState: ScreenState.SuccessDataLoaded,
  superSimplifierContributionsState: ScreenState.SuccessDataLoaded,
  superSimplifierDocumentsState: ScreenState.SuccessDataLoaded,
  superSimplifierAccounts: undefined,
  superSimplifierAccount: undefined,
  superSimplifierContributions: undefined,
  superSimplifierDocuments: undefined,
  superSimplifierSelectedAccount: null,
  error: undefined,
} as SupersimplifierStore;

export const SupersimplifierSlice = createSlice({
  name: 'supersimplifier',
  initialState,
  reducers: {
    setSuperSimplifierAccountParameter: (state, action: PayloadAction<string | null>) => {
      return { ...state, superSimplifierSelectedAccount: action.payload };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSuperSimplifierAccountsAsync.fulfilled, (prevState, action) => {
        let defaultAccount: string | undefined =
          action.payload.data?.find((i) => i.type === 'Pension')?.accountNumber || '';
        if (defaultAccount === '') {
          defaultAccount = action?.payload?.data?.length
            ? action.payload.data[0].accountNumber
            : '';
        }
        return {
          ...prevState,
          superSimplifierAccountsState: ScreenState.SuccessDataLoaded,
          superSimplifierAccounts: action.payload,
          superSimplifierSelectedAccount: defaultAccount || '',
        };
      })
      .addCase(getSuperSimplifierAccountsAsync.pending, (prevState) => {
        return { ...prevState, superSimplifierAccountsState: ScreenState.Loading };
      })
      .addCase(getSuperSimplifierAccountsAsync.rejected, (prevState, action) => {
        return {
          ...prevState,
          superSimplifierAccountsState: ScreenState.ErrorUnableToLoadData,
          error: action.error.message,
        };
      })

      .addCase(getSuperSimplifierAccountAsync.fulfilled, (prevState, action) => {
        return {
          ...prevState,
          superSimplifierAccountState: ScreenState.SuccessDataLoaded,
          superSimplifierAccount: action.payload,
        };
      })
      .addCase(getSuperSimplifierAccountAsync.pending, (prevState) => {
        return { ...prevState, superSimplifierAccountState: ScreenState.Loading };
      })
      .addCase(getSuperSimplifierAccountAsync.rejected, (prevState, action) => {
        return {
          ...prevState,
          superSimplifierAccountState: ScreenState.ErrorUnableToLoadData,
          error: action.error.message,
        };
      })

      .addCase(getContributionsAsync.fulfilled, (prevState, action) => {
        return {
          ...prevState,
          superSimplifierContributionsState: ScreenState.SuccessDataLoaded,
          superSimplifierContributions: action.payload,
        };
      })
      .addCase(getContributionsAsync.pending, (prevState) => {
        return { ...prevState, superSimplifierContributionsState: ScreenState.Loading };
      })
      .addCase(getContributionsAsync.rejected, (prevState, action) => {
        return {
          ...prevState,
          superSimplifierContributionsState: ScreenState.ErrorUnableToLoadData,
          error: action.error.message,
        };
      })

      .addCase(getDocumentsAsync.fulfilled, (prevState, action) => {
        return {
          ...prevState,
          superSimplifierDocumentsState: ScreenState.SuccessDataLoaded,
          superSimplifierDocuments: action.payload,
        };
      })
      .addCase(getDocumentsAsync.pending, (prevState) => {
        return { ...prevState, superSimplifierDocumentsState: ScreenState.Loading };
      })
      .addCase(getDocumentsAsync.rejected, (prevState, action) => {
        return {
          ...prevState,
          superSimplifierDocumentsState: ScreenState.ErrorUnableToLoadData,
          error: action.error.message,
        };
      });
  },
});

export const { setSuperSimplifierAccountParameter } = SupersimplifierSlice.actions;

export const selectSupersimplifier = (state: RootState): SupersimplifierStore =>
  state.supersimplifier;

export default SupersimplifierSlice.reducer;
