/* eslint-disable no-debugger */
import container from '@ioc';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import {
  MoneysoftServiceClient,
  MoneysoftFinancialDetailsViewModel,
  MoneysoftITokenViewModel,
} from 'clientportalshared';
import {
  IAuthenticationService,
  IAuthenticationService$,
} from '@services/auth/IAuthentication.interface';
import ILoggingService, { ILoggingService$ } from '@services/logging/ILoggingService.interface';
import appConfig from '@config/appConfig';
import {
  moneysoftDetailApiThunkPayload,
  moneysoftInviteClientApiThunkPayload,
  moneysoftSingleSignOnTokenApiThunkPayload,
  moneysoftAsAtDateParameterThunkPayload,
} from '../../constants';

export const setMoneysoftAsAtDateParameter = createAsyncThunk(
  moneysoftAsAtDateParameterThunkPayload,
  (balanceAsAt: DateTime | null) => {
    const response = balanceAsAt;
    return response;
  }
);

export const getMoneysoftFinancialDetailsAsync = createAsyncThunk<
  MoneysoftFinancialDetailsViewModel,
  { balanceAsAt: string }
>(moneysoftDetailApiThunkPayload, async ({ balanceAsAt }) => {
  const authService = container.get<IAuthenticationService>(IAuthenticationService$);
  const loggingService = container.get<ILoggingService>(ILoggingService$);

  const moneysoftClient = new MoneysoftServiceClient(
    appConfig.apiClientportalEndpointUrl,
    authService,
    loggingService,
    appConfig
  );
  const result = await moneysoftClient.getMoneysoftFinancialDetails(balanceAsAt);
  return result;
});

export const getMoneysoftInviteClientAsync = createAsyncThunk<MoneysoftITokenViewModel>(
  moneysoftInviteClientApiThunkPayload,
  async () => {
    const authService = container.get<IAuthenticationService>(IAuthenticationService$);
    const loggingService = container.get<ILoggingService>(ILoggingService$);

    const moneysoftClient = new MoneysoftServiceClient(
      appConfig.apiClientportalEndpointUrl,
      authService,
      loggingService,
      appConfig
    );
    const result = await moneysoftClient.getMoneysoftInviteClient();
    if (result.singleSignOnToken) {
      const moneysoftUrl = `${appConfig.apiMoneysoftUrl}login?token=${result.singleSignOnToken}&source=${appConfig.appMoneysoftSource}`;
      window.open(moneysoftUrl, '_blank', 'noopener,noreferrer');
    }
    return result;
  }
);

export const getMoneysoftSingleSignOnTokenAsync = createAsyncThunk<MoneysoftITokenViewModel>(
  moneysoftSingleSignOnTokenApiThunkPayload,
  async () => {
    const authService = container.get<IAuthenticationService>(IAuthenticationService$);
    const loggingService = container.get<ILoggingService>(ILoggingService$);

    const moneysoftClient = new MoneysoftServiceClient(
      appConfig.apiClientportalEndpointUrl,
      authService,
      loggingService,
      appConfig
    );
    const result = await moneysoftClient.getMoneysoftSingleSignOnToken();
    if (result.singleSignOnToken) {
      const moneysoftUrl = `${appConfig.apiMoneysoftUrl}login?token=${result.singleSignOnToken}&source=${appConfig.appMoneysoftSource}`;
      window.open(moneysoftUrl, '_blank', 'noopener,noreferrer');
    }
    return result;
  }
);
