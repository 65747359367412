/* eslint-disable import/prefer-default-export */
import container from '@ioc';
import { Severity } from '@sentry/react';
import ILoggingService, { ILoggingService$ } from '@services/logging/ILoggingService.interface';

export const errorHandler = (
  error: Error,
  info: {
    componentStack: string;
  }
): void => {
  const loggingService = container.get<ILoggingService>(ILoggingService$);

  loggingService.log({
    message: error.message,
    level: Severity.Fatal,
    error,
    data: info,
  });
};
