import { DesktopDatePicker } from '@mui/lab';
import { Box, Stack, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { DateRange as SelectedDateRange } from '../../store';
import { DateTimeFormat } from '@src/app/utils/GenericUtils';
import WO2Button from '@components/button/Button';
import PreDefinedDatesSelect from '@components/DateRangePickerPreDefinedDates';
import './dateRange.scss';

interface Props {
  inceptionDate?: string;
  dateRange: SelectedDateRange;
  isDateOnly: boolean;
  setDateRange: (dateRange: SelectedDateRange) => void;
  handleCloseModal: () => void;
}

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaperDatePicker: {
    backgroundImage: `url(${Image})`,
    backgroundRepeat: 'no-repeat',
    padding: '20px 0 0 0',
    height: '250px',
    width: '520px',
  },
  buttonContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    verticalAlign: 'top',
    paddingTop: '30px',
    width: '100%',
  },
  datePicker: {
    display: 'inline-block',
    verticalAlign: 'top',
    paddingLeft: '26px',
    paddingTop: '10px',
    height: '80px',
    width: '234px',
  },
  preDefinedDates: {
    display: 'inline-block',
    verticalAlign: 'top',
    paddingLeft: '25px',
  },
}));

export default function DateRangePickerModal(props: Props): JSX.Element {
  const classes = useStyles();
  const [dateFromError, setDateFromError] = useState(false);
  const [dateToError, setdateToError] = useState(false);
  const [dateTo, setDateTo] = useState<DateTime | null>(DateTime.fromISO(props.dateRange.dateTo));
  const [dateFrom, setDateFrom] = useState<DateTime | null>(
    DateTime.fromISO(props.dateRange.dateFrom)
  );
  const [dateRangeType, setDateRangeType] = useState<string | null>('');

  const { isDateOnly } = props;

  const dateFromErrorFn = (error: React.ReactNode) => {
    if (error !== '') {
      setDateFromError(true);
    } else {
      setDateFromError(false);
    }
  };
  const dateToErrorFn = function (error: React.ReactNode) {
    if (error !== '') {
      setdateToError(true);
    } else {
      setdateToError(false);
    }
  };

  const handleDateChange = (
    materialUiPickersDate: DateTime | null,
    setFunction: (date: DateTime | null) => void
  ) => {
    setFunction(materialUiPickersDate ?? null);
  };

  const closeDatePicker = () => {
    if (dateFrom && dateTo && !dateFromError && !dateToError) {
      props.setDateRange({
        dateFrom: dateFrom.toISODate(),
        dateTo: dateTo.toISODate(),
        dateRangeType: dateRangeType,
      });

      props.handleCloseModal();
    }
  };

  return (
    <div className={classes.modalPaperDatePicker}>
      <div className={classes.preDefinedDates}>
        <PreDefinedDatesSelect
          dateTo={dateTo}
          setDateTo={setDateTo}
          dateFrom={dateFrom}
          setDateFrom={setDateFrom}
          inceptionDate={props.inceptionDate}
          isDateOnly={isDateOnly}
          setDateRangeType={setDateRangeType}
        />
      </div>
      <div>
        {props.isDateOnly && (
          <div className={classes.datePicker}>
            <DesktopDatePicker
              inputFormat={DateTimeFormat.Short.displayName}
              label="From date"
              value={dateTo}
              onChange={(e) => {
                handleDateChange(e, setDateTo);
              }}
              OpenPickerButtonProps={{
                'aria-label': 'change date',
              }}
              maxDate={DateTime.now()}
              onError={(error) => dateFromErrorFn(error)}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
            />
          </div>
        )}
        {!props.isDateOnly && (
          <Box paddingTop={'15px'} paddingLeft={'25px'}>
            <Stack direction="row" spacing={2}>
              <DesktopDatePicker
                inputFormat={DateTimeFormat.Short.displayName}
                label="From date"
                value={dateFrom}
                onChange={(e) => {
                  handleDateChange(e, setDateFrom);
                }}
                OpenPickerButtonProps={{
                  'aria-label': 'change date',
                }}
                maxDate={dateTo}
                onError={(error) => dateFromErrorFn(error)}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
              />

              <DesktopDatePicker
                inputFormat={DateTimeFormat.Short.displayName}
                label="To date"
                value={dateTo}
                onChange={(e) => {
                  handleDateChange(e, setDateTo);
                }}
                OpenPickerButtonProps={{
                  'aria-label': 'change date',
                }}
                minDate={dateFrom}
                maxDate={DateTime.now()}
                onError={(error) => dateToErrorFn(error)}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
              />
            </Stack>
          </Box>
        )}
      </div>
      <div style={{ width: '100%' }}>
        <div className={classes.buttonContainer}>
          <div></div>
          <div style={{ paddingRight: '20px' }}>
            <WO2Button
              variant="contained"
              color={!dateFromError && !dateToError ? 'primary' : 'inherit'}
              className="doneButton"
              sx={{ height: '2.625rem', textTransform: 'none' }}
              onClick={() => {
                closeDatePicker();
              }}
            >
              APPLY
            </WO2Button>
          </div>
        </div>
      </div>
    </div>
  );
}
