/* eslint-disable import/prefer-default-export */
import container from '@ioc';
import {
  ICommunicationService,
  ICommunicationService$,
} from '@services/communication/ICommunication.interface';
import appConfig from '@config/appConfig';
import { changePasswordUrl } from './UrlBuilder';

export const resetPassword = async (email: string): Promise<boolean> => {
  const communicationService = container.get<ICommunicationService>(ICommunicationService$);

  try {
    await communicationService.postAsync(
      changePasswordUrl(),
      {
        client_id: appConfig.auth0ClientId,
        email,
        connection: 'ClientPortal',
      },
      undefined,
      undefined,
      true
    );
    return true;
  } catch (e) {
    return false;
  }
};
