import { FC } from 'react';
import FusionCharts from 'fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import ReactFusioncharts from 'react-fusioncharts';
import { MoneysoftAssetChartProps } from '@src/app/types';

// Resolves charts dependancy
charts(FusionCharts);

const AssetChart: FC<MoneysoftAssetChartProps> = ({
  color,
  dataset,
  assetCategoryName,
}): JSX.Element => {
  const dataSource = {
    chart: {
      baseFont: 'Roboto',
      numvisibleplot: 0,
      showvalues: 0,
      showLabels: 0,
      showcanvasborder: '0',
      showPlotBorder: '1',
      plotBorderThickness: '2',
      plotBorderColor: '#ffffff',
      decimals: 0,
      showLegend: 0,
      showDivLineValues: 0,
      showYAxisValues: 0,
      showLimits: 0,
      stack100percent: '1',
      showTickMarks: 0,
      valuefontcolor: '#FFFFFF',
      paletteColors: color,
      plottooltext: `$seriesName: $displayValue`,
      theme: 'fusion',
      animation: 0,
    },
    categories: [
      {
        category: [
          {
            label: assetCategoryName,
          },
        ],
      },
    ],
    dataset,
  };
  return (
    <div style={{ margin: '5px' }}>
      <ReactFusioncharts
        type="stackedbar2d"
        width="100%"
        height="60"
        dataFormat="JSON"
        dataSource={dataSource}
      />
    </div>
  );
};

export default AssetChart;
