import FusionCharts, { ChartObject } from 'fusioncharts';
import { PerformanceChartData, PerformanceItem } from '@src/app/types';
import { Screen } from '@enums/index';
import strings from '@strings/LocalisedStrings';

export const chartSchema = [
  {
    name: strings.time,
    type: 'date',
    format: '%d-%m-%Y',
  },
  {
    name: strings.portfolio,
    type: 'number',
  },
  {
    name: strings.benchmark,
    type: 'number',
  },
];

export const initialChartConfig = (screen: Screen): ChartObject => {
  return {
    type: 'timeseries',
    width: '100%',
    height: '400',
    dataFormat: 'json',
    dataSource: {
      chart: {
        theme: 'fusion',
        numberPrefix: '$',
        lineThickness: '2',
        showLegend: 0,
      },
      tooltip: {
        style: {
          container: {
            width: '170px',
          },
          text: {},
        },
      },
      data: new FusionCharts.DataStore().createDataTable([], chartSchema),
      navigator: {
        enabled: 0,
      },
      yaxis: [
        {
          plot: [
            {
              value: strings.portfolio,
              type: 'line',
              title: '',
              connectnulldata: true,
            },
            {
              value: strings.benchmark,
              type: 'line',
              title: '',
              connectnulldata: true,
            },
          ],
          title: '',
          format:
            screen === Screen.PortfolioDetail
              ? {
                  suffix: '%',
                }
              : undefined,
        },
      ],
      extensions: {
        standardRangeSelector: {
          enabled: '0',
        },
        customRangeSelector: {
          enabled: '0',
        },
      },
    },
  };
};

export const getChartConfig = (
  fcChartConfig: ChartObject,
  chartData: PerformanceChartData[],
  benchmarkPerformanceData?: PerformanceItem[]
): ChartObject => {
  const newChartData: (string | number)[][] = [];

  if (chartData.length > 0) {
    const benchmarkDataMap = new Map<string, number>();
    if (benchmarkPerformanceData && benchmarkPerformanceData.length > 0) {
      benchmarkPerformanceData.forEach((benchmarkDataItem: PerformanceItem) => {
        benchmarkDataMap.set(benchmarkDataItem?.date, benchmarkDataItem.value);
      });
    }
    chartData.forEach((returnDatum: PerformanceChartData) => {
      const valueArr: (string | number)[] = [];
      const date = returnDatum?.label;

      const benchmarkReturnForCorrespondingDate: number | undefined = benchmarkDataMap.get(date);
      if (benchmarkReturnForCorrespondingDate) {
        valueArr.push(date);
        valueArr.push(returnDatum.value);
        valueArr.push(benchmarkReturnForCorrespondingDate);
      } else {
        valueArr.push(date);
        valueArr.push(returnDatum.value);
      }
      newChartData.push(valueArr);
    });
  }

  const fusionTable: FusionCharts.DataTable = new FusionCharts.DataStore().createDataTable(
    newChartData,
    chartSchema
  );

  const tempChartConfig = { ...fcChartConfig };
  const tempDataSource: Record<string, unknown> = { ...(tempChartConfig.dataSource || {}) };
  tempDataSource.data = fusionTable;
  tempChartConfig.dataSource = tempDataSource;

  return tempChartConfig;
};
