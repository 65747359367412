import { FC } from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { CustomSkeleton } from '@components/index';

const CommonLoader: FC = (): JSX.Element => (
  <Container sx={{ mt: 5, mb: 5 }}>
    <Stack spacing={2}>
      <CustomSkeleton variant="text" />
      <CustomSkeleton variant="rectangular" height={150} />
      <CustomSkeleton variant="rectangular" height={150} />

      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <CustomSkeleton variant="circular" height={300} width={300} />
        <Stack spacing={1} width="50%">
          <CustomSkeleton variant="rectangular" height={50} numberOfItems={5} />
        </Stack>
      </Stack>

      <Stack spacing={1} direction="row">
        <CustomSkeleton variant="rectangular" height={100} width="100%" numberOfItems={3} />
      </Stack>
    </Stack>
  </Container>
);

export default CommonLoader;
