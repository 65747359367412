import React, { FC, useState } from 'react';
import { useAppSelector } from '@hooks/store';
import { Link, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import HeaderLogo from '@images/dash-logo.svg';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { ReactComponent as DownChevronImg } from '@icons/downChevron.svg';
import { ReactComponent as UserImg } from '@icons/user.svg';
import { ReactComponent as SettingsImg } from '@icons/settings.svg';
import { ReactComponent as HomeImg } from '@icons/home.svg';
import checkBetweenDateRange from '@utils/DateRangeUtil';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';

import { NavigationItem } from '@components/index';
import {
  homeUrl,
  adviserUrl,
  profileUrl,
  documentsUrl,
  beneficiariesUrl,
  insuranceUrl,
  pensionUrl,
  benefitStatementUrl,
  contributionsUrl,
} from '@utils/UrlBuilder';
import { NavigationItemType } from '@src/app/types';
import { SupersimplifierStore, selectSupersimplifier } from '@store/reducers/supersimplifier';
import './_header.scss';
import strings from '@strings/LocalisedStrings';
import { Routes } from '@enums/index';
// import useLocalStorage from '@hooks/useLocalStorage';
import NotificationBanner from './NotificationBanner/NotificationBanner';

const Header: FC = (): JSX.Element => {
  const superSimplifierStore: SupersimplifierStore = useAppSelector(selectSupersimplifier);
  const location = useLocation();
  const isOnboarding = location.pathname.includes('onboarding');
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  // const [isNotificationBannerOpen, setIsNotificationBannerOpen] = useLocalStorage<boolean>(
  //   'notificationBanner',
  //   true
  // );

  const subMenu: NavigationItemType[] | boolean = [
    { name: strings.profile, key: Routes.Profile, route: profileUrl() },
    { name: strings.logout, key: Routes.Logout, route: '#' },
  ];

  const subMenuSuper: NavigationItemType[] | boolean = [];
  let isPensionAccount = false;
  let isSuperAccount = false;
  let showSuperMenu = false;

  if (superSimplifierStore.superSimplifierAccounts?.data?.length) {
    showSuperMenu = true;
    if (
      superSimplifierStore.superSimplifierAccounts.data[0].accountNumber &&
      superSimplifierStore.superSimplifierAccounts.data[0].name
    ) {
      isPensionAccount =
        !!superSimplifierStore.superSimplifierAccounts.data.find((i) => i.type === 'Pension') ||
        false;
      isSuperAccount =
        !!superSimplifierStore.superSimplifierAccounts.data.find((i) => i.type === 'Super') ||
        false;
    }
    subMenuSuper.push({
      name: 'Benefit Statement',
      key: Routes.BenefitStatement,
      route: benefitStatementUrl(),
    });
    subMenuSuper.push({
      name: 'Beneficiaries',
      key: Routes.Beneficiaries,
      route: beneficiariesUrl(),
    });
    if (isPensionAccount) {
      subMenuSuper.push({ name: 'Pension Details', key: Routes.Pension, route: pensionUrl() });
    }
    subMenuSuper.push({ name: 'Insurance', key: Routes.Insurance, route: insuranceUrl() });
    if (isSuperAccount) {
      subMenuSuper.push({
        name: 'Contributions',
        key: Routes.Contributions,
        route: contributionsUrl(),
      });
    }
    subMenuSuper.push({ name: 'Documents', key: Routes.Documents, route: documentsUrl() });
  }

  const navigationItems: NavigationItemType[] = [];
  navigationItems.push({
    name: strings.home,
    key: Routes.Home,
    leftIcon: <HomeImg />,
    route: homeUrl(),
  });
  navigationItems.push({
    name: strings.adviser,
    key: Routes.Adviser,
    leftIcon: <UserImg />,
    route: adviserUrl(),
  });
  if (showSuperMenu) {
    navigationItems.push({
      name: 'Super Simplifier',
      key: Routes.BenefitStatement,
      leftIcon: <SavingsOutlinedIcon />,
      rightIcon: <DownChevronImg />,
      menu: subMenuSuper,
    });
  }
  navigationItems.push({
    name: strings.profile,
    key: Routes.Profile,
    leftIcon: <SettingsImg />,
    rightIcon: <DownChevronImg />,
    menu: subMenu,
  });

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (page?: NavigationItemType, close = false) => {
    // don't close menu if there's a submenu (only close when close = true)
    if (page && page.menu && !close) return;
    setAnchorElNav(null);
  };

  const handleLoginPressed = async () => {
    await loginWithRedirect();
  };

  const LoginButton: FC<{ inverted?: boolean }> = ({ inverted }): JSX.Element => (
    <Button
      variant={inverted ? 'contained' : undefined}
      className={`loginBtn ${inverted ? 'loginBtnInverted' : ''}`}
      onClick={handleLoginPressed}
    >
      {strings.login}
    </Button>
  );

  // const handleCloseNotificationBanner = () => {
  //   setIsNotificationBannerOpen(false);
  // };

  //todo more useful to specify dates as process env variables
  const displayBetweenDates: boolean = checkBetweenDateRange(
    new Date('2023-10-17T00:00:00'),
    new Date('2023-10-18T00:00:00')
  );

  return (
    <>
      {/* {isNotificationBannerOpen && showSuperMenu && displayBetweenDates && ( */}
      {displayBetweenDates && <NotificationBanner />}
      {/* <NotificationBanner onClick={handleCloseNotificationBanner} /> */}
      <AppBar position="static" className="appHeader">
        <Container className="content">
          <Toolbar className="appHeaderToolbar">
            <Box className="appHeaderLogo" sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Link to={homeUrl()} color="inherit">
                <img src={HeaderLogo} alt="Dash logo" width="164" />
              </Link>
            </Box>

            {!isOnboarding && (
              <Box className="navigation" sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menuAppbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                {!isLoading && (
                  <Menu
                    id="menuAppbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={() => handleCloseNavMenu()}
                    sx={{
                      display: { xs: 'block', md: 'none' },
                    }}
                  >
                    {isAuthenticated ? (
                      navigationItems.map((page: NavigationItemType) => (
                        <MenuItem
                          key={page.name}
                          className="mobileNavMenu"
                          onClick={() => handleCloseNavMenu(page)}
                        >
                          <NavigationItem
                            key={page.name}
                            navigation={page}
                            handleCloseParentNavMenu={page.menu ? handleCloseNavMenu : undefined}
                          />
                        </MenuItem>
                      ))
                    ) : (
                      <LoginButton />
                    )}
                  </Menu>
                )}
              </Box>
            )}
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <Link to={homeUrl()} color="inherit">
                <img src={HeaderLogo} alt="Dash logo" width="164" />
              </Link>
            </Box>
            {!isLoading && (
              <Box
                sx={{
                  display: { xs: 'none', md: 'flex' },
                }}
                className="appHeaderUserProfile"
              >
                {isAuthenticated
                  ? navigationItems.map((page) => (
                      <NavigationItem key={page.name} navigation={page} />
                    ))
                  : !isOnboarding && <LoginButton inverted />}
              </Box>
            )}
          </Toolbar>
        </Container>
        <section className="contentBottom" />
      </AppBar>
    </>
  );
};

export default Header;
