import { FC, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useSnackbar } from 'notistack';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import MuiLink from '@mui/material/Link';
import { useAppDispatch, useAppSelector } from '@hooks/store';
import useQuery from '@hooks/useQuery';
import { createUserPasswordAsync, selectOnboardingStore } from '@store/reducers/onboarding';
import inputConfig from '@config/inputConfig';
import urlParamKeys from '@constants/urlParamKeys';
import {
  rootUrl,
  wealthO2TermsAndConditionsUrl,
  wealthO2PrivacyPolicyUrl,
  onboardingDoneUrl,
} from '@utils/UrlBuilder';
import { PageNotFound } from '@components/index';
import strings from '@strings/LocalisedStrings';
import { OnboardingErrorCode, ScreenState } from '@enums/index';
import styles from './index.module.scss';
import { getErrorText } from './onboarding.util';

const Onboarding: FC = (): JSX.Element => {
  const { isAuthenticated } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const dispatch = useAppDispatch();
  const { data, state } = useAppSelector(selectOnboardingStore);

  const query = useQuery();
  const userName = query.get(urlParamKeys.name);
  const userEmail = query.get(urlParamKeys.email);
  const onboardingKey = query.get(urlParamKeys.key);
  const isInvalidUrl = !userName || !userEmail || !onboardingKey;

  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    if (state === ScreenState.SuccessDataLoaded) {
      if (!data.success) {
        if (data.errorCode === OnboardingErrorCode.PasswordStrengthError) {
          setError(true);
          setErrorText(getErrorText(data.errorCode));
        } else {
          enqueueSnackbar(getErrorText(data.errorCode), {
            variant: 'error',
          });
        }
      } else {
        history.push(onboardingDoneUrl());
      }
    }
  }, [state]);

  const handlePasswordChanged = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (error) setError(false);
    setPassword(e.target.value);
  };
  const handlePasswordOnBlur = () => {
    if (password.length < inputConfig.minPasswordLength) {
      if (!error) {
        setError(true);
        setErrorText(strings.passwordMustBe);
      }
      return true;
    }
    if (password.length >= 8 && error) {
      setError(false);
    }
    return false;
  };
  const onCreateAccountClicked = () => {
    if (handlePasswordOnBlur()) return;
    if (error) {
      enqueueSnackbar(strings.resolveErrorFirst, {
        variant: 'error',
      });
      return;
    }
    setIsLoading(true);
    dispatch(createUserPasswordAsync({ key: onboardingKey!, email: userEmail!, password }))
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setError(true);
      });
  };

  if (isAuthenticated) {
    return <PageNotFound heading={strings.logoutBeforeInvitation} noBackButton />;
  }
  if (isInvalidUrl) {
    return <PageNotFound heading={strings.invalidInvitationLink} noBackButton />;
  }
  return (
    <Container className={styles.onboarding}>
      <Typography variant="h4">{strings.dashClientPortalAccountSetup}</Typography>
      <Typography>
        {strings.yourFinancialAdviser} {strings.hasInvitedYou}
      </Typography>
      <Typography variant="h6" mt={5}>
        {strings.yourAccountDetails}
      </Typography>
      <Stack spacing={3} my={2} className={styles.form}>
        {userName !== userEmail && (
          <div>
            <Typography className={styles.label}>{strings.name}</Typography>
            <div className={styles.nameContainer}>
              <Typography className={styles.name}>{userName}</Typography>
              <Link to={rootUrl()}>{strings.notYou}</Link>
            </div>
          </div>
        )}
        <div>
          <Typography className={styles.label}>{strings.emailAddress}</Typography>
          <div className={styles.nameContainer}>
            <Typography className={styles.name}>{userEmail}</Typography>
            {userName === userEmail && <Link to={rootUrl()}>{strings.notYou}</Link>}
          </div>
        </div>
        <div>
          <Typography className={styles.label}>{strings.password}</Typography>
          <TextField
            variant="outlined"
            type="password"
            placeholder={strings.newPassword}
            value={password}
            onChange={handlePasswordChanged}
            error={error}
            onBlur={handlePasswordOnBlur}
            helperText={
              <Typography
                className={error ? styles.helperText : styles.grayHelperText}
                component="div"
              >
                {error ? errorText : strings.chooseStrongPassword}
              </Typography>
            }
            fullWidth
          />
        </div>
        <div>
          <LoadingButton
            variant="contained"
            className={styles.btn}
            onClick={onCreateAccountClicked}
            loading={isLoading}
            fullWidth
          >
            {strings.createAccount}
          </LoadingButton>
          <Typography className={styles.agreement}>
            {strings.byCreatingAccount}{' '}
            <MuiLink href={wealthO2TermsAndConditionsUrl()} target="_blank">
              {strings.termsAndConditions}
            </MuiLink>{' '}
            {strings.and}{' '}
            <MuiLink href={wealthO2PrivacyPolicyUrl()} target="_blank">
              {strings.privacyPolicy}
            </MuiLink>
          </Typography>
        </div>
      </Stack>
    </Container>
  );
};

export default Onboarding;
