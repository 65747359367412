/* eslint-disable react/prop-types */
import { FC } from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
// import { percentageFormatter } from '@utils/GenericUtils';
import styles from './table.module.scss';

export interface Beneficiary {
  name?: string;
  nominationType?: string;
  percentage?: number;
  relationship?: string;
}

export interface Props {
  tableData: Beneficiary[] | undefined;
}

const ListedBeneficiaries = ({ tableData }: Props): JSX.Element => {
  const TableRows: FC = () => {
    if (tableData?.length) {
      return (
        <TableBody>
          {tableData.map((_item, index) => {
            const reactKey = `${index}index`;
            return (
              <TableRow key={reactKey}>
                <TableCell>
                  <Typography data-testid={`name${index}`} className={styles.rowData}>
                    {_item?.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography data-testid={`relationship${index}`} className={styles.rowData}>
                    {_item?.relationship}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography data-testid={`nominationType${index}`} className={styles.rowData}>
                    {_item?.nominationType}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography data-testid={`percentage${index}`} className={styles.rowData}>
                    {/* {percentageFormatter(_item?.percentage || 0)} */}
                    {_item?.percentage || 0}%
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      );
    }
    return <></>;
  };
  return (
    <>
      <Grid container>
        <Typography data-testid="containerHeader" variant="h6" sx={{ padding: '20px 0 20px 15px' }}>
          Beneficiaries
        </Typography>
      </Grid>
      <Paper
        elevation={5}
        className={styles.tableWrapper}
        sx={{ marginLeft: '15px', width: '100%' }}
      >
        <TableContainer component={Paper} elevation={0}>
          <Table>
            <TableHead>
              <TableRow className={styles.tableHeader}>
                <TableCell>
                  <Typography data-testid="header1">NAME</Typography>
                </TableCell>
                <TableCell>
                  <Typography data-testid="header2">RELATIONSHIP</Typography>
                </TableCell>
                <TableCell>
                  <Typography data-testid="header3">TYPE</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography data-testid="header4">PERCENTAGE</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableRows />
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default ListedBeneficiaries;
