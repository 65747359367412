import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import container from '@ioc';
import {
  ICommunicationService,
  ICommunicationService$,
} from '@services/communication/ICommunication.interface';
import { userOnboardingUrl } from '@utils/UrlBuilder';
import { Screen, ScreenState } from '@enums/index';
import { OnboardingResponse } from '@src/app/types';
import { onboardingApiThunkPayload } from '../../constants';
import { RootState } from '../..';

export interface OnboardingStore {
  state: ScreenState | undefined;
  data: OnboardingResponse;
  error?: string;
}

const initialState: OnboardingStore = {
  state: undefined,
  data: {} as OnboardingResponse,
};

export const createUserPasswordAsync = createAsyncThunk<
  OnboardingResponse,
  { email: string; key: string; password: string }
>(onboardingApiThunkPayload, async ({ email, key, password }) => {
  const communicationService = container.get<ICommunicationService>(ICommunicationService$);

  try {
    return await communicationService.postAnonymousAsync<OnboardingResponse>(
      userOnboardingUrl(),
      {
        email,
        key,
        password,
      },
      undefined,
      Screen.Onboarding,
      true
    );
  } catch (_error) {
    const error = _error as AxiosError<OnboardingResponse>;
    const errorResponse = error.response?.data;

    if (
      errorResponse &&
      !errorResponse.success &&
      errorResponse.errorCode &&
      errorResponse.message
    ) {
      return errorResponse;
    }
    throw error;
  }
});

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createUserPasswordAsync.fulfilled, (prevState, action) => {
        return {
          ...prevState,
          state: ScreenState.SuccessDataLoaded,
          data: action.payload,
        };
      })
      .addCase(createUserPasswordAsync.pending, (prevState) => {
        return { ...prevState, state: ScreenState.Loading };
      })
      .addCase(createUserPasswordAsync.rejected, (prevState, action) => {
        return {
          ...prevState,
          state: ScreenState.ErrorUnableToLoadData,
          error: action.error.message,
        };
      });
  },
});

export const selectOnboardingStore = (state: RootState): OnboardingStore => state.onboarding;
export default onboardingSlice.reducer;
