import { Button as MUIButton, ButtonProps as MUIButtonProps } from '@mui/material';
import * as React from 'react';

export interface ButtonProps extends MUIButtonProps {
  selected?: boolean;
  buttonType?: 'button' | 'reset' | 'submit';
  name?: string;
}

const WO2Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const { selected, buttonType, name } = props;
  return (
    <MUIButton
      name={name}
      disableElevation
      disableRipple={true}
      disableFocusRipple={true}
      className={!!selected ? 'selected' : ''}
      type={buttonType || 'button'}
      {...props}
    >
      {props.children}
    </MUIButton>
  );
};

export default WO2Button;
