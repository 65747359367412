/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { MUIDataTableColumn as MUIDataTableColumnBase, MUIDataTableMeta } from 'mui-datatables';
import { Holding } from 'clientportalshared';
import Typography from '@mui/material/Typography';
import {
  NumberRangePicker,
  customRender,
  customUpdate,
} from '@components/datatable/dataTableUtils';
import {
  numberFormatterDollar,
  numberFormatterHoldings,
  percentageFormatter,
} from '@utils/GenericUtils';
import strings from '@strings/LocalisedStrings';
import styles from './index.module.scss';

interface MUIDataTableColumn extends MUIDataTableColumnBase {
  name: keyof Holding;
}

const getColumns = (
  data: Holding[],
  assetClassFilterList: string[],
  securityFilterNames: string[] | undefined
): MUIDataTableColumn[] => {
  const columns: MUIDataTableColumn[] = [
    {
      name: 'assetClassKey',
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: 'assetClassName',
      label: strings.assetClasses,
      options: {
        display: false,
        filterList: assetClassFilterList,
      },
    },
    {
      name: 'securityName',
      label: strings.security,
      options: {
        filterOptions: {
          names: securityFilterNames,
        },
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return <Typography variant="body1">{strings.security}</Typography>;
        },
        customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
          const { rowIndex, currentTableData } = tableMeta;
          const code = currentTableData[rowIndex].data[3];
          return (
            <div>
              <Typography variant="body1" className={styles.securityValue}>
                {value}
              </Typography>
              <Typography variant="caption" className={styles.securityCode}>
                {code}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: 'securityCode',
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: 'units',
      label: strings.quantity,
      options: {
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <Typography
              variant="body1"
              align="right"
              style={{ textAlign: 'right', width: '100px' }}
            >
              {strings.quantity}
            </Typography>
          );
        },
        customBodyRenderLite: (dataIndex) => {
          return (
            <div>
              <Typography variant={'body2'} align="right" style={{ paddingRight: '20px' }}>
                {numberFormatterHoldings(data[dataIndex].units, 4)}
              </Typography>
            </div>
          );
        },
        filterType: 'custom',
        customFilterListOptions: {
          render: (filters) => customRender(filters, strings.quantity),
          update: customUpdate,
        },
        filterOptions: {
          fullWidth: true,
          logic: (value, filters) => {
            if (filters.length < 2 || !filters[0] || !filters[1]) return false;
            if (value >= filters[0] && value <= filters[1]) return false;
            return true;
          },
          display: (filterList, onChange, index, column) => (
            <NumberRangePicker
              title={strings.quantity}
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
            />
          ),
        },
      },
    },
    {
      name: 'price',
      label: strings.price,
      options: {
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <Typography variant="body1" align="right" style={{ textAlign: 'right', width: '56px' }}>
              {strings.price}
            </Typography>
          );
        },
        customBodyRenderLite: (dataIndex) => {
          return (
            <div>
              <Typography variant={'body2'} align="right" style={{ paddingRight: '20px' }}>
                {numberFormatterDollar(data[dataIndex].price, 4)}
              </Typography>
            </div>
          );
        },
        filterType: 'custom',
        customFilterListOptions: {
          render: (filters) => customRender(filters, strings.price),
          update: customUpdate,
        },
        filterOptions: {
          fullWidth: true,
          logic: (value, filters) => {
            if (filters.length < 2 || !filters[0] || !filters[1]) return false;
            if (value >= filters[0] && value <= filters[1]) return false;
            return true;
          },
          display: (filterList, onChange, index, column) => (
            <NumberRangePicker
              title={strings.price}
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
            />
          ),
        },
      },
    },
    {
      name: 'value',
      label: strings.marketValue,
      options: {
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <Typography variant="body1" align="right" style={{ textAlign: 'right' }}>
              {strings.marketValue}
            </Typography>
          );
        },
        customBodyRenderLite: (dataIndex) => {
          return (
            <div>
              <Typography variant={'body2'} align="right" style={{ paddingRight: '20px' }}>
                {numberFormatterDollar(data[dataIndex].value)}
              </Typography>
            </div>
          );
        },
        filterType: 'custom',
        customFilterListOptions: {
          render: (filters) => customRender(filters, strings.marketValue),
          update: customUpdate,
        },
        filterOptions: {
          fullWidth: true,
          logic: (value, filters) => {
            if (filters.length < 2 || !filters[0] || !filters[1]) return false;
            if (value >= filters[0] && value <= filters[1]) return false;
            return true;
          },
          display: (filterList, onChange, index, column) => (
            <NumberRangePicker
              title={strings.marketValue}
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
            />
          ),
        },
      },
    },
    {
      name: 'percentageOfPortfolio',
      label: strings.weight,
      options: {
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <Typography variant="body1" align="right" style={{ textAlign: 'right', width: '56px' }}>
              {strings.weight}
            </Typography>
          );
        },
        customBodyRenderLite: (dataIndex) => {
          return (
            <div>
              <Typography variant={'body2'} align="right" style={{ paddingRight: '20px' }}>
                {percentageFormatter(data[dataIndex].percentageOfPortfolio!)}
              </Typography>
            </div>
          );
        },
        filterType: 'custom',
        customFilterListOptions: {
          render: (filters) => customRender(filters, strings.weight),
          update: customUpdate,
        },
        filterOptions: {
          fullWidth: true,
          logic: (value, filters) => {
            if (filters.length < 2 || !filters[0] || !filters[1]) return false;
            if (value >= filters[0] && value <= filters[1]) return false;
            return true;
          },
          display: (filterList, onChange, index, column) => (
            <NumberRangePicker
              title={strings.weight}
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
            />
          ),
        },
      },
    },
    {
      name: 'costBase',
      label: strings.cost,
      options: {
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <Typography variant="body1" align="right" style={{ textAlign: 'right', width: '56px' }}>
              {strings.cost}
            </Typography>
          );
        },
        customBodyRenderLite: (dataIndex) => {
          return (
            <div>
              <Typography variant={'body2'} align="right" style={{ paddingRight: '20px' }}>
                {numberFormatterDollar(data[dataIndex].costBase)}
              </Typography>
            </div>
          );
        },
        filterType: 'custom',
        customFilterListOptions: {
          render: (filters) => customRender(filters, strings.cost),
          update: customUpdate,
        },
        filterOptions: {
          fullWidth: true,
          logic: (value, filters) => {
            if (filters.length < 2 || !filters[0] || !filters[1]) return false;
            if (value >= filters[0] && value <= filters[1]) return false;
            return true;
          },
          display: (filterList, onChange, index, column) => (
            <NumberRangePicker
              title={strings.cost}
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
            />
          ),
        },
      },
    },
    {
      name: 'estimatedGainOrLoss',
      label: strings.estLossGain,
      options: {
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <Typography variant="body1" align="right" style={{ textAlign: 'right' }}>
              {strings.estLossGain}
            </Typography>
          );
        },
        customBodyRenderLite: (dataIndex) => {
          return (
            <div>
              <Typography variant={'body2'} align="right" style={{ paddingRight: '20px' }}>
                {numberFormatterDollar(data[dataIndex].estimatedGainOrLoss)}
              </Typography>
            </div>
          );
        },
        filterType: 'custom',
        customFilterListOptions: {
          render: (filters) => customRender(filters, strings.estLossGain),
          update: customUpdate,
        },
        filterOptions: {
          fullWidth: true,
          logic: (value, filters) => {
            if (filters.length < 2 || !filters[0] || !filters[1]) return false;
            if (value >= filters[0] && value <= filters[1]) return false;
            return true;
          },
          display: (filterList, onChange, index, column) => (
            <NumberRangePicker
              title={strings.estLossGain}
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
            />
          ),
        },
      },
    },
  ];

  return columns;
};

export default getColumns;
