import { FC, MouseEvent, useState } from 'react';
import Menu from '@mui/material/Menu';
import { homeUrl, profileUrl, logoutCallbackUrl, benefitStatementUrl } from '@utils/UrlBuilder';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { NavigationItemType } from '@interfacetypes/index';
import './_navigationItem.scss';

interface NavigationItemProps {
  navigation: NavigationItemType;
  handleCloseParentNavMenu?: (page?: NavigationItemType | undefined, close?: boolean) => void;
}

const NavigationItem: FC<NavigationItemProps> = ({
  navigation,
  handleCloseParentNavMenu,
}): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [show, setShow] = useState(false);
  const { logout } = useAuth0();

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    if (navigation.menu) {
      setAnchorEl(event.currentTarget);
      setShow(true);
    }
  };

  const handleCloseNavMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (event.currentTarget.id === 'logout') {
      logout({ returnTo: logoutCallbackUrl() });
    }
    setAnchorEl(null);

    // close parent menu before unmounting submenu
    if (handleCloseParentNavMenu) handleCloseParentNavMenu(navigation, true);
    setShow(false);
  };

  return (
    <>
      <Link
        id="navigationItemWrapper"
        to={
          navigation.name === 'Super Simplifier'
            ? benefitStatementUrl()
            : navigation.route || profileUrl()
        }
        key={navigation.name}
        className="navigationItemWrapper"
        onClick={handleMenu}
      >
        <span className="navigationItemContent">
          {navigation.leftIcon && <span className="navigationLeftIcon">{navigation.leftIcon}</span>}
          <span className="navigationName">{navigation.name}</span>
          {navigation.rightIcon && (
            <span className="navigationRightIcon">{navigation.rightIcon}</span>
          )}
          {navigation.menu && (
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={show}
              onClose={handleCloseNavMenu}
            >
              {navigation.menu.map((menuItem: NavigationItemType) => {
                return (
                  <MenuItem key={menuItem.key}>
                    <Link
                      id={menuItem.key}
                      to={menuItem.route || homeUrl()}
                      onClick={handleCloseNavMenu}
                      className="navigationLink"
                    >
                      {menuItem.name}
                    </Link>
                  </MenuItem>
                );
              })}
            </Menu>
          )}
        </span>
      </Link>
    </>
  );
};

NavigationItem.defaultProps = {
  handleCloseParentNavMenu: undefined,
};

export default NavigationItem;
