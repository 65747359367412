import { interfaces } from 'inversify';

interface ICookieStorageService {
  get(key: string): string | undefined;
  set(key: string, value: string | number, expiresInSeconds: number): void;
  clear(key: string): void;
  clearAll(): void;
}

export default ICookieStorageService;

const ICookieStorageService$: interfaces.ServiceIdentifier<ICookieStorageService> =
  Symbol('ICookieStorageService');

export { ICookieStorageService$ };
