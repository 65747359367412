import { interfaces } from 'inversify';

interface ILocalStorageService {
  get(key: string): string | null;
  set(key: string, value: string): void;
  clear(key: string): void;
  clearAll(): void;
}

export default ILocalStorageService;

const ILocalStorageService$: interfaces.ServiceIdentifier<ILocalStorageService> =
  Symbol('ILocalStorageService');

export { ILocalStorageService$ };
