import { DateTime } from 'luxon';
import { PerformanceItem } from '@src/app/types';
import { ValueColor, DateTimeFormats, Locales, NumberFormats, Currency } from '@enums/index';
import colors from '@theme/Colors';
import Enumeration from './Enumeration';

export const dateFormatter = (
  date: string | Date,
  shouldIncludeTime = false,
  format = DateTimeFormats.DateOnly
): string => {
  if (typeof date === 'string') {
    if (shouldIncludeTime) {
      return DateTime.fromISO(date).toFormat(DateTimeFormats.DateWithTime);
    }
    return DateTime.fromISO(date).toFormat(format);
  }
  if (shouldIncludeTime) {
    return DateTime.fromJSDate(date).toFormat(DateTimeFormats.DateWithTime);
  }
  return DateTime.fromJSDate(date).toFormat(format);
};

export const dateFormatterToIso = (date: string | Date): string => {
  if (typeof date === 'string') return date;
  return DateTime.fromJSDate(date, { zone: 'utc' }).toISO();
};

export const dateFormatterToLocalIso = (date: string | Date): string => {
  if (typeof date === 'string') return date;
  return DateTime.fromJSDate(date, { zone: 'local' }).toISO();
};

export const currencyFormatter = (
  formatNumber: number | null | undefined,
  maxNumDigits = 0,
  minNumDigits = 0
): string => {
  if (formatNumber === null || formatNumber === undefined) {
    return '';
  }
  return new Intl.NumberFormat(Locales.Australia, {
    style: NumberFormats.Currency,
    currency: Currency.Australia,
    minimumFractionDigits: minNumDigits,
    maximumFractionDigits: maxNumDigits,
  }).format(formatNumber);
};

export const numberFormatter = (formatNumber: number | null | undefined, numDigits = 0): string => {
  if (formatNumber === null || formatNumber === undefined) {
    return '';
  }
  const result: string = new Intl.NumberFormat(Locales.Australia, {
    style: NumberFormats.Decimal,
    minimumFractionDigits: 0,
    maximumFractionDigits: numDigits,
  }).format(formatNumber);
  return result;
};

export const numberFormatterHoldings = (
  formatNumber: number | null | undefined,
  numDigits = 0
): string => {
  if (formatNumber === null || formatNumber === undefined) {
    return '0.00';
  }
  const formatNumberToFixed = Number(formatNumber.toFixed(numDigits));

  const result: string = new Intl.NumberFormat(Locales.Australia, {
    style: NumberFormats.Decimal,
    minimumFractionDigits: numDigits,
    maximumFractionDigits: numDigits,
  }).format(formatNumberToFixed);
  return result;
};

export const numberFormatterDollar = (
  val: number | null | undefined,
  decimals = 2
): string | null | undefined => {
  if (!val) {
    const result2 = ('$' + val == '0' ? '0.00' : val) as string | null | undefined;
    return result2 == '0' ? '$0.00' : result2;
  }
  const result = numberFormatterHoldings(val, decimals);
  if (result && result?.includes('-')) {
    return `${result.replace('-', '-$')}`;
  }
  return '$' + result;
};

export const percentageFormatter = (
  formatNumber: number,
  maximumFractionDigits = 2,
  minimumFractionDigits = 2
): string => {
  let maximumFractionDigitsLocal = maximumFractionDigits;
  if (formatNumber < 0.01) {
    maximumFractionDigitsLocal = 2;
  }
  const result: string = new Intl.NumberFormat(Locales.Australia, {
    style: NumberFormats.Percentage,
    minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigitsLocal,
  }).format(formatNumber);
  return result;
};

export const percentageWithSign = (returnPercentage: number): string =>
  `${returnPercentage > 0 ? '+' : ''}${percentageFormatter(returnPercentage)}`;

export const getColorStyle = (value: number | string, colorize: boolean): string => {
  if (!colorize) {
    return '';
  }
  if (Number(value) > 0) {
    return ValueColor.Positive;
  }
  if (Number(value) < 0) {
    return ValueColor.Negative;
  }
  return '';
};

export const modifyPercentageReturnValues = (returnData: PerformanceItem[]): PerformanceItem[] => {
  const returnDataObj = [...returnData];
  return returnDataObj?.map((item) => {
    const newItem = { ...item };
    newItem.value /= 100;
    return newItem;
  });
};

export const getSumOfKeysInAnArrayOfObjects = (listOfObjects: [], key: string): number => {
  if (!listOfObjects) {
    return 0;
  }
  return listOfObjects.reduce(
    (sumToReturn, currentIndexValue) => sumToReturn + (parseInt(currentIndexValue[key], 10) || 0),
    0
  );
};

export const getReturnColor = (value: number | undefined): string => {
  if (value) {
    return value > 0 ? colors.green : colors.red;
  }
  return colors.blackColor;
};

export const formatNumberCommaSeparated = (
  num: number | null | undefined,
  decimalPlaces?: number
): string => {
  return !!num || num === 0
    ? new Intl.NumberFormat('en-AU', {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
      }).format(num)
    : '';
};

export const financialYear = (): number => {
  const currentYear = DateTime.now().year;
  let currentFinancialYear = currentYear;
  if (DateTime.now().month >= 7 && DateTime.now().day >= 1) {
    currentFinancialYear += 1;
  }

  return currentFinancialYear;
};

export class DateTimeFormat extends Enumeration {
  static Short = new DateTimeFormat(1, 'Short', 'dd/MM/yyyy');
  static DateTime = new DateTimeFormat(2, 'DateTime', 'dd/MM/yyyy HH:mm:ss');
  static Filename = new DateTimeFormat(3, 'Filename', 'dd-MM-yyyy HH-mm-ss');
  static ChartMonthDate = new DateTimeFormat(4, 'ChartMonthDate', 'MMM-yy');
  static ChartDayDate = new DateTimeFormat(5, 'ChartDayDate', 'dd-MMM-yy');
  static DailyDate = new DateTimeFormat(6, 'DailyDate', 'dd-MMM-yyyy');
  static MonthlyDate = new DateTimeFormat(7, 'MonthlyDate', 'MMM yyyy');
  static DayDate = new DateTimeFormat(8, 'DayDate', 'dd MMM yyyy');
  static MonthDate = new DateTimeFormat(9, 'MonthDate', 'MMMM yyyy');
  static LetterDate = new DateTimeFormat(10, 'LetterDate', 'dd MMM yyyy');
  static LetterShortDate = new DateTimeFormat(11, 'LetterShortDate', 'd MMM yyyy');
  static DateTimeShort = new DateTimeFormat(12, 'DateTimeShort', 'dd/MM/yyyy hh:mm a');
  static BackendDate = new DateTimeFormat(13, 'BackendDate', 'yyyy-MM-dd');
}

type moment = string;
const pluralRules = new Intl.PluralRules('en-US', {
  type: 'ordinal',
});
const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
]);

export const getLocalDateTime = (
  isoDateTime: moment | undefined,
  format: DateTimeFormat,
  isUtc = false
): moment => {
  if (isoDateTime) {
    const dateTime = isUtc
      ? DateTime.fromISO(isoDateTime, { zone: 'utc' }).toLocal()
      : DateTime.fromISO(isoDateTime);

    if (format.id === DateTimeFormat.LetterDate.id) {
      return `${dateTime.day}${suffixes.get(pluralRules.select(dateTime.day)) || ''} ${
        dateTime.monthLong || ''
      } ${dateTime.year}`;
    }
    return dateTime.toFormat(format.displayName);
  }
  return '';
};

export const jsDateOnly = (inputDate: Date): Date => {
  const year = inputDate.getFullYear();
  const month = inputDate.getMonth();
  const date = inputDate.getDate();
  return new Date(`${year}/${month + 1}/${date}`);
};

export const SystemMinimumDate = '2000-01-01T00:00:00.00';
