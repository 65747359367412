import { FC, useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@hooks/store';
import {
  getSuperSimplifierAccountsAsync,
  getContributionsAsync,
} from '@store/reducers/supersimplifier/thunks';
import { ScreenState } from '@src/app/enums';
import {
  SupersimplifierStore,
  selectSupersimplifier,
  setSuperSimplifierAccountParameter,
} from '@store/reducers/supersimplifier';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { BackButton } from '@components/index';
import SupersimplifierTable, {
  SupersimplifierTableRow,
  TableHeaders,
} from '@components/superTables/supersimplifierTable';
import { homeUrl } from '@utils/UrlBuilder';
import { financialYear } from '@utils/GenericUtils';
import { Alert, Typography, Stack } from '@mui/material';
import { SuperSimplifierAccountSummary } from 'clientportalshared';

const Contributions: FC = (): JSX.Element => {
  const [yearSelected, setYear] = useState(financialYear());
  const [selectedAccount, setSelectedAccount] = useState('');
  const dispatch = useAppDispatch();
  const supersimplifierStore: SupersimplifierStore = useAppSelector(selectSupersimplifier);

  const getSuperSimplifierContributions = async (
    accountLis: SuperSimplifierAccountSummary[] | undefined
  ) => {
    if (accountLis?.length && accountLis[0].accountNumber) {
      await dispatch(
        getContributionsAsync({
          accountNumber: accountLis[0].accountNumber,
          financialYearEndingYear: financialYear(),
        })
      );
    }
  };

  const getPageData = () => {
    dispatch(getSuperSimplifierAccountsAsync())
      .unwrap()
      .then((responce) => {
        if (responce?.data?.length && responce.data[0].accountNumber) {
          setSelectedAccount(responce.data[0].accountNumber);
        }
        getSuperSimplifierContributions(responce.data);
      });
  };

  useEffect(() => {
    getPageData();
  }, []);

  const tableHeaders: TableHeaders = { header1: 'COMPONENT', header2: 'VALUE ($)' };
  let concessionalTable: SupersimplifierTableRow[] = [];
  let nonConcessionalTable: SupersimplifierTableRow[] = [];

  interface Contributions {
    assessableForeignFund: number | undefined;
    award: number | undefined;
    cgtSmallBusiness15YearExempt: number | undefined;
    cgtSmallBusinessRetirementExempt: number | undefined;
    coContribution: number | undefined;
    concessional: number | undefined;
    directedTerminationPaymentTaxFree: number | undefined;
    directedTerminationPaymentTaxable: number | undefined;
    employerVoluntary: number | undefined;
    firstHomeSaverAccount: number | undefined;
    firstHomeSuperSaverSchemeRecontribution: number | undefined;
    homeDownsize: number | undefined;
    insuranceProceeds: number | undefined;
    lowIncomeSuper: number | undefined;
    nonAssessableForeignFund: number | undefined;
    nonConcessional: number | undefined;
    otherFamilyAndFriend: number | undefined;
    personalInjury: number | undefined;
    salarySacrifice: number | undefined;
    spouseAndChild: number | undefined;
    superGuarantee: number | undefined;
  }

  if (supersimplifierStore.superSimplifierContributions) {
    const {
      assessableForeignFund,
      award,
      cgtSmallBusiness15YearExempt,
      cgtSmallBusinessRetirementExempt,
      coContribution,
      concessional,
      directedTerminationPaymentTaxFree,
      directedTerminationPaymentTaxable,
      employerVoluntary,
      firstHomeSaverAccount,
      firstHomeSuperSaverSchemeRecontribution,
      homeDownsize,
      insuranceProceeds,
      lowIncomeSuper,
      nonAssessableForeignFund,
      nonConcessional,
      otherFamilyAndFriend,
      personalInjury,
      salarySacrifice,
      spouseAndChild,
      superGuarantee,
    } = supersimplifierStore.superSimplifierContributions as Contributions;

    const nonConcessionalTotal =
      (assessableForeignFund ?? 0) +
      (cgtSmallBusiness15YearExempt ?? 0) +
      (cgtSmallBusinessRetirementExempt ?? 0) +
      (coContribution ?? 0) +
      (directedTerminationPaymentTaxFree ?? 0) +
      (firstHomeSuperSaverSchemeRecontribution ?? 0) +
      (firstHomeSaverAccount ?? 0) +
      (homeDownsize ?? 0) +
      (insuranceProceeds ?? 0) +
      (lowIncomeSuper ?? 0) +
      (nonAssessableForeignFund ?? 0) +
      (nonConcessional ?? 0) +
      (otherFamilyAndFriend ?? 0) +
      (personalInjury ?? 0) +
      (spouseAndChild ?? 0);

    nonConcessionalTable = [
      { label: 'Assessable Foreign Fund', value: assessableForeignFund },
      { label: 'CGT Small Bus 15 Exempt', value: cgtSmallBusiness15YearExempt },
      { label: 'CGT Small Business Retirement Exempt', value: cgtSmallBusinessRetirementExempt },
      { label: 'CoContribution', value: coContribution },
      { label: 'Directed Termination Payment Tax Free', value: directedTerminationPaymentTaxFree },
      { label: 'FHSS Recontribution', value: firstHomeSuperSaverSchemeRecontribution },
      { label: 'First Home Saver Account', value: firstHomeSaverAccount },
      { label: 'Home Downsize', value: homeDownsize },
      { label: 'Insurance Proceeds', value: insuranceProceeds },
      { label: 'Low Income Super', value: lowIncomeSuper },
      { label: 'Non Assessable Foreign Fund', value: nonAssessableForeignFund },
      { label: 'Non Concessional', value: nonConcessional },
      { label: 'Other Family and Friend', value: otherFamilyAndFriend },
      { label: 'Personal Injury', value: personalInjury },
      { label: 'Spouse And Child', value: spouseAndChild },
      { label: 'Total', value: nonConcessionalTotal },
    ];

    const concessionalTotal =
      (award ?? 0) +
      (concessional ?? 0) +
      (directedTerminationPaymentTaxable ?? 0) +
      (employerVoluntary ?? 0) +
      (salarySacrifice ?? 0) +
      (superGuarantee ?? 0);

    concessionalTable = [
      { label: 'Award', value: award },
      { label: 'Concessional', value: concessional },
      { label: 'Directed Termination Payment Taxable', value: directedTerminationPaymentTaxable },
      { label: 'Employer Voluntary', value: employerVoluntary },
      { label: 'Salary Sacrifice', value: salarySacrifice },
      { label: 'Super Guarantee', value: superGuarantee },
      { label: 'Total', value: concessionalTotal },
    ];
  }

  const setFinancialYear = (event: SelectChangeEvent) => {
    setYear(Number(event.target.value));
    if (
      supersimplifierStore?.superSimplifierAccounts?.data?.length &&
      supersimplifierStore.superSimplifierAccounts.data[0].accountNumber
    ) {
      dispatch(
        getContributionsAsync({
          accountNumber: supersimplifierStore.superSimplifierAccounts.data[0].accountNumber,
          financialYearEndingYear: Number(event.target.value),
        })
      );
    }
  };

  const handleAccountChange = (event: SelectChangeEvent) => {
    setSelectedAccount(event.target.value);
    dispatch(setSuperSimplifierAccountParameter(event.target.value));
    dispatch(
      getContributionsAsync({
        accountNumber: event.target.value,
        financialYearEndingYear: yearSelected,
      })
    );
  };

  let errorMessage = null;
  if (
    supersimplifierStore.superSimplifierContributionsState === ScreenState.ErrorUnableToLoadData
  ) {
    errorMessage =
      'There is currently no contribution information for this account. If the account has been recently established it may take a few days for this data to be populated. Please contact your adviser or our client service team for assistance.';
    concessionalTable = [];
    nonConcessionalTable = [];
  } else if (supersimplifierStore.superSimplifierContributionsState === ScreenState.Loading) {
    concessionalTable = [];
    nonConcessionalTable = [];
  }

  const financialYearOptions: number[] = [2023];
  for (let step = 2023; step < financialYear(); step += 1) {
    financialYearOptions.push((step += 1));
  }

  return (
    <Container>
      <BackButton url={homeUrl()} />
      <Grid container className="portfolioContent" rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} rowSpacing={{ xs: 2, md: 2 }}>
              <p className="pageHeader">Contributions</p>
            </Grid>
          </Grid>
          <Grid>
            <Typography sx={{ paddingTop: '20px' }}>
              Important Note: DIY Master Plan (Super Simplifier Division) issued by Diversa Trustees
              Limited (ABN 46 074 281 314) (&quot;former fund&quot;) provided the members
              contribution information paid up to and including 27 November 2022. All contributions
              made from 28 November 2022 onwards are to current Super Simplifier (&quot;fund&quot;)
              (ABN 36 526 795 205; USI 36 526 795 205 001) issued by Equity Trustee Superannuation
              Limited (&quot;Trustee&quot;) (ABN 50 055 641 757, AFSL 229757, RSE L0001458). The
              information provided by your former fund has been made available in good faith and the
              Trustee accepts no liability for any errors or omissions within the information
              provided by the former fund. If you have any queries in relation to the information
              provided, please contact DASH Client Services on 1300 726 008.
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} rowSpacing={{ xs: 2, md: 2 }} sx={{ marginTop: '20px' }}>
              <Select
                labelId="selected-account-select-label"
                id="selected-account-select"
                value={selectedAccount}
                defaultValue={selectedAccount}
                label="ACCOUNT"
                onChange={handleAccountChange}
              >
                {supersimplifierStore?.superSimplifierAccounts?.data
                  ?.filter((iii) => iii.type === 'Super')
                  .map((i) => (
                    <MenuItem key={i.accountNumber} value={i.accountNumber ?? ''}>{`${
                      i.accountNumber ?? ''
                    } - ${i.name ?? ''}`}</MenuItem>
                  ))}
              </Select>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} rowSpacing={{ xs: 2, md: 2 }} sx={{ marginTop: '20px' }}>
              <Typography variant="h6" sx={{ paddingBottom: '15px' }}>
                Financial year ending
              </Typography>
              <Select
                labelId="financial-year-select-label"
                id="financial-year-select"
                value={yearSelected.toString()}
                defaultValue={financialYear.toString()}
                label="ACCOUNT"
                onChange={setFinancialYear}
              >
                {financialYearOptions.map((ii) => (
                  <MenuItem key={ii} value={ii}>
                    {ii}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            {errorMessage && (
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  rowSpacing={{ xs: 2, md: 2 }}
                  sx={{ marginTop: '20px', marginLeft: '17px' }}
                >
                  <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert variant="outlined" severity="info">
                      {errorMessage}
                    </Alert>
                  </Stack>
                </Grid>
              </Grid>
            )}
            {!errorMessage && (
              <>
                <SupersimplifierTable
                  tableHeaders={tableHeaders}
                  data={concessionalTable}
                  header="Concessional"
                />

                <SupersimplifierTable
                  tableHeaders={tableHeaders}
                  data={nonConcessionalTable}
                  header="Non Concessional"
                />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Contributions;
