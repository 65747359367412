/* eslint-disable react/destructuring-assignment */
import { PortfolioSummary } from 'clientportalshared';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useHistory } from 'react-router-dom';
import {
  dateFormatter,
  percentageFormatter,
  getColorStyle,
  numberFormatterDollar,
} from '@utils/GenericUtils';
import { portfolioDetailsUrl } from '@utils/UrlBuilder';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ReturnValueDisplayMode from '@src/app/enums/ReturnValueDisplayMode.enum';
import strings from '@localizedstrings/LocalisedStrings';
import './_portfolioCard.scss';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

interface PortfolioCardProps extends PortfolioSummary {
  mode?: number;
  singleItem?: boolean;
}

const PortfolioCard = (props: PortfolioCardProps): JSX.Element => {
  const PortfolioKey = props.portfolioKey!;
  const Name = props.name!;
  const currentValue = props.currentValue || 0;
  const inceptionDate = props.inceptionDate!;
  const totalReturnValue = props.totalReturnValue!;
  const TotalReturnPercentage = props.totalReturnPercentage!;
  const mode = props.mode!;
  const singleItem = props.singleItem!;

  const history = useHistory();
  let valueToUseWhileComparing: number | string = '';
  switch (mode) {
    case ReturnValueDisplayMode.ValueOnly:
      valueToUseWhileComparing = totalReturnValue;
      break;
    case ReturnValueDisplayMode.PercentageOnly:
      valueToUseWhileComparing = totalReturnValue;
      break;
    case ReturnValueDisplayMode.ValueAndPercentage:
      // Note: If valueReturn and percentageReturn have same sign we are comparing based on valueReturn otherwise valueToUseWhileComparing will be undefined.
      if (
        (totalReturnValue >= 0 && TotalReturnPercentage >= 0) ||
        (totalReturnValue < 0 && TotalReturnPercentage < 0)
      ) {
        valueToUseWhileComparing = totalReturnValue;
      }
      break;
    default:
      break;
  }

  const handleNavigation = () => {
    history.push(portfolioDetailsUrl(PortfolioKey));
  };
  return (
    <Grid
      item
      xs={12}
      md={singleItem ? 12 : 6}
      className="portfolioCardWrapper"
      onClick={handleNavigation}
    >
      <Item className="portfolioCard">
        <div className="portfolioCardLeft">
          <h4 className="heading">{Name}</h4>
          <p className="netBalance">{numberFormatterDollar(currentValue)}</p>
          <h5 className="headingReturn">{`${strings.totalReturnDate} ${dateFormatter(
            inceptionDate
          )}`}</h5>
          <p className={`netPortfolioReturn ${getColorStyle(valueToUseWhileComparing, true)}`}>
            {`${totalReturnValue > 0 ? '+' : ''}${numberFormatterDollar(totalReturnValue)} (${
              TotalReturnPercentage > 0 ? '+' : ''
            }${percentageFormatter(TotalReturnPercentage)})`}
          </p>
        </div>
        <div className="portfolioCardRight">
          <ChevronRightIcon className="portfolioCardRightChevron" />
        </div>
      </Item>
    </Grid>
  );
};

PortfolioCard.defaultProps = {
  mode: '',
  singleItem: false,
};

export default PortfolioCard;
