/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/index';

export interface UserState {
  token: string | undefined;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: UserState = {
  token: undefined,
  status: 'idle',
};

export interface Response {
  data: string;
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
});

export const { setToken } = userSlice.actions;
export const getToken = (state: RootState): string | undefined => state.user.token;
export default userSlice.reducer;
