import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { formatNumberCommaSeparated, dateFormatter } from '@utils/GenericUtils';
import styles from './table.module.scss';

export interface SupersimplifierTableRow {
  label?: string;
  value?: Date | number | string;
}
export interface TableHeaders {
  header1?: string;
  header2?: string;
}

export interface Props {
  header: string;
  tableHeaders: TableHeaders;
  data: SupersimplifierTableRow[];
}

const displayValue = (value: Date | string | number | undefined) => {
  if (typeof value === 'number') {
    return `$${formatNumberCommaSeparated(value || 0, 2)}`;
  }

  if (typeof value === 'string' && value.split('T')[1] === '00:00:00') {
    return dateFormatter(value);
  }

  return value;
};

const SupersimplifierTable = ({ data, header, tableHeaders }: Props): JSX.Element => {
  return (
    <>
      <Grid container>
        <Typography variant="h6" data-testid="containerHeader" sx={{ padding: '20px 0 20px 15px' }}>
          {header}
        </Typography>
      </Grid>
      <Paper
        elevation={5}
        className={styles.tableWrapper}
        sx={{ marginLeft: '15px', width: '100%' }}
      >
        <TableContainer component={Paper} elevation={0}>
          <Table>
            <TableHead>
              <TableRow className={styles.tableHeader}>
                <TableCell>
                  <Typography data-testid="header1">{tableHeaders.header1}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography data-testid="header2">{tableHeaders.header2}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={`rk${Math.floor(Math.random() * 1000000)}`}>
                  <TableCell>
                    <Typography
                      data-testid={`label${index}`}
                      className={styles.rowTitle}
                      sx={row.label === 'Total' ? { fontWeight: 'bold' } : { fontWeight: 'normal' }}
                    >
                      {row.label}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      data-testid={`value${index}`}
                      className={styles.rowData}
                      sx={row.label === 'Total' ? { fontWeight: 'bold' } : { fontWeight: 'normal' }}
                    >
                      {displayValue(row.value)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default SupersimplifierTable;
