import { FC, useEffect } from 'react';
import { useParams } from 'react-router';
import Container from '@mui/material/Container';
import {
  IconHeading,
  BackButton,
  Datatable,
  CustomSkeleton,
  UnableToLoadData,
} from '@components/index';
import strings from '@strings/LocalisedStrings';
import dollarImg from '@icons/dollar.svg';
import { getTransactionsAsync, selectTransactions } from '@store/reducers/transactions';
import { ScreenState } from '@enums/index';
import { useAppDispatch, useAppSelector } from '@hooks/store';
import { portfolioDetailsUrl } from '@utils/UrlBuilder';
import getColumns from './datatableColumn';

const Transactions: FC = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  const { data, state } = useAppSelector(selectTransactions);

  const loadDataAsync = () => {
    dispatch(getTransactionsAsync({ portfolioKey: id, securityCode: null }));
  };

  useEffect(() => {
    loadDataAsync();
  }, []);

  const RenderDatatable: FC = (): JSX.Element => {
    if (state === undefined || state === ScreenState.Loading)
      return (
        <Container>
          <CustomSkeleton variant="rectangular" height={500} />
        </Container>
      );
    if (state === ScreenState.ErrorUnableToLoadData)
      return <UnableToLoadData onClick={loadDataAsync} />;
    return <Datatable data={data} columns={getColumns(data)} />;
  };

  return (
    <Container sx={{ mb: 4 }}>
      <BackButton url={portfolioDetailsUrl(id)} />
      <IconHeading
        icon={dollarImg}
        title={strings.transactionsHeading}
        subTitle={strings.recentTransactions}
        style={{ marginBottom: '2rem' }}
      />
      <RenderDatatable />
    </Container>
  );
};

export default Transactions;
