/* eslint-disable @typescript-eslint/no-explicit-any */
import { Severity } from '@sentry/types';

export default class LogEntryModel {
  message: string;

  level:
    | Severity.Critical
    | Severity.Debug
    | Severity.Error
    | Severity.Fatal
    | Severity.Info
    | Severity.Log
    | Severity.Warning;

  error: unknown;

  data?: {
    [key: string]: any;
  };

  constructor(
    message: string,
    level: Severity,
    data?: {
      [key: string]: any;
    },
    error?: unknown
  ) {
    this.message = message;
    this.data = data;
    this.level = level;
    this.error = error;
  }
}
