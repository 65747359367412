import { createTheme, Theme } from '@mui/material';
import colors from './Colors';

const getTheme = (clickableRows: boolean): Theme => {
  const theme = createTheme({
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          contentWrapper: {
            color: colors.green,
            textTransform: 'capitalize',
          },
          sortActive: {
            color: colors.green,
          },
        },
      },
      MUIDataTableBodyRow: {
        styleOverrides: {
          root: {
            '&:nth-of-type(odd)': {
              backgroundColor: colors.gray100,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'capitalize',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: `${colors.gray200} !important`,
            },
            cursor: clickableRows ? 'pointer' : 'default',
          },
        },
      },
      MUIDataTableToolbar: {
        styleOverrides: {
          actions: {
            textAlign: 'right',
          },
        },
      },
    },
  });

  return theme;
};

export default getTheme;
