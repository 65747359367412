/* eslint-disable no-debugger */
import { FC, useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import { useAuth0 } from '@auth0/auth0-react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Typography from '@mui/material/Typography';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import TextField from '@mui/material/TextField';
import FusionCharts from 'fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import { useAppSelector, useAppDispatch } from '@hooks/store';
import {
  MoneysoftStore,
  selectMoneysoft,
  setMoneysoftAsAtDateParameter,
} from '@store/reducers/moneysoft';
import {
  getMoneysoftFinancialDetailsAsync,
  getMoneysoftSingleSignOnTokenAsync,
} from '@store/reducers/moneysoft/thunks';
import { currencyFormatter, dateFormatter, numberFormatterDollar } from '@utils/GenericUtils';
import strings from '@strings/LocalisedStrings';
import buttonBackArrowImg from '@icons/button_back_arrow.svg';
import {
  CustomSkeleton,
  CommonLoader,
  UnableToLoadData,
  BackButton,
  IconHeading,
} from '@components/index';
import { ScreenState, DateTimeFormats } from '@src/app/enums';
import { homeUrl } from '@utils/UrlBuilder';
import { ChartData } from '@src/app/types';
import './dashboard.scss';
import { Button } from '@mui/material';
import asset from '@icons/asset.svg';
import { DateTime } from 'luxon';
import styles from './MoneysoftChart.module.scss';
import AssetCard from './AssetCard';
import AssetChart from './AssetChart';

// Resolves charts dependancy
charts(FusionCharts);

const MoneySoft: FC = (): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const dispatch = useAppDispatch();
  const moneysoftDataStore: MoneysoftStore = useAppSelector(selectMoneysoft);
  const moneysoftStore: MoneysoftStore = useAppSelector(selectMoneysoft);

  const getMoneysoftDataAsync = async () => {
    await dispatch(
      getMoneysoftFinancialDetailsAsync({ balanceAsAt: DateTime.now().toISO().split('T')[0] })
    );
  };

  const getMoneysoftSingleSignOnToken = async () => {
    handleToggle();
    await dispatch(getMoneysoftSingleSignOnTokenAsync()).then(() => handleClose());
  };

  const { user, isLoading } = useAuth0<{
    email: string;
    name: string;
    // eslint-disable-next-line camelcase
    given_name: string;
    // eslint-disable-next-line camelcase
    family_name: string;
  }>();

  useEffect(() => {
    getMoneysoftDataAsync();
  }, []);

  if (
    !moneysoftStore.moneysoftFinancialDetailsState ||
    moneysoftStore.moneysoftFinancialDetailsState === ScreenState.Loading
  )
    return <CommonLoader />;
  if (moneysoftStore.moneysoftFinancialDetailsState === ScreenState.ErrorUnableToLoadData) {
    return <UnableToLoadData onClick={getMoneysoftDataAsync} />;
  }

  const assetCategoryName = (assetCategory: number) => {
    let name = 'All';
    switch (assetCategory) {
      case 1: {
        name = 'Bank Accounts';
        return name;
      }
      case 2: {
        name = 'Property';
        return name;
      }
      case 3: {
        name = 'Vehicles';
        return name;
      }
      case 4: {
        name = 'Investments';
        return name;
      }
      case 5: {
        name = 'Credit Cards';
        return name;
      }
      case 6: {
        name = 'Loans';
        return name;
      }
      case 7: {
        name = 'Other Assets';
        return name;
      }
      case 8: {
        name = 'Loyalty';
        return name;
      }
      case 9: {
        name = 'Other Liabilities';
        return name;
      }
      case 10: {
        name = 'Insurance';
        return name;
      }
      case 11: {
        name = 'Reward';
        return name;
      }
      case 12: {
        name = 'Bills';
        return name;
      }
      case 13: {
        name = 'Superannuation';
        return name;
      }
      default: {
        return 'All';
      }
    }
  };

  const newData: ChartData[] = [];

  (() => {
    const financialAccountDetails =
      moneysoftStore.moneysoftFinancialDetails?.financialAccountDetails;

    financialAccountDetails?.forEach((item) => {
      if (!newData.length) {
        newData.push({
          assetCategory: item.assetCategory,
          assetCategoryName: assetCategoryName(item.assetCategory ?? 0),
          assetType: item.assetType,
          items: [],
        });
      }
      const yy = newData.find((ii) => ii.assetCategory === item.assetCategory);
      if (!yy) {
        newData.push({
          assetCategory: item.assetCategory,
          assetCategoryName: assetCategoryName(item.assetCategory ?? 0),
          assetType: item.assetType,
          items: [],
        });
      }
    });

    financialAccountDetails?.forEach((item) => {
      newData.forEach((ii) => {
        const displayValue =
          item.assetType === 2 ? -Math.abs(item.currentBalance ?? 0) : item.currentBalance ?? 0;
        if (item.assetCategory === ii.assetCategory) {
          ii.items.push({
            seriesname: item.name ?? '',
            data: [
              {
                value: displayValue,
                displayValue: currencyFormatter(displayValue, 2),
              },
            ],
          });
        }
      });
    });

    newData.sort((a, b) => {
      const nameA = a.assetCategoryName?.toUpperCase() ?? '';
      const nameB = b.assetCategoryName?.toUpperCase() ?? '';
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    newData.sort((a, b) => {
      return (a.assetType ?? 0) - (b.assetType ?? 0);
    });
  })();

  const LineItems: FC = () => {
    if (newData.length) {
      return (
        <>
          {newData.map((_item, index) => {
            const color = _item.assetType === 2 ? '#E74C3C' : '#28B463';
            const reactKey = `${index}index`;
            return (
              <Grid container key={reactKey}>
                <Grid item xs={12} md={6} rowSpacing={1}>
                  <AssetCard
                    color={color}
                    assetClass={_item.assetCategoryName ?? ''}
                    items={_item.items}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <AssetChart
                    color={color}
                    assetCategoryName={_item.assetCategoryName ?? ''}
                    dataset={_item.items}
                  />
                </Grid>
              </Grid>
            );
          })}
        </>
      );
    }
    return <></>;
  };

  const handleDatePickerState = (value?: boolean) => {
    const newValue = value || !openDatePicker;
    setOpenDatePicker(newValue);
  };

  const onDateChangedFromDatePicker = (newValue: DateTime | null) => {
    if (newValue) {
      dispatch(setMoneysoftAsAtDateParameter(newValue.toISO().split('T')[0]));
      dispatch(getMoneysoftFinancialDetailsAsync({ balanceAsAt: newValue.toISO().split('T')[0] }));
    }
  };

  let balanceFromMoneysoft = 0;

  if (moneysoftStore.moneysoftFinancialDetails?.financialAccountDetails?.length) {
    moneysoftStore.moneysoftFinancialDetails?.financialAccountDetails.forEach((lineItem) => {
      if (lineItem.assetType !== 2) {
        balanceFromMoneysoft += lineItem.currentBalance ?? 0;
      } else {
        balanceFromMoneysoft -= lineItem.currentBalance ?? 0;
      }
    });
  }

  return (
    <Container>
      <BackButton url={homeUrl()} />
      {!moneysoftDataStore.moneysoftFinancialDetails ? (
        <Stack spacing={1}>
          <CustomSkeleton variant="text" />
          <CustomSkeleton variant="rectangular" height={150} numberOfItems={2} />
        </Stack>
      ) : (
        <Grid container className="portfolioContent" rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} rowSpacing={{ xs: 2, md: 2 }}>
                <p className="pageHeader">
                  {!isLoading ? user?.given_name : ''} - {!isLoading ? user?.name : ''}{' '}
                </p>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                }}
              >
                <Button
                  variant="contained"
                  style={{ width: '200px' }}
                  id="launchMoneysoft"
                  onClick={getMoneysoftSingleSignOnToken}
                >
                  Launch Moneysoft
                </Button>
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={open}
                  onClick={handleClose}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            className="portfolioContent"
            style={{ paddingLeft: '20px', marginTop: '20px' }}
          >
            <Grid item xs={12} md={3.5} mb={4} className="portfolioInfo">
              <h5>CURRENT VALUE</h5>
              <p className="netWorth" style={{ paddingTop: '20px' }}>
                {numberFormatterDollar(balanceFromMoneysoft)}
              </p>
            </Grid>
          </Grid>

          <Grid container className="portfolioInfo" style={{ paddingLeft: '20px' }}>
            <Grid item xs={11}>
              <IconHeading icon={asset} title="BALANCES" />
            </Grid>
            <Grid item xs={1}>
              {' '}
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            rowSpacing={1}
            ml={0}
            sx={{ paddingTop: '30px', paddingLeft: '18px' }}
          >
            <Grid item xs={12} md={6} className={styles.allocationContainer}>
              <Typography variant="subtitle2">Balances as at</Typography>
              <Typography
                variant="subtitle2"
                component="button"
                className={styles.allocationDate}
                onClick={() => handleDatePickerState()}
              >
                {moneysoftStore.moneysoftAsAtDate &&
                  dateFormatter(moneysoftStore.moneysoftAsAtDate.toString())}
                {!moneysoftStore.moneysoftAsAtDate && dateFormatter(DateTime.now().toString())}
              </Typography>
              <img
                className={`rotateArrow ${styles.arrow}`}
                src={buttonBackArrowImg}
                alt={strings.next}
              />
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DesktopDatePicker
                  label="Date desktop"
                  inputFormat={DateTimeFormats.YearMonthDay}
                  value={moneysoftStore.moneysoftAsAtDate}
                  onChange={onDateChangedFromDatePicker}
                  open={openDatePicker}
                  onClose={() => handleDatePickerState(false)}
                  maxDate={DateTime.now()}
                  minDate={DateTime.fromJSDate(new Date('2000-01-01'))}
                  renderInput={(params) => (
                    <TextField className={styles.datePickerInput} {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
      )}
      <LineItems />
    </Container>
  );
};

export default MoneySoft;
