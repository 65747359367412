import { FC, CSSProperties } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { grey, blue } from '@mui/material/colors';
import { ReactComponent as DownloadImg } from '@icons/download.svg';
import styles from './index.module.scss';

const DownloadIcon: FC = (): JSX.Element => (
  <SvgIcon
    className={styles.svgIcon}
    style={{ '--color': grey[600], '--hover-color': blue[700] } as CSSProperties}
  >
    <DownloadImg />
  </SvgIcon>
);

export default DownloadIcon;
