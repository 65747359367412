import { FC, useEffect, useState } from 'react';
import container from '@ioc';
import {
  ICommunicationService,
  ICommunicationService$,
} from '@services/communication/ICommunication.interface';
import { useAppSelector, useAppDispatch } from '@hooks/store';
import {
  getSuperSimplifierAccountsAsync,
  getDocumentsAsync,
} from '@store/reducers/supersimplifier/thunks';
import { ScreenState } from '@src/app/enums';
import {
  SupersimplifierStore,
  selectSupersimplifier,
  setSuperSimplifierAccountParameter,
} from '@store/reducers/supersimplifier';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { BackButton, Datatable, CustomSkeleton, UnableToLoadData } from '@components/index';
import { homeUrl } from '@utils/UrlBuilder';
import { SuperSimplifierAccountSummary } from 'clientportalshared';
import getColumns from './datatableColumn';

const Documents: FC = (): JSX.Element => {
  const [isDownloading, setIsDownloading] = useState<{ [key: string]: boolean }>({});
  const [selectedAccount, setSelectedAccount] = useState('');
  const dispatch = useAppDispatch();
  const communicationService = container.get<ICommunicationService>(ICommunicationService$);
  const supersimplifierStore: SupersimplifierStore = useAppSelector(selectSupersimplifier);

  const getSuperSimplifierDocuments = async (
    accountLis: SuperSimplifierAccountSummary[] | undefined
  ) => {
    if (accountLis?.length && accountLis[0].accountNumber) {
      await dispatch(
        getDocumentsAsync({ accountNumber: accountLis[0].accountNumber, skip: 0, take: 100 })
      );
    }
  };

  const getPageData = () => {
    dispatch(getSuperSimplifierAccountsAsync())
      .unwrap()
      .then((responce) => {
        if (responce?.data?.length && responce.data[0].accountNumber) {
          setSelectedAccount(responce.data[0].accountNumber);
        }
        getSuperSimplifierDocuments(responce.data);
      });
  };

  useEffect(() => {
    getPageData();
  }, []);

  const downloadPdfAsync = (attachmentUrl: string, documentKey: string, filename: string) => {
    setIsDownloading((prev) => ({ ...prev, [documentKey]: true }));
    communicationService
      .downloadPdfAsync(attachmentUrl, `${filename}.pdf`)
      .then(() =>
        setIsDownloading((prev) => {
          const newValues = { ...prev };
          delete newValues[documentKey];
          return newValues;
        })
      )
      .catch(() => {
        setIsDownloading((prev) => ({ ...prev, [documentKey]: false }));
      });
  };

  const handleAccountChange = (event: SelectChangeEvent) => {
    setSelectedAccount(event.target.value);
    dispatch(setSuperSimplifierAccountParameter(event.target.value));
    dispatch(getDocumentsAsync({ accountNumber: event.target.value, skip: 0, take: 100 }));
  };

  const RenderDatatable: FC = (): JSX.Element => {
    if (
      supersimplifierStore.superSimplifierDocumentsState === undefined ||
      supersimplifierStore.superSimplifierDocumentsState === ScreenState.Loading
    )
      return (
        <Container>
          <CustomSkeleton variant="rectangular" height={500} />
        </Container>
      );
    if (supersimplifierStore.superSimplifierDocumentsState === ScreenState.ErrorUnableToLoadData)
      return <UnableToLoadData onClick={getPageData} />;
    return (
      <Datatable
        data={supersimplifierStore.superSimplifierDocuments?.data || []}
        columns={getColumns(downloadPdfAsync, isDownloading)}
        options={{ download: false }}
      />
    );
  };

  return (
    <Container>
      <BackButton url={homeUrl()} />
      <Grid container className="portfolioContent" rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} rowSpacing={{ xs: 2, md: 2 }}>
              <p className="pageHeader">Documents</p>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} rowSpacing={{ xs: 2, md: 2 }} sx={{ marginTop: '20px' }}>
              <Select
                labelId="selected-account-select-label"
                id="selected-account-select"
                value={selectedAccount}
                defaultValue={selectedAccount}
                label="ACCOUNT"
                onChange={handleAccountChange}
              >
                {supersimplifierStore?.superSimplifierAccounts?.data?.map((i) => (
                  <MenuItem key={i.accountNumber} value={i.accountNumber ?? ''}>{`${
                    i.accountNumber ?? ''
                  } - ${i.name ?? ''}`}</MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sx={{ marginTop: '20px', marginLeft: '18px', width: '100%' }}>
          <RenderDatatable />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Documents;
