export const FailureResponse = {
  status: 400,
  data: {},
  headers: undefined,
};

export const UnauthorizedResponse = {
  status: 401,
  data: {},
  headers: undefined,
};
