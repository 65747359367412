import { FC } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import strings from '@strings/LocalisedStrings';
import { BackButton } from '..';

interface PageNotFoundProps {
  heading?: string;
  noBackButton?: boolean;
}

const PageNotFound: FC<PageNotFoundProps> = ({ heading, noBackButton = false }): JSX.Element => {
  return (
    <Container>
      {!noBackButton && <BackButton />}
      <Typography variant="h5" textAlign="center">
        {heading}
      </Typography>
    </Container>
  );
};

PageNotFound.defaultProps = {
  heading: strings.pageNotFound,
  noBackButton: false,
};

export default PageNotFound;
