import { DateTime } from 'luxon';
import { MUIDataTableColumn as MUIDataTableColumnBase, MUIDataTableMeta } from 'mui-datatables';
import { DocumentSummary } from 'clientportalshared';
import { DateTimeFormats } from '@enums/index';
import { dateFormatter } from '@utils/GenericUtils';
import { DateRangePicker, customRender, customUpdate } from '@components/datatable/dataTableUtils';
import { Download, Warning } from '@mui/icons-material';
import strings from '@strings/LocalisedStrings';
import styles from './index.module.scss';

interface MUIDataTableColumn extends MUIDataTableColumnBase {
  name: keyof DocumentSummary;
}

const getColumns = (
  downloadPdfAsync: (attachmentUrl: string, documentKey: string, filename: string) => void,
  isDownloading: { [key: string]: boolean }
): MUIDataTableColumn[] => {
  const columns: MUIDataTableColumn[] = [
    {
      name: 'documentKey',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'name',
      label: 'Document Name',
      options: {
        filter: false,
        filterType: 'textField',
        filterOptions: {
          fullWidth: true,
        },
      },
    },
    {
      name: 'createdOnUtc',
      label: strings.dateAdded,
      options: {
        filterType: 'custom',
        customBodyRender: (value) => dateFormatter(value),
        customFilterListOptions: {
          render: (filters) => customRender(filters, strings.date, dateFormatter),
          update: customUpdate,
        },
        filterOptions: {
          fullWidth: true,
          logic: (columnDate, filters) => {
            if (filters.length < 2 || !filters[0] || !filters[1]) return false;

            const columnDateConverted = DateTime.fromFormat(columnDate, DateTimeFormats.DateOnly);
            const dateRangeFrom = DateTime.fromISO(filters[0]);
            const dateRangeTo = DateTime.fromISO(filters[1]);

            if (columnDateConverted >= dateRangeFrom && columnDateConverted <= dateRangeTo)
              return false;

            return true;
          },
          display: (filterList, onChange, index, column) => (
            <DateRangePicker
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
            />
          ),
        },
      },
    },
    {
      name: 'url',
      label: strings.action,
      options: {
        filter: false,
        customBodyRender: (value: string, meta: MUIDataTableMeta) => {
          const documentKey = String(meta.rowData[0]);
          const filename = String(meta.rowData[1]);

          if (isDownloading[documentKey]) return <div className="spinningLoader" />;
          if (isDownloading[documentKey] === false) return <Warning style={{ color: 'red' }} />;
          return (
            <button
              type="button"
              className={styles.downloadBtn}
              onClick={() => downloadPdfAsync(value, documentKey, filename)}
            >
              <span>{strings.download}</span>
              <Download />
            </button>
          );
        },
      },
    },
  ];

  return columns;
};

export default getColumns;
