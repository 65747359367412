enum Screen {
  Login = 'login',
  Biometric = 'biometric',
  FirstUseSequence = 'firstUseSequence',
  PushNotifications = 'pushNotifications',
  App = 'app',
  Portfolio = 'portfolio',
  PortfolioDetail = 'portfolioDetail',
  PortfolioReports = 'portfolioReports',
  PortfolioTransactions = 'portfolioTransactions',
  PortfolioDetailPerformanceSection = 'portfolioDetailPerformanceSection',
  Auth = 'auth',
  HoldingDetails = 'holdingDetails',
  HoldingTransactions = 'holdingTransactions',
  HoldingTransactionsLoadMoreSection = 'holdingTransactionsLoadMoreSection',
  PortfolioTransactionsLoadMoreSection = 'portfolioTransactionsLoadMoreSection',
  ReportsLoadMoreSection = 'reportsLoadMoreSection',
  AssetClassSelector = 'assetClassSelector',
  TabNavigator = 'tabNavigator',
  AllReports = 'allReports',
  PdfViewer = 'pdfViewer',
  BenchmarkSelector = 'benchmarkSelector',
  AssetAllocationSection = 'assetAllocationSection',
  Settings = 'settings',
  Adviser = 'adviser',
  EnvironmentSelector = 'environmentSelector',
  SettingsWebView = 'settingsWebView',
  AdviserContactSelector = 'adviserContactSelector',
  DevTools = 'devTools',
  WhatsNewV170 = 'whatsNewV1_7_0',
  Onboarding = 'onboarding',
}

export default Screen;
