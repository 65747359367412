import { FC, HTMLAttributeAnchorTarget, ReactNode, useEffect, useState } from 'react';
import { AdviserSummary } from 'clientportalshared';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useAppDispatch, useAppSelector } from '@hooks/store';
import { selectDashboard, DashboardStore, getDashboardAsync } from '@store/reducers/dashboard';
import { CustomSkeleton, UnableToLoadData } from '@components/index';
import strings from '@localizedstrings/LocalisedStrings';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { ScreenState } from '@src/app/enums';
import adviserHome from '@icons/adviserHome.svg';
import adviserPhone from '@icons/adviserPhone.svg';
import adviserEmail from '@icons/adviserEmail.svg';
import './adviser.scss';

interface StyledButtonProps {
  href: string;
  target: HTMLAttributeAnchorTarget;
  children: ReactNode;
}

export const StyledButton: FC<StyledButtonProps> = ({ href, children, target }): JSX.Element => (
  <Button
    className="advisorButton"
    sx={{ height: '2.625rem', textTransform: 'none' }}
    variant="contained"
    target={target}
    href={href}
  >
    {children}
  </Button>
);

const AdviserPage: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const dashboardDataStore: DashboardStore = useAppSelector(selectDashboard);
  const dashboardData = dashboardDataStore.data;
  const [adviserData, setAdviserData] = useState<AdviserSummary | undefined>(undefined);

  const getDashboardDataAsync = async () => {
    await dispatch(getDashboardAsync());
  };

  useEffect(() => {
    // if dashboard api is not called, call dashboard api
    if (dashboardDataStore.state === undefined) {
      getDashboardDataAsync();
    } else if (dashboardDataStore.state === ScreenState.SuccessDataLoaded) {
      if (Object.keys(dashboardData).length > 0) {
        setAdviserData(dashboardData.adviser);
      }
    }
  }, [dashboardDataStore.state]);

  if (dashboardDataStore.state === undefined || dashboardDataStore.state === ScreenState.Loading)
    return (
      <Container>
        <Stack spacing={1}>
          <CustomSkeleton variant="text" />
          <CustomSkeleton variant="rectangular" height={150} />
        </Stack>
      </Container>
    );
  if (dashboardDataStore.state === ScreenState.ErrorUnableToLoadData) return <UnableToLoadData />;
  return (
    <Container maxWidth="md">
      <Box mt={5} mb={5} className="adviserContent">
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <h5 className="heading">{strings.adviser}</h5>
            <p className="name">{adviserData?.name}</p>
            {adviserData?.afsl && (
              <div className="infoRow">
                <img src={adviserHome} alt="icon" />
                <p className="infoText">{adviserData?.afsl.name}</p>
              </div>
            )}
            {adviserData?.emailAddress && (
              <div className="infoRow">
                <img src={adviserEmail} alt="icon" />
                <p className="infoText">{adviserData?.emailAddress}</p>
              </div>
            )}
            {adviserData?.mobilePhoneNumber && (
              <div className="infoRow">
                <img src={adviserPhone} alt="icon" />
                <p className="infoText">{adviserData?.mobilePhoneNumber}</p>
              </div>
            )}
            <Stack spacing={3} mt={6}>
              {adviserData?.emailAddress && (
                <StyledButton href={`mailto:${adviserData?.emailAddress}`} target="_self">
                  {strings.email}
                </StyledButton>
              )}
              {adviserData?.calendarUrl && (
                <StyledButton target="_blank" href={adviserData?.calendarUrl}>
                  {strings.scheduleAppointment}
                </StyledButton>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AdviserPage;
