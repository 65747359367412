import { FC, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { resetPassword } from '@utils/Auth0Utils';
import { CustomSkeleton } from '@components/index';
import strings from '@strings/LocalisedStrings';
import adviserEmail from '@icons/adviserEmail.svg';
import buttonBackArrow from '@icons/button_back_arrow.svg';
import styles from './index.module.scss';

const Profile: FC = (): JSX.Element => {
  const { user, isLoading } = useAuth0<{
    email: string;
    name: string;
    // eslint-disable-next-line camelcase
    given_name: string;
    // eslint-disable-next-line camelcase
    family_name: string;
  }>();

  const [changePasswordLoading, setChangePasswordLoading] = useState(false);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);

  const handleModalOpen = () => {
    setChangePasswordModalOpen(true);
  };
  const handleModalClose = () => {
    setChangePasswordModalOpen(false);
  };

  const handleChangePasswordPressed = async (email: string) => {
    setChangePasswordLoading(true);
    await resetPassword(email);
    handleModalOpen();
    setChangePasswordLoading(false);
  };

  const RenderButtons: FC = (): JSX.Element => {
    if (!isLoading && user && user.email && !changePasswordLoading) {
      return (
        <Button
          className={styles.changePasswordBtn}
          onClick={() => handleChangePasswordPressed(user.email)}
        >
          <span>{strings.changePassword}</span>
          <img className="rotateArrow" src={buttonBackArrow} alt="" />
        </Button>
      );
    }
    return (
      <LoadingButton loading className={styles.loadingBtn}>
        <span>{strings.changePassword}</span>
      </LoadingButton>
    );
  };

  return (
    <Container>
      <Box mt={5} mb={5} className={styles.profilePage}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <h5 className={styles.heading}>{strings.profile}</h5>
            {isLoading ? (
              <Stack mb={2}>
                <CustomSkeleton variant="rectangular" height={150} width={250} />
              </Stack>
            ) : (
              <>
                <p className={styles.name}>
                  {user?.given_name} {user?.family_name}
                </p>
                <div className={styles.infoRow}>
                  <img src={adviserEmail} alt="icon" />
                  <p>{user?.email}</p>
                </div>
              </>
            )}
            <hr />
            <RenderButtons />
            <hr />
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={changePasswordModalOpen}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{strings.changePasswordModalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {strings.changePasswordModalMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} autoFocus>
            {strings.ok}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Profile;
