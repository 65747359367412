import { FC, useEffect, useState } from 'react';
import container from '@ioc';
import { Redirect } from 'react-router-dom';
import {
  IAuthenticationService,
  IAuthenticationService$,
} from '@services/auth/IAuthentication.interface';
import { useAppDispatch } from '@hooks/store';
import { setAuthState } from '@store/reducers/auth';
import AuthenticationStateEnum from '@services/auth/AuthenticationState.enum';
import { rootUrl } from '@utils/UrlBuilder';
import { CommonLoader } from '@components/loaders';

const Logout: FC = (): JSX.Element => {
  const authenticationService = container.get<IAuthenticationService>(IAuthenticationService$);
  const dispatch = useAppDispatch();

  const [isCleanupComplete, setIsCleanupComplete] = useState(false);

  const cleanupFunction = () => {
    dispatch(setAuthState(AuthenticationStateEnum.LOGGED_OFF));
    authenticationService.logoutAsync();
    setIsCleanupComplete(true);
  };

  useEffect(() => {
    cleanupFunction();
  }, []);

  if (isCleanupComplete) return <Redirect to={rootUrl()} />;
  return <CommonLoader />;
};

export default Logout;
