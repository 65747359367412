/* eslint-disable no-debugger */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import container from '@ioc';
import { DateTime } from 'luxon';
import { AdviserSummary, DashboardClient, Portfolio, ValueOverTimeItem } from 'clientportalshared';
import {
  IAuthenticationService$,
  IAuthenticationService,
} from '@services/auth/IAuthentication.interface';
import ILoggingService, { ILoggingService$ } from '@services/logging/ILoggingService.interface';
import appConfig from '@config/appConfig';
import { ScreenState } from '@enums/index';
import { dashboardApiThunkPayload } from '../../constants';
import type { RootState } from '../../index';

export interface Dashboard {
  name: string | undefined;
  currentValue: number;
  totalReturnValue: number;
  inceptionDate: string;
  valueOverTimeList: ValueOverTimeItem[];
  portfolios: Portfolio[];
  adviser: AdviserSummary | undefined;
}

export interface DashboardStore {
  state: ScreenState;
  error?: string;
  data: Dashboard;
}

const initialState = {} as DashboardStore;

export const getDashboardAsync = createAsyncThunk<Dashboard>(dashboardApiThunkPayload, async () => {
  const authService = container.get<IAuthenticationService>(IAuthenticationService$);
  const loggingService = container.get<ILoggingService>(ILoggingService$);

  const dashboardClient = new DashboardClient(
    appConfig.apiEndpointUrl,
    authService,
    loggingService,
    appConfig
  );

  const result = await dashboardClient.getDashboard(null, null);
  return {
    ...result,
    inceptionDate: result.inceptionDate ? DateTime.fromJSDate(result.inceptionDate).toISO() : '',
  };
});

export const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getDashboardAsync.fulfilled, (prevState, action) => {
        return {
          ...prevState,
          state: ScreenState.SuccessDataLoaded,
          data: action.payload,
        };
      })
      .addCase(getDashboardAsync.pending, (prevState) => {
        return { ...prevState, state: ScreenState.Loading };
      })
      .addCase(getDashboardAsync.rejected, (prevState, action) => {
        return {
          ...prevState,
          state: ScreenState.ErrorUnableToLoadData,
          error: action.error.message,
        };
      });
  },
});

export const selectDashboard = (state: RootState): DashboardStore => state.dashboard;

export default DashboardSlice.reducer;
