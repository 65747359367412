/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import strings from '@strings/LocalisedStrings';
import styles from './index.module.scss';

interface PaginationComponentProps {
  itemsPerPage: number;
  items: any[];
  Container: FC<{
    items: any[];
  }>;
  outerContainerClassName?: string;
}

const PaginationComponent: FC<PaginationComponentProps> = ({
  itemsPerPage,
  items,
  Container,
  outerContainerClassName = '',
}): JSX.Element => {
  const [currentItems, setCurrentItems] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const handlePageClick = (selectedItem: { selected: number }) => {
    const newOffset = (selectedItem.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  const ShowPageEntries: FC = (): JSX.Element => {
    const totalAssets = items.length;
    const firstIndex = itemOffset + 1;
    let secondIndex = itemOffset + itemsPerPage;

    if (secondIndex > totalAssets) secondIndex = totalAssets;

    return (
      <div className={styles.showPageEntries}>
        Showing {`${firstIndex} - ${secondIndex} of ${totalAssets} entries`}
      </div>
    );
  };

  const needPaginationComponent = items.length > itemsPerPage;

  return (
    <div
      className={`${styles.paginationComponent} ${outerContainerClassName} ${
        !needPaginationComponent ? styles.noPagination : ''
      }`}
    >
      <Container items={currentItems} />
      <div className={styles.paginationContainer}>
        {needPaginationComponent && (
          <>
            <ShowPageEntries />
            <br />
            <ReactPaginate
              containerClassName={styles.paginateContainer}
              nextClassName={styles.nextText}
              previousClassName={styles.previousText}
              activeClassName={styles.active}
              pageClassName={styles.page}
              disabledClassName={styles.disabled}
              breakLabel="..."
              nextLabel={strings.next}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel={strings.previous}
              renderOnZeroPageCount={undefined}
            />
          </>
        )}
      </div>
    </div>
  );
};

PaginationComponent.defaultProps = {
  outerContainerClassName: '',
};

export default PaginationComponent;
