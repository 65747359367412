/* eslint-disable import/prefer-default-export */
import { OnboardingErrorCode } from '@enums/index';
import strings from '@strings/LocalisedStrings';

export const getErrorText = (errorCode: OnboardingErrorCode): string => {
  switch (errorCode) {
    case OnboardingErrorCode.GenericError:
      return strings.invalidInvitationLink;

    case OnboardingErrorCode.InvitationExpired:
      return strings.invitationLinkExpired;

    case OnboardingErrorCode.PasswordStrengthError:
      return strings.passwordTooWeak;

    default:
      return `${strings.pleaseTryAgainLater}`;
  }
};
