import { ApiConfig } from 'clientportalshared';
import { DynamicAppConfig, StaticAppConfig, FusionChartsOptions } from '../types';

const appConfig: StaticAppConfig & DynamicAppConfig & ApiConfig & FusionChartsOptions = {
  devUseWo2MockData: process.env.REACT_APP_DEV_USE_WO2_MOCK_DATA === 'true',
  shouldReturnMockData: process.env.REACT_APP_DEV_USE_WO2_MOCK_DATA === 'true',
  sentryDsn: process.env.REACT_APP_SENTRY_DNS || '',
  fusionChartsLicense: { key: process.env.REACT_APP_FC_LICENCE_KEY || '', creditLabel: false },
  apiEndpointUrl: process.env.REACT_APP_BACKEND_API_HOST || '',
  apiClientportalEndpointUrl: process.env.REACT_APP_BACKEND_CLIENTPORTAL_API_HOST || '',
  appMoneysoftSource: process.env.REACT_APP_MONEYSOFT_SOURCE || '',
  apiMoneysoftUrl: process.env.REACT_APP_MONEYSOFT_HOST || '',
  auth0Domain: process.env.REACT_APP_AUTHO_DOMAIN || '',
  auth0ClientId: process.env.REACT_APP_AUTHO_CLIENT_ID || '',
  auth0Audience: process.env.REACT_APP_AUTHO_AUDIENCE || '',
  auth0DomainMdaTenant: '',
  auth0ClientIdMdaTenant: '',
  logLevelToSend: '',
  minimumAppVersion: '',
  defaultTimeoutInMs: 0,
  defaultSkip: 0,
  defaultTake: 100,
};

export const userOnboardingBaseUrl = process.env.REACT_APP_USER_ONBOARDING_BASE_URL || '';

export default appConfig;
