import 'reflect-metadata';
import { Container } from 'inversify';
import { ICookieStorageService$ } from '@services/storage/cookie/ICookieStorage.interface';
import CookieStorageService from '@services/storage/cookie/CookieStorage.service';
import { ILocalStorageService$ } from '@services/storage/local/ILocalStorage.interface';
import LocalStorageService from '@services/storage/local/LocalStorage.service';
import { ISessionStorageService$ } from '@services/storage/session/ISessionStorage.interface';
import UniversalSessionStorageService from '@services/storage/session/SessionStorage.service';
import { IAuthenticationService$ } from '@services/auth/IAuthentication.interface';
import JWTAuthenticationService from '@services/auth/JWTAuthentication.service';
import { ICommunicationService$ } from '@services/communication/ICommunication.interface';
import AxiosCommunicationService from '@services/communication/AxiosCommunication.service';
import ConsoleLogTransport from '@services/logging/ConsoleLog.transport';
import { ILoggingService$ } from '@services/logging/ILoggingService.interface';
import { ILogTransport$ } from '@services/logging/ILogTransport.interface';
import LoggingService from '@services/logging/Logging.service';
import SentryLogTransport from '@services/logging/SentryLog.transport';

const container = new Container();

if (
  process.env.REACT_APP_ENV_NAME === 'local' ||
  process.env.REACT_APP_ENV_NAME === 'development'
) {
  container.bind(ILogTransport$).to(ConsoleLogTransport).inSingletonScope();
} else {
  container.bind(ILogTransport$).to(SentryLogTransport).inSingletonScope();
}

container.bind(ILoggingService$).to(LoggingService).inSingletonScope();
container.bind(ICookieStorageService$).to(CookieStorageService).inSingletonScope();
container.bind(ILocalStorageService$).to(LocalStorageService).inSingletonScope();
container.bind(ISessionStorageService$).to(UniversalSessionStorageService).inSingletonScope();

container.bind(IAuthenticationService$).to(JWTAuthenticationService).inSingletonScope();
container.bind(ICommunicationService$).to(AxiosCommunicationService).inSingletonScope();

export default container;
