import appConfig, { userOnboardingBaseUrl } from '@config/appConfig';
import urlParamKeys from '@constants/urlParamKeys';

const baseUrl = appConfig.apiEndpointUrl;

// Web links
export const rootUrl = (): string => `/`;
export const homeUrl = (): string => `/dashboard`;
export const loginUrl = (): string => `/login-callback`;
export const logoutUrl = (): string => `/logout-callback`;
export const loginCallbackUrl = (): string => `${window.location.origin}${loginUrl()}`;
export const logoutCallbackUrl = (): string => `${window.location.origin}${logoutUrl()}`;
export const portfolioDetailsUrl = (portfolioId: string): string => `/portfolio/${portfolioId}`;
export const adviserUrl = (): string => `/adviser`;
export const documentsUrl = (): string => `/documents`;
export const beneficiariesUrl = (): string => `/beneficiaries`;
export const insuranceUrl = (): string => `/insurance`;
export const pensionUrl = (): string => `/pension`;
export const benefitStatementUrl = (): string => `/benefitstatement`;
export const contributionsUrl = (): string => `/contributions`;
export const profileUrl = (): string => `/profile`;
export const moneySoftUrl = (): string => '/moneysoft';
export const contactUsUrl = (): string => {
  return 'https://dash.com.au/company/contact-us';
};
export const allHoldingsUrl = (id: string, dateAsOf?: string): string => {
  if (dateAsOf) {
    // eslint-disable-next-line prefer-template
    const dateAsOfNoTime = dateAsOf.split('T')[0] + 'T00:00:00Z';
    return `/portfolio/${id}/holdings?${urlParamKeys.dateAsOf}=${dateAsOfNoTime}`;
  }
  return `/portfolio/${id}/holdings`;
};
export const selectedHoldingsUrl = (id: string, holdingsKey: string, dateAsOf: string): string =>
  `/portfolio/${id}/holdings/?${urlParamKeys.assetClassKey}=${holdingsKey}&${urlParamKeys.dateAsOf}=${dateAsOf}`;
export const holdingsTransactionsUrl = (
  id: string,
  securityCode: string,
  dateAsOf?: string
): string => {
  if (dateAsOf)
    return `/portfolio/${id}/holdings/${securityCode}/transactions?${urlParamKeys.dateAsOf}=${dateAsOf}`;
  return `/portfolio/${id}/holdings/${securityCode}/transactions`;
};
export const allReportsUrl = (id: string): string => `/portfolio/${id}/reports`;
export const allTransactionsUrl = (id: string): string => `/portfolio/${id}/transactions`;
export const onboardingUrl = (): string => `/onboarding`;
export const onboardingDoneUrl = (): string => `/onboarding/account-created`;

// API urls
export const fileDownloadUrl = (attachmentUrl: string): string => {
  return `${baseUrl.split('/api/v1')[0]}${attachmentUrl}`;
};

export const wealthO2HomePageUrl = (): string => {
  return 'https://dash.com.au';
};

export const wealthO2TermsAndConditionsUrl = (): string => {
  return 'https://support.dash.com.au/hc/en-au/articles/4402937250841-Client-Portal-Terms-and-Conditions';
};

export const wealthO2PrivacyPolicyUrl = (): string => {
  return 'https://dash.com.au/privacy-policy';
};

export const environmentUrl = (envUrl: string): string => {
  return envUrl.split('/api/v1')[0];
};

export const playStoreUrl = (): string => {
  return 'https://play.google.com/store/apps/details?id=au.com.wealtho2.clientportal';
};

export const appStoreUrl = (): string => {
  return 'https://apps.apple.com/us/app/wealtho2-client-portal/id1569628726';
};

export const contactsForAdviserUrl = (): string => {
  return `/Adviser/me/Contacts`;
};

// Auth0 urls
export const changePasswordUrl = (): string => {
  return `https://${appConfig.auth0Domain}/dbconnections/change_password`;
};

export const userOnboardingUrl = (): string => {
  return `${userOnboardingBaseUrl}/userspublic/ActivateClientPortalAccessForUser`;
};
