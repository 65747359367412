import { FC } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import strings from '@strings/LocalisedStrings';
import styles from '../errorFallbackComponent/index.module.scss';

interface UnableToLoadDataProps {
  heading?: string;
  subHeading?: string;
  onClick?: () => void;
}

const UnableToLoadData: FC<UnableToLoadDataProps> = ({
  heading,
  subHeading,
  onClick,
}): JSX.Element => {
  return (
    <Container className={styles.errorBoundary}>
      <Grid container>
        <Grid item xs={0} md={3} />
        <Grid item xs={12} md={6}>
          <div className={styles.infoContainer}>
            <Typography variant="h5">{heading}</Typography>
            <Typography variant="subtitle2">{subHeading}</Typography>
            {onClick && (
              <Button variant="contained" onClick={onClick}>
                {strings.retry}
              </Button>
            )}
          </div>
        </Grid>
        <Grid item xs={0} md={3} />
      </Grid>
    </Container>
  );
};

UnableToLoadData.defaultProps = {
  heading: '',
  subHeading:
    'We are unable to load your data at this time. We are working to restore services and apologise for the inconvenience. If you require immediate assistance with your portfolio, please contact your financial adviser.',
  onClick: undefined,
};

export default UnableToLoadData;
