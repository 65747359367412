export const dashboardApiThunkPayload = 'dashboard/fetch';
export const portfolioDetailApiThunkPayload = 'portfolioDetails/fetch';
export const portfolioDetailPerformanceApiThunkPayload = 'portfolioDetailsPerformance/fetch';
export const portfolioDetailAssetsApiThunkPayload = 'portfolioDetails/fetchAssets';
export const transactionsApiThunkPayload = 'transactions/fetch';
export const reportsApiThunkPayload = 'reports/fetch';
export const onboardingApiThunkPayload = 'onboarding/post';
export const holdingsApiThunkPayload = 'holdings/fetch';
export const portfolioDocumentsThunkPayload = 'portfolioDetails/fetchDocuments';
export const moneysoftDetailApiThunkPayload = 'moneysoftDetails/fetch';
export const moneysoftInviteClientApiThunkPayload = 'moneysoftInviteClient/fetch';
export const moneysoftSingleSignOnTokenApiThunkPayload = 'moneysoftSingleSignOnToken/fetch';
export const moneysoftAsAtDateParameterThunkPayload = 'moneysoftSetDate/set';
export const supersimplifierAccountsApiThunkPayload = 'supersimplifierAccounts/fetch';
export const supersimplifierAccountApiThunkPayload = 'supersimplifierAccount/fetch';
export const supersimplifierContributionsApiThunkPayload = 'supersimplifierContributions/fetch';
export const supersimplifierDocumentsApiThunkPayload = 'supersimplifierDocuments/fetch';
