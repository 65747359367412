import React, { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import buttonBackArrowImg from '@icons/button_back_arrow.svg';
import closeImg from '@icons/close.svg';
import { useAppDispatch, useAppSelector } from '@hooks/store';
import {
  dateFormatter,
  getReturnColor,
  percentageFormatter,
  getLocalDateTime,
  DateTimeFormat,
  jsDateOnly,
  SystemMinimumDate,
} from '@src/app/utils/GenericUtils';
import strings from '@strings/LocalisedStrings';
import colors from '@theme/Colors';
import { PerformanceChartData } from '@src/app/types';
import { DateTimeFormats, Screen, ScreenState } from '@src/app/enums';
import { selectPortfolioDetails } from '@store/reducers/portfolioDetails';
import { getBenchmarkPerformanceDataAsync } from '@store/reducers/portfolioDetails/thunks';
import { AreaTimeSeriesChart, CustomSkeleton } from '@components/index';
import styles from './PerformanceChart.module.scss';
import DateRangePicker from '@components/DateRangePicker';
import { DateRange } from '../../store';
import moment from 'moment';

const PerformanceChart: FC = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const { benchmarkSelected, benchmarkPerformance, data, benchmarkPerformanceState } =
    useAppSelector(selectPortfolioDetails);
  const { benchmarks } = data;
  const performanceData = data.performance!.data!;

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [chartData, setChartData] = useState<PerformanceChartData[]>([]);
  const [popoverWidth, setPopoverWidth] = useState(0);
  const [error, setError] = useState('');
  const [totalReturn, setTotalReturn] = useState(percentageFormatter(data.totalReturnPercentage!));

  const [dateParameter, setDateParameter] = useState({
    dateFrom: DateTime.fromISO(SystemMinimumDate).toISODate(),
    dateTo: DateTime.now().toISODate(),
  });

  const updateReportDates = (dates: DateRange) => {
    setDateParameter(dates);
    applyDateRange(dates);
  };

  const inceptionDateForEndpoint = data.inceptionDate ? data.inceptionDate : new Date('2000-01-01');

  const inceptionDate = DateTime.fromJSDate(jsDateOnly(new Date(inceptionDateForEndpoint)))
    .toLocal()
    .toISO();

  const dispatch = useAppDispatch();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    setPopoverWidth(event.currentTarget.offsetWidth);
  };

  const handleClose = (
    event: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const noBenchmarkApplicable = 'No Benchmark Applicable ';

  const dateForWealtho2PublicapiClient = (date: string) => {
    const newDate = moment(date).subtract(1, 'days').format('YYYY-MM-DD') + 'T13';
    return new Date(moment.utc(newDate).format());
  };

  const handleIndexSelection = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ): void => {
    event.stopPropagation();
    if (value === 'none') {
      dispatch(
        getBenchmarkPerformanceDataAsync({
          benchmarkKey: undefined,
          benchmarkSelected: null,
          portfolioKey: id,
          fromDate: dateForWealtho2PublicapiClient(dateParameter.dateFrom),
          toDate: dateForWealtho2PublicapiClient(dateParameter.dateTo),
        })
      );
    } else {
      const benchmarkSelection = benchmarks!.filter((bmdatum) => bmdatum.code! === value)[0];
      dispatch(
        getBenchmarkPerformanceDataAsync({
          benchmarkKey: benchmarkSelection.benchmarkKey,
          benchmarkSelected: benchmarkSelection,
          portfolioKey: id,
          fromDate: dateForWealtho2PublicapiClient(dateParameter.dateFrom),
          toDate: dateForWealtho2PublicapiClient(dateParameter.dateTo),
        })
      );
    }
    setAnchorEl(null);
  };

  const totalReturnCardHeader = `Total Return from ${getLocalDateTime(
    dateParameter.dateFrom,
    DateTimeFormat.DailyDate
  )} to ${getLocalDateTime(dateParameter.dateTo, DateTimeFormat.DailyDate)}`;

  const applyDateRange = (dates: DateRange) => {
    dispatch(
      getBenchmarkPerformanceDataAsync({
        benchmarkKey: undefined,
        benchmarkSelected: null,
        portfolioKey: id,
        fromDate: dateForWealtho2PublicapiClient(dates.dateFrom),
        toDate: dateForWealtho2PublicapiClient(dates.dateTo),
      })
    )
      .unwrap()
      .then((response) => {
        const tempChartData: PerformanceChartData[] = [];
        response.benchmarkPerformace?.forEach((item) => {
          tempChartData.push({
            label: dateFormatter(item.date!, false, DateTimeFormats.FcDateFormat),
            value: item.metric1! * 100,
          });
        });
        setError('');
        setChartData(tempChartData);
        if (response.benchmarkPerformace) {
          setTotalReturn(
            percentageFormatter(
              response.benchmarkPerformace[response.benchmarkPerformace.length - 1].metric1 || 0
            )
          );
        }
      })
      .catch(() => {
        setError('Please select a from date that is before to date.');
      });
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simplePopover' : undefined;

  useEffect(() => {
    const tempChartData: PerformanceChartData[] = [];
    performanceData.forEach((item) =>
      tempChartData.push({
        label: dateFormatter(item.date!, false, DateTimeFormats.FcDateFormat),
        value: item.metric1! * 100,
      })
    );
    setChartData(tempChartData);
  }, [performanceData]);

  const lastBenchmark = benchmarkPerformance
    ? benchmarkPerformance.filter((item) => item.metric2 !== 0).slice(-1)[0]?.metric2
    : null;

  if (benchmarkPerformanceState === ScreenState.Loading)
    <Stack direction="column" spacing={2} sx={{ mt: 5 }}>
      <Stack direction="row" spacing={2} width="50%">
        <CustomSkeleton variant="rectangular" height={100} width="50%" numberOfItems={2} />
      </Stack>
      <Stack spacing={1}>
        <CustomSkeleton variant="rectangular" height={450} numberOfItems={1} />
      </Stack>
    </Stack>;

  return (
    <Box style={{ marginLeft: '25px', paddingTop: '25px' }}>
      <Grid container spacing={2}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DateRangePicker
            dateRange={dateParameter}
            setDateRange={updateReportDates}
            inceptionDate={inceptionDate}
          />
        </LocalizationProvider>
        <Typography
          variant="body1"
          sx={{ color: 'orange', paddingLeft: '10px', paddingTop: '15px' }}
        >
          {error}
        </Typography>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          mt: 3,
          mb: 3,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box className={`${styles.performanceCard} ${styles.noCursor}`}>
              <Typography variant="subtitle2" mb="0.86em">
                {totalReturnCardHeader}
              </Typography>
              <Typography variant="h5" fontWeight={900} color={getReturnColor(0)}>
                {totalReturn}
              </Typography>
            </Box>
          </Grid>
          {!(benchmarks?.length === 1 && benchmarks[0].code === noBenchmarkApplicable) && (
            <Grid item xs={12} md={4}>
              <Box
                className={styles.performanceCard}
                aria-describedby={popoverId}
                onClick={handleClick}
              >
                <Grid container justifyContent="space-between" alignItems="center" mb="0.75em">
                  <Tooltip
                    title={
                      benchmarkSelected ? benchmarkSelected.name! : strings.compareAgainstBenchmark
                    }
                  >
                    <Typography
                      width="85%"
                      sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                      variant="subtitle2"
                      className="benchmarkHeading"
                    >
                      {benchmarkSelected
                        ? benchmarkSelected.name!
                        : strings.compareAgainstBenchmark}
                    </Typography>
                  </Tooltip>
                  <img className="rotateArrow" src={buttonBackArrowImg} alt={strings.next} />
                </Grid>
                <Typography
                  variant="h5"
                  fontWeight={900}
                  color={colors.navy}
                  className="benchmarkReturn"
                  style={{
                    visibility: benchmarkSelected && benchmarkPerformance ? 'unset' : 'hidden',
                  }}
                >
                  {lastBenchmark ? percentageFormatter(lastBenchmark / 100) : 'null'}
                </Typography>
                <Popover
                  id={popoverId}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  className="indexPopover"
                >
                  <Box
                    style={{
                      padding: '1.5rem',
                      minWidth: `${popoverWidth}px`,
                      maxWidth: `${2 * popoverWidth}px`,
                    }}
                  >
                    <Grid container justifyContent="space-between" alignItems="center" mb="0.75em">
                      <Typography variant="subtitle2" sx={{ textTransform: 'uppercase' }}>
                        {strings.chooseBenchmark}
                      </Typography>
                      <IconButton onClick={handleClose}>
                        <img className="rotateArrow" src={closeImg} alt={strings.next} />
                      </IconButton>
                    </Grid>
                    <Typography className="benchmarkSubHeading">
                      {strings.chooseBenchmarkDescription}
                    </Typography>
                    <hr />
                    <RadioGroup
                      onChange={handleIndexSelection}
                      aria-label="gender"
                      value={benchmarkSelected?.code || 'none'}
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        labelPlacement="start"
                        value="none"
                        control={<Radio />}
                        label={strings.none}
                      />
                      {benchmarks &&
                        benchmarks.length > 0 &&
                        benchmarks
                          .filter((benchmark) => benchmark.code !== noBenchmarkApplicable)
                          .map((benchmarkDatum) => (
                            <FormControlLabel
                              key={benchmarkDatum.benchmarkKey}
                              labelPlacement="start"
                              value={benchmarkDatum.code}
                              control={<Radio />}
                              label={benchmarkDatum.name!}
                            />
                          ))}
                    </RadioGroup>
                  </Box>
                </Popover>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <AreaTimeSeriesChart
        screen={Screen.PortfolioDetail}
        chartData={chartData}
        benchmarkPerformanceData={benchmarkPerformance}
      />
    </Box>
  );
};

export default PerformanceChart;
