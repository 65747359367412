import container from '@ioc';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PortfoliosServiceClient, Transaction } from 'clientportalshared';
import {
  IAuthenticationService,
  IAuthenticationService$,
} from '@services/auth/IAuthentication.interface';
import ILoggingService, { ILoggingService$ } from '@services/logging/ILoggingService.interface';
import appConfig from '@config/appConfig';
import { ScreenState } from '@enums/index';
import { transactionsApiThunkPayload } from '../../constants';
import { RootState } from '../..';

export interface TransactionsStore {
  state: ScreenState;
  error?: string;
  data: Transaction[];
}

const initialState: TransactionsStore = {} as TransactionsStore;

export const getTransactionsAsync = createAsyncThunk<
  Transaction[],
  { portfolioKey: string; securityCode: string | null }
>(transactionsApiThunkPayload, async ({ portfolioKey, securityCode }) => {
  const authService = container.get<IAuthenticationService>(IAuthenticationService$);
  const loggingService = container.get<ILoggingService>(ILoggingService$);

  const portfoliosClient = new PortfoliosServiceClient(
    appConfig.apiEndpointUrl,
    authService,
    loggingService,
    appConfig
  );
  const result = await portfoliosClient.getAllPortfolioTransactionsAsync(
    portfolioKey,
    securityCode,
    undefined,
    undefined,
    true
  );
  return result;
});

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getTransactionsAsync.fulfilled, (prevState, action) => {
        return {
          ...prevState,
          state: ScreenState.SuccessDataLoaded,
          data: action.payload,
        };
      })
      .addCase(getTransactionsAsync.pending, (prevState) => {
        return { ...prevState, state: ScreenState.Loading };
      })
      .addCase(getTransactionsAsync.rejected, (prevState, action) => {
        return {
          ...prevState,
          state: ScreenState.ErrorUnableToLoadData,
          error: action.error.message,
        };
      });
  },
});

export const selectTransactions = (state: RootState): TransactionsStore => state.transactions;
export default transactionsSlice.reducer;
