export default abstract class BaseError extends Error {
  handled: boolean;

  constructor(description: string, handled: boolean) {
    super(description);

    Object.setPrototypeOf(this, new.target.prototype);
    this.handled = handled;
    Error.captureStackTrace(this);
  }
}
