import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FallbackProps } from 'react-error-boundary';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import strings from '@strings/LocalisedStrings';
import Header from '@shell/header';
import Footer from '@shell/footer';
import styles from './index.module.scss';

type Props = {
  customError?: string;
};
const ErrorFallbackComponent: FC<FallbackProps & Props> = ({
  resetErrorBoundary,
  customError,
}): JSX.Element => {
  const history = useHistory();

  useEffect(() => {
    // listen for any route change, on route change remove the error boundary
    history.listen(() => resetErrorBoundary());
  }, []);

  return (
    <div className="app">
      <Header />
      <main>
        <Container className={styles.errorBoundary}>
          <div className={styles.infoContainer}>
            {customError ? (
              <div
                className={styles.customNotification}
                dangerouslySetInnerHTML={{ __html: customError }}
              />
            ) : (
              <Grid container>
                <Grid item xs={0} md={3} />
                <Grid item xs={12} md={6}>
                  <div className={styles.infoContainer}>
                    <Typography variant="subtitle2">
                      We are unable to load your data at this time. We are working to restore
                      services and apologise for the inconvenience. If you require immediate
                      assistance with your portfolio, please contact your financial adviser.
                    </Typography>
                    <Button variant="contained" onClick={resetErrorBoundary}>
                      {strings.retry}
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={0} md={3} />
              </Grid>
            )}
          </div>
        </Container>
      </main>
      <Footer />
    </div>
  );
};

export default ErrorFallbackComponent;
