import { FC, useState } from 'react';
import container from '@ioc';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import {
  ICommunicationService,
  ICommunicationService$,
} from '@services/communication/ICommunication.interface';
import { dateFormatter } from '@utils/GenericUtils';
import { Download, Warning } from '@mui/icons-material';
import strings from '@strings/LocalisedStrings';
import colors from '@theme/Colors';
import styles from './ReportCard.module.scss';

interface ReportCardProps {
  heading: string;
  date: Date;
  attachmentUrl: string;
}

const ReportCard: FC<ReportCardProps> = ({ date, heading, attachmentUrl }): JSX.Element => {
  const communicationService = container.get<ICommunicationService>(ICommunicationService$);

  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadError, setDownloadError] = useState(false);

  const downloadPdf = (filename: string) => {
    setIsDownloading(true);
    communicationService
      .downloadPdfAsync(attachmentUrl, filename)
      .catch(() => {
        setDownloadError(true);
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  return (
    <Card className={styles.reportCard} onClick={() => downloadPdf(heading)}>
      <Typography variant="subtitle2" fontWeight={700} lineHeight="1.142em">
        {heading}
      </Typography>
      <div className={styles.bottomContainer}>
        <Typography variant="body2" color={colors.gray300}>
          {strings.added} {dateFormatter(date)}
        </Typography>
        {isDownloading && <div className="spinningLoader" />}
        {downloadError && <Warning style={{ color: 'red' }} />}
        {!isDownloading && !downloadError && <Download />}
      </div>
    </Card>
  );
};

export default ReportCard;
