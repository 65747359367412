import { CSSProperties, FC } from 'react';
import styles from './IconHeading.module.scss';

interface IconHeadingProps {
  title: string;
  icon: string;
  subTitle?: string | null;
  className?: string;
  style?: CSSProperties;
}

const IconHeading: FC<IconHeadingProps> = ({
  icon,
  title,
  subTitle = null,
  className = '',
  style,
}): JSX.Element => (
  <div className={`${styles.iconHeading} ${className}`} style={style}>
    <img src={icon} alt="icon" />
    <div>
      <h2>{title}</h2>
      {subTitle && <p>{subTitle}</p>}
    </div>
  </div>
);

IconHeading.defaultProps = {
  subTitle: null,
  className: '',
  style: undefined,
};

export default IconHeading;
