/* eslint-disable import/prefer-default-export */
import { DeviceOS } from '@enums/index';

export const getMobileOS = (): DeviceOS => {
  const ua = navigator.userAgent;
  if (/android/i.test(ua)) {
    return DeviceOS.Android;
  }
  if (
    /iPad|iPhone|iPod/.test(ua) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  ) {
    return DeviceOS.iOS;
  }
  return DeviceOS.other;
};
