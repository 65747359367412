// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
import { /*React,*/ VFC } from 'react';
import './styles.scss';
import strings from '@strings/LocalisedStrings';

type Props = {
  // onClick: () => void;
};

// todo make banner component more re-usable/generic by specifying content as process env variables
const NotificationBanner: VFC<Props> = (/*{ onClick }: Props*/) => (
  <div className="notificationBanner">
    <div
      style={{ paddingLeft: '20px' }}
      dangerouslySetInnerHTML={{
        __html: strings.dashMaintenanceBannerPreWarningHeader,
        // +
        // strings.dashMaintenanceBannerPreWarningSubheader,
      }}
    />
    <div>
      {/* <IconButton
        size="large"
        aria-label="close notification banner"
        onClick={onClick}
        color="inherit"
      >
        <CloseIcon />
      </IconButton> */}
    </div>
  </div>
);

export default NotificationBanner;
