import { FC, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import MUIDataTable, { MUIDataTableProps, MUIDataTableOptions } from 'mui-datatables';
import getTheme from '@theme/muiDatatableTheme';
import DownloadIcon from './DownloadIcon';

interface DatatableProps extends Omit<MUIDataTableProps, 'title'> {
  clickableRows?: boolean;
}

const Datatable: FC<DatatableProps> = ({
  data,
  columns,
  components,
  options,
  clickableRows = false,
}): JSX.Element => {
  const [componentDidMount, setComponentDidMount] = useState(true);

  const baseOptions: MUIDataTableOptions & Record<string, unknown> = {
    filterType: 'dropdown',
    selectableRows: 'none',
    print: false,
    viewColumns: false,
    responsive: 'vertical',
    searchAlwaysOpen: true,
    searchProps: {
      onFocus: (e) => {
        if (componentDidMount) {
          // remove focus from the search field when datatable is initially loaded
          e.currentTarget.blur();
          setComponentDidMount(false);
        }
      },
    },
  };

  const baseComponents = {
    icons: {
      DownloadIcon,
    },
  };

  const finalOptions = { ...baseOptions, ...options };
  const finalComponents = { ...baseComponents, ...components };

  return (
    <ThemeProvider theme={getTheme(clickableRows)}>
      <MUIDataTable
        title=""
        data={data}
        columns={columns}
        options={finalOptions}
        components={finalComponents}
      />
    </ThemeProvider>
  );
};

Datatable.defaultProps = {
  clickableRows: false,
};

export default Datatable;
