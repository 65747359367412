enum Routes {
  Login = 'login',
  Profile = 'profile',
  Documents = 'documents',
  Beneficiaries = 'beneficiaries',
  Insurance = 'insurance',
  Pension = 'pension',
  BenefitStatement = 'benefitstatement',
  Contributions = 'contributions',
  Logout = 'logout',
  Home = 'home',
  Adviser = 'adviser',
}

export default Routes;
