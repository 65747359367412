/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import Skeleton, { SkeletonProps } from '@mui/material/Skeleton';

interface CustomSkeletonProps extends SkeletonProps {
  numberOfItems?: number;
}

const CustomSkeleton: FC<CustomSkeletonProps> = ({ numberOfItems = 1, ...props }) => {
  const tempArray = new Array(numberOfItems).fill(0);

  return (
    <>
      {tempArray.map((_item, index) => (
        <Skeleton key={index} {...props} />
      ))}
    </>
  );
};

CustomSkeleton.defaultProps = {
  numberOfItems: 1,
};

export default CustomSkeleton;
