/* eslint-disable react/require-default-props */
import { FC } from 'react';
import { Holding } from 'clientportalshared';
import Grid from '@mui/material/Grid';
import strings from '@strings/LocalisedStrings';
import { dateFormatter, percentageFormatter, numberFormatterHoldings } from '@utils/GenericUtils';
import styles from './index.module.scss';

interface SecurityDetailsProps {
  holdingsDate: string;
  security: Holding;
}

interface SecurityInfoColumnProps {
  title: string;
  desc?: string | number;
  firstColumn?: boolean;
}

const SecurityInfoColumn: FC<SecurityInfoColumnProps> = ({
  title,
  desc,
  firstColumn = false,
}): JSX.Element => (
  <div className={`${styles.securityInfoColumn} ${firstColumn ? styles.firstColumn : ''}`}>
    <div className={styles.title}>{title}</div>
    {desc && <div className={styles.desc}>{desc}</div>}
  </div>
);

const SecurityDetails: FC<SecurityDetailsProps> = ({ holdingsDate, security }): JSX.Element => (
  <div>
    <div className={styles.securityName}>{security.securityName}</div>
    <div className={styles.securityCode}>{security.securityCode}</div>
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
      style={{ paddingTop: '20px' }}
    >
      <Grid item xs={12}>
        <SecurityInfoColumn
          title={`${strings.detailsAsOf} ${dateFormatter(holdingsDate)}`}
          firstColumn
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <SecurityInfoColumn
          title={strings.units}
          desc={numberFormatterHoldings(security.units, 4)}
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <SecurityInfoColumn
          title={strings.price}
          desc={`$${numberFormatterHoldings(security.price, 4)}`}
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <SecurityInfoColumn
          title={strings.marketValue}
          desc={`$${numberFormatterHoldings(security.value, 2)}`}
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <SecurityInfoColumn
          title={strings.weight}
          desc={percentageFormatter(security.percentageOfPortfolio!)}
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <SecurityInfoColumn
          title={strings.cost}
          desc={`$${numberFormatterHoldings(security.costBase, 2)}`}
        />
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
        <SecurityInfoColumn
          title={strings.estLossGain}
          desc={`$${numberFormatterHoldings(security.estimatedGainOrLoss, 2)}`}
        />
      </Grid>
    </Grid>
    <div className={styles.allTransactions}>{strings.allTransactions}</div>
  </div>
);

export default SecurityDetails;
