import { FC } from 'react';
import ReactFC from 'react-fusioncharts';
import FusionCharts, { ChartObject } from 'fusioncharts';
import Doughnut2D from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { AssetChartData } from '@src/app/types';

interface DoughnutChartProps {
  chartData: AssetChartData[];
}

ReactFC.fcRoot(FusionCharts, Doughnut2D, FusionTheme);

const DoughnutChart: FC<DoughnutChartProps> = ({ chartData }): JSX.Element => {
  const chartConfig: ChartObject = {
    type: 'doughnut2d',
    width: '360',
    height: '345',
    dataFormat: 'json',
    dataSource: {
      chart: {
        theme: 'fusion',
        pieRadius: '160',
        startingAngle: '90',
        doughnutRadius: '105',
        showpercentvalues: '1',
        animation: true,
        animateClockwise: true,
        radius3D: '5',
        smartLineThickness: '0',
        labelFontSize: '0',
        showLegend: false,
        labelFontColor: '#ffffff',
        bgColor: '#ffffff',
        labelDisplay: '0',
        aligncaptionwithcanvas: '1',
        decimals: '1',
        plottooltext: '<b>$percentValue</b> in <b>$label</b>',
        legendPosition: 'right',
        enableRotation: false,
        enableSlicing: false,
      },
      data: chartData,
    },
  };

  return <ReactFC {...chartConfig} />;
};

export default DoughnutChart;
