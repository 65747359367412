import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../index';

interface LocationStore {
  initialLocation: string;
}

const initialState: LocationStore = {
  initialLocation: '',
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setInitialLocation: (state, action: PayloadAction<string>) => {
      return { ...state, initialLocation: action.payload };
    },
  },
});

export const { setInitialLocation } = locationSlice.actions;
export const selectLocationStore = (state: RootState): LocationStore => state.location;

export default locationSlice.reducer;
