import { FC } from 'react';
import { TransactionSummary } from 'clientportalshared';
import {
  currencyFormatter,
  dateFormatter,
  numberFormatter,
  numberFormatterDollar,
} from '@utils/GenericUtils';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import strings from '@strings/LocalisedStrings';
import styles from './TransactionCard.module.scss';

interface TransactionCardProps {
  transactionItem: TransactionSummary;
}

const TransactionCard: FC<TransactionCardProps> = ({ transactionItem }): JSX.Element => (
  <Box className={styles.container} key={transactionItem.transactionKey}>
    <Grid container spacing={1}>
      <Grid item xs={12} container direction="row" alignItems="center">
        <Typography className={styles.heading} style={{ height: '27px' }}>
          {transactionItem.securityName?.trim()}
        </Typography>
      </Grid>
      <Grid item xs={12} md={1.5} container direction="row" alignItems="center">
        <Tooltip title={transactionItem.securityCode ? transactionItem.securityCode : ''}>
          <Typography className={styles.subheading}>
            {transactionItem.securityCode ? transactionItem.securityCode : ''}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid item xs={12} md={1.5} container direction="row" alignItems="center">
        <Typography className={styles.time}>{dateFormatter(transactionItem.tradeDate!)}</Typography>
      </Grid>
      <Grid item xs={12} md={3} container direction="row" alignItems="center">
        <Tooltip title={transactionItem.description ? transactionItem.description : ''}>
          <Typography className={styles.description}>
            {transactionItem.description ? transactionItem.description : ''}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid item xs={12} md={1.5} container direction="row" alignItems="center">
        <Typography className={styles.paymentInfo}>
          <span className={styles.highlight}>{transactionItem.typeDescription}</span>
        </Typography>
      </Grid>
      <Grid item xs={12} md={1.5} container direction="row" alignItems="center">
        <Typography className={styles.paymentInfo}>
          {`${numberFormatter(transactionItem.units, 2)} @ ${currencyFormatter(
            transactionItem.price,
            2,
            2
          )}`}
        </Typography>
      </Grid>
      <Grid item xs={12} md={1.5} container direction="row" alignItems="center">
        <Typography className={styles.change}>
          {currencyFormatter(transactionItem.fees, 2, 2)} {strings.inFees}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={1.5}
        container
        direction="row"
        alignItems="center"
        justifyContent="end"
      >
        <Typography className={styles.value}>
          {numberFormatterDollar(transactionItem.netTransactionValue)}
        </Typography>
      </Grid>
    </Grid>
  </Box>
);

export default TransactionCard;
