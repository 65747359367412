import container from '@ioc';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HoldingList, PortfoliosServiceClient } from 'clientportalshared';
import {
  IAuthenticationService,
  IAuthenticationService$,
} from '@services/auth/IAuthentication.interface';
import ILoggingService, { ILoggingService$ } from '@services/logging/ILoggingService.interface';
import appConfig from '@config/appConfig';
import { ScreenState } from '@enums/index';
import { holdingsApiThunkPayload } from '../../constants';
import type { RootState } from '../../index';

export interface HoldingsStore {
  state: ScreenState;
  error?: string;
  data: HoldingList;
}

const initialState = {} as HoldingsStore;

export const getHoldingsAsync = createAsyncThunk<
  HoldingList,
  { portfolioKey: string; dateAsOf: string }
>(holdingsApiThunkPayload, async ({ portfolioKey, dateAsOf }) => {
  const authService = container.get<IAuthenticationService>(IAuthenticationService$);
  const loggingService = container.get<ILoggingService>(ILoggingService$);

  const portfoliosClient = new PortfoliosServiceClient(
    appConfig.apiEndpointUrl,
    authService,
    loggingService,
    appConfig
  );
  const result = await portfoliosClient.getPortfolioHoldingsAsync(portfolioKey, new Date(dateAsOf));
  return result;
});

export const HoldingsSlice = createSlice({
  name: 'holdings',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getHoldingsAsync.fulfilled, (prevState, action) => {
        return {
          ...prevState,
          state: ScreenState.SuccessDataLoaded,
          data: action.payload,
        };
      })
      .addCase(getHoldingsAsync.pending, (prevState) => {
        return { ...prevState, state: ScreenState.Loading };
      })
      .addCase(getHoldingsAsync.rejected, (prevState, action) => {
        return {
          ...prevState,
          state: ScreenState.ErrorUnableToLoadData,
          error: action.error.message,
        };
      });
  },
});

export const selectHoldings = (state: RootState): HoldingsStore => state.holdings;
export default HoldingsSlice.reducer;
