import User from '@services/user/User.model';
import { injectable, multiInject } from 'inversify';
import ILoggingService from './ILoggingService.interface';
import ILogTransport, { ILogTransport$ } from './ILogTransport.interface';
import LogEntryModel from './LogEntry.model';

@injectable()
export default class LoggingService implements ILoggingService {
  private providers: ILogTransport[];

  constructor(@multiInject(ILogTransport$) providers: ILogTransport[]) {
    this.providers = providers;
    this.providers.forEach((item) => item.init());
  }

  log(entry: LogEntryModel): void {
    this.providers.forEach((item) => item.log(entry));
  }

  registerUser(user: User): void {
    this.providers.forEach((item) => item.registerUser(user));
  }

  page(title: string, url: string): void {
    this.providers.forEach((item) => item.page(title, url));
  }
}
