import container from '@ioc';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  DocumentSummaryList,
  SuperSimplifierAccountList,
  SupersimplifierAccountsServiceClient,
  SuperSimplifierAccount,
} from 'clientportalshared';
import {
  IAuthenticationService,
  IAuthenticationService$,
} from '@services/auth/IAuthentication.interface';
import ILoggingService, { ILoggingService$ } from '@services/logging/ILoggingService.interface';
import appConfig from '@config/appConfig';
import {
  supersimplifierAccountsApiThunkPayload,
  supersimplifierAccountApiThunkPayload,
  supersimplifierContributionsApiThunkPayload,
  supersimplifierDocumentsApiThunkPayload,
} from '../../constants';

export const getSuperSimplifierAccountsAsync = createAsyncThunk<SuperSimplifierAccountList>(
  supersimplifierAccountsApiThunkPayload,
  async () => {
    const authService = container.get<IAuthenticationService>(IAuthenticationService$);
    const loggingService = container.get<ILoggingService>(ILoggingService$);

    const supersimplifierClient = new SupersimplifierAccountsServiceClient(
      appConfig.apiEndpointUrl,
      authService,
      loggingService,
      appConfig
    );
    const result = await supersimplifierClient.getSuperSimplifierAccountsAsync(0, 100);
    return result;
  }
);

export const getSuperSimplifierAccountAsync = createAsyncThunk<
  SuperSimplifierAccount,
  { accountNumber: string }
>(supersimplifierAccountApiThunkPayload, async ({ accountNumber }) => {
  const authService = container.get<IAuthenticationService>(IAuthenticationService$);
  const loggingService = container.get<ILoggingService>(ILoggingService$);

  const moneysoftClient = new SupersimplifierAccountsServiceClient(
    appConfig.apiEndpointUrl,
    authService,
    loggingService,
    appConfig
  );
  const result = await moneysoftClient.getSuperSimpliferAccountAsync(accountNumber);
  return result;
});

export const getContributionsAsync = createAsyncThunk<
  DocumentSummaryList,
  { accountNumber: string; financialYearEndingYear: number }
>(
  supersimplifierContributionsApiThunkPayload,
  async ({ accountNumber, financialYearEndingYear }) => {
    const authService = container.get<IAuthenticationService>(IAuthenticationService$);
    const loggingService = container.get<ILoggingService>(ILoggingService$);

    const moneysoftClient = new SupersimplifierAccountsServiceClient(
      appConfig.apiEndpointUrl,
      authService,
      loggingService,
      appConfig
    );
    const result = await moneysoftClient.getContributionsAsync(
      accountNumber,
      financialYearEndingYear
    );
    return result;
  }
);

export const getDocumentsAsync = createAsyncThunk<
  DocumentSummaryList,
  { accountNumber: string; skip: number; take: number }
>(supersimplifierDocumentsApiThunkPayload, async ({ accountNumber, skip, take }) => {
  const authService = container.get<IAuthenticationService>(IAuthenticationService$);
  const loggingService = container.get<ILoggingService>(ILoggingService$);

  const moneysoftClient = new SupersimplifierAccountsServiceClient(
    appConfig.apiEndpointUrl,
    authService,
    loggingService,
    appConfig
  );
  const result = await moneysoftClient.getDocumentsAsync(accountNumber, skip, take);
  return result;
});
