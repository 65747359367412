import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Holding } from 'clientportalshared';
import Container from '@mui/material/Container';
import useQuery from '@hooks/useQuery';
import { getHoldingsAsync, selectHoldings } from '@store/reducers/holdings';
import { getTransactionsAsync, selectTransactions } from '@store/reducers/transactions';
import urlParamKeys from '@constants/urlParamKeys';
import {
  BackButton,
  Datatable,
  CustomSkeleton,
  PageNotFound,
  UnableToLoadData,
} from '@components/index';
import strings from '@strings/LocalisedStrings';
import { ScreenState } from '@enums/index';
import { useAppDispatch, useAppSelector } from '@hooks/store';
import getColumns from './datatableColumn';
import SecurityDetails from './SecurityDetails';

const HoldingsTransactions: FC = (): JSX.Element => {
  const query = useQuery();
  const dispatch = useAppDispatch();

  const { id, securityCode } = useParams<{ id: string; securityCode: string }>();
  const { data: holdingsData, state: holdingsState } = useAppSelector(selectHoldings);
  const { data, state } = useAppSelector(selectTransactions);
  const dateAsOf = query.get(urlParamKeys.dateAsOf);

  const [invalidSecurityCode, setInvalidSecurityCode] = useState(false);
  const [selectedSecurity, setSelectedSecurity] = useState<Holding | null>(null);

  const getCurrentSecurity = () => {
    const result = holdingsData.data!.find((item) => item.securityCode! === securityCode);
    if (!result) setInvalidSecurityCode(true);
    else setSelectedSecurity(result);
  };

  const loadDataAsync = () => {
    if (!holdingsState) dispatch(getHoldingsAsync({ portfolioKey: id, dateAsOf: dateAsOf! }));
    if (!state) dispatch(getTransactionsAsync({ portfolioKey: id, securityCode }));
  };

  useEffect(() => {
    if (state === ScreenState.SuccessDataLoaded && holdingsState === ScreenState.SuccessDataLoaded)
      getCurrentSecurity();
    loadDataAsync();
  }, [selectedSecurity, state, holdingsState]);

  useEffect(() => {
    dispatch(getTransactionsAsync({ portfolioKey: id, securityCode }));
  }, [getTransactionsAsync]);

  if (!dateAsOf) return <PageNotFound heading={strings.invalidUrl} />;
  if (invalidSecurityCode)
    return <PageNotFound heading={`${strings.pageNotFound}, ${strings.invalidSecurityCode}`} />;
  if (
    !holdingsState ||
    !state ||
    holdingsState === ScreenState.Loading ||
    state === ScreenState.Loading
  ) {
    return (
      <Container sx={{ mb: 4 }}>
        <BackButton />
        <CustomSkeleton variant="rectangular" height={500} />
      </Container>
    );
  }
  if (
    holdingsState === ScreenState.ErrorUnableToLoadData ||
    state === ScreenState.ErrorUnableToLoadData
  )
    return <UnableToLoadData onClick={loadDataAsync} />;

  return (
    <Container sx={{ mb: 4 }}>
      <BackButton />
      {selectedSecurity && <SecurityDetails holdingsDate={dateAsOf} security={selectedSecurity} />}
      <Datatable data={data} columns={getColumns(data)} />
    </Container>
  );
};

export default HoldingsTransactions;
