import { interfaces } from 'inversify';
import { IAuthenticationService as CommonIAuthenticationService } from 'clientportalshared';
import AuthenticationStateEnum from './AuthenticationState.enum';

export interface IAuthenticationService extends CommonIAuthenticationService {
  init: () => Promise<void>;
  getToken: () => string | null;
  setToken: (jwtToken: string) => void;
  getAuthenticationState: () => AuthenticationStateEnum;
  checkTokenExpiry: () => void;
  logoutAsync: () => void;
  forcedLogout: () => void;
}

const IAuthenticationService$: interfaces.ServiceIdentifier<IAuthenticationService> =
  Symbol('IAuthenticationService');

export { IAuthenticationService$ };
