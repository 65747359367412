import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import container from '@ioc';
import {
  ICommunicationService,
  ICommunicationService$,
} from '@services/communication/ICommunication.interface';
import { useAppDispatch, useAppSelector } from '@hooks/store';
import usePortfolioDetailData from '@hooks/usePortfolioDetailData';
import { getReportsAsync, selectReports } from '@store/reducers/reports';
import Container from '@mui/material/Container';
import {
  IconHeading,
  BackButton,
  Datatable,
  CustomSkeleton,
  UnableToLoadData,
} from '@components/index';
import { ScreenState } from '@enums/index';
import { portfolioDetailsUrl } from '@utils/UrlBuilder';
import strings from '@strings/LocalisedStrings';
import reportImg from '@icons/report.svg';
import getColumns from './datatableColumn';

const Reports: FC = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const { portfolioDetailsStore, loadPortfolioDetailsData } = usePortfolioDetailData();
  const dispatch = useAppDispatch();
  const communicationService = container.get<ICommunicationService>(ICommunicationService$);
  const reportsData = useAppSelector(selectReports);
  const clientAccountKey = portfolioDetailsStore?.data?.clientAccountKey;

  const [isDownloading, setIsDownloading] = useState<{ [key: string]: boolean }>({});

  const loadDataAsync = () => {
    if (!portfolioDetailsStore.state) {
      loadPortfolioDetailsData();
    } else {
      dispatch(getReportsAsync({ clientAccountKey: clientAccountKey! }));
    }
  };

  useEffect(() => {
    loadDataAsync();
  }, [portfolioDetailsStore.state]);

  const downloadPdfAsync = (attachmentUrl: string, documentKey: string, filename: string) => {
    setIsDownloading((prev) => ({ ...prev, [documentKey]: true }));
    communicationService
      .downloadPdfAsync(attachmentUrl, filename)
      .then(() =>
        setIsDownloading((prev) => {
          const newValues = { ...prev };
          delete newValues[documentKey];
          return newValues;
        })
      )
      .catch(() => {
        setIsDownloading((prev) => ({ ...prev, [documentKey]: false }));
      });
  };

  const RenderDatatable: FC = (): JSX.Element => {
    if (reportsData.state === undefined || reportsData.state === ScreenState.Loading)
      return (
        <Container>
          <CustomSkeleton variant="rectangular" height={500} />
        </Container>
      );
    if (
      portfolioDetailsStore.state === ScreenState.ErrorUnableToLoadData ||
      reportsData.state === ScreenState.ErrorUnableToLoadData
    )
      return <UnableToLoadData onClick={loadDataAsync} />;
    return (
      <Datatable
        data={reportsData.data}
        columns={getColumns(downloadPdfAsync, isDownloading)}
        options={{ download: false }}
      />
    );
  };

  return (
    <Container sx={{ mb: 4 }}>
      <BackButton url={portfolioDetailsUrl(id)} />
      <IconHeading
        icon={reportImg}
        title={strings.reportsHeading}
        subTitle={strings.recentReportHoldings}
        style={{ marginBottom: '2rem' }}
      />
      <RenderDatatable />
    </Container>
  );
};

export default Reports;
