import BaseError from './Base.error';

export default class ApiError extends BaseError {
  statusCode: number;

  constructor(description: string, statusCode: number, handled = false) {
    super(description, handled);
    Object.setPrototypeOf(this, new.target.prototype);
    this.statusCode = statusCode;
  }
}
