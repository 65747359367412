import { FC } from 'react';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { IconHeading, CustomSkeleton } from '@components/index';
import { homeUrl } from '@utils/UrlBuilder';
import investmentsImg from '@icons/investments.svg';
import assetImg from '@icons/asset.svg';
import reportImg from '@icons/report.svg';
import dollarImg from '@icons/dollar.svg';
import { ReactComponent as ButtonBackArrowImg } from '@icons/button_back_arrow.svg';
import strings from '@strings/LocalisedStrings';

interface PortfolioDetailsLoaderProps {
  styles: {
    readonly [key: string]: string;
  };
}

const PortfolioDetailsLoader: FC<PortfolioDetailsLoaderProps> = ({ styles }): JSX.Element => {
  return (
    <Container sx={{ mb: 4 }}>
      <Link to={homeUrl()} className={styles.back}>
        <ButtonBackArrowImg />
        <div>{strings.back}</div>
      </Link>
      <Stack spacing={2}>
        <CustomSkeleton variant="text" />
        <CustomSkeleton variant="rectangular" height={150} />
        <hr />
        {/* Performance */}
        <IconHeading icon={investmentsImg} title={strings.performance} />
        <CustomSkeleton variant="rectangular" height={150} />
        <hr />
        {/* Asset Allocation */}
        <IconHeading icon={assetImg} title={strings.assetAllocation} />
        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
          <CustomSkeleton variant="circular" height={300} width={300} />
          <Stack spacing={1} width="50%">
            <CustomSkeleton variant="rectangular" height={50} numberOfItems={5} />
          </Stack>
        </Stack>
        <hr />
        {/* Reports */}
        <IconHeading
          icon={reportImg}
          title={strings.reportsHeading}
          subTitle={strings.recentReportHoldings}
        />
        <Stack spacing={1} direction="row">
          <CustomSkeleton variant="rectangular" height={100} width="100%" numberOfItems={3} />
        </Stack>
        <hr />
        {/* Transactions */}
        <IconHeading
          icon={dollarImg}
          title={strings.transactionsHeading}
          subTitle={strings.recentTransactions}
        />
        <Stack spacing={1}>
          <CustomSkeleton variant="rectangular" height={100} width="100%" numberOfItems={5} />
        </Stack>
      </Stack>
    </Container>
  );
};

export default PortfolioDetailsLoader;
