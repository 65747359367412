import container from '@ioc';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ClientAccountsServiceClient, DocumentSummary, DocumentType } from 'clientportalshared';
import {
  IAuthenticationService,
  IAuthenticationService$,
} from '@services/auth/IAuthentication.interface';
import ILoggingService, { ILoggingService$ } from '@services/logging/ILoggingService.interface';
import appConfig from '@config/appConfig';
import { ScreenState } from '@enums/index';
import { reportsApiThunkPayload } from '../../constants';
import { RootState } from '../..';

export interface ReportsStore {
  state: ScreenState;
  error?: string;
  data: DocumentSummary[];
}

const initialState: ReportsStore = {} as ReportsStore;

export const getReportsAsync = createAsyncThunk<DocumentSummary[], { clientAccountKey: string }>(
  reportsApiThunkPayload,
  async ({ clientAccountKey }) => {
    const authService = container.get<IAuthenticationService>(IAuthenticationService$);
    const loggingService = container.get<ILoggingService>(ILoggingService$);

    const clientAccountsClient = new ClientAccountsServiceClient(
      appConfig.apiEndpointUrl,
      authService,
      loggingService,
      appConfig
    );
    const result = await clientAccountsClient.getAllDocumentsAsync(
      clientAccountKey,
      'Report',
      DocumentType.Report
    );
    return result;
  }
);

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getReportsAsync.fulfilled, (prevState, action) => {
        return {
          ...prevState,
          state: ScreenState.SuccessDataLoaded,
          data: action.payload,
        };
      })
      .addCase(getReportsAsync.pending, (prevState) => {
        return { ...prevState, state: ScreenState.Loading };
      })
      .addCase(getReportsAsync.rejected, (prevState, action) => {
        return {
          ...prevState,
          state: ScreenState.ErrorUnableToLoadData,
          error: action.error.message,
        };
      });
  },
});

export const selectReports = (state: RootState): ReportsStore => state.reports;
export default reportsSlice.reducer;
