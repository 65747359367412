enum Headers {
  Accept = 'Accept',
  Authorization = 'Authorization',
  UserAgent = 'User-Agent',
  ContentType = 'Content-type',
  Host = 'Host',
  Cookie = 'cookie',
}

export default Headers;
