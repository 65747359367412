import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userReducer from './reducers/user';
import authStateReducer from './reducers/auth';
import portfolioDetailsReducer from './reducers/portfolioDetails';
import dashboardReducer from './reducers/dashboard';
import transactionsReducer from './reducers/transactions';
import reportsReducer from './reducers/reports';
import locationReducer from './reducers/location';
import holdingsReducer from './reducers/holdings';
import onboardingReducer from './reducers/onboarding';
import moneysoftReducer from './reducers/moneysoft';
import SupersimplifierReducer from './reducers/supersimplifier';

export type moment = string;

export interface DateRange {
  dateFrom: moment;
  dateTo: moment;
  dateRangeType?: string | null;
}

export const store = configureStore({
  reducer: {
    user: userReducer,
    auth: authStateReducer,
    portfolioDetails: portfolioDetailsReducer,
    dashboard: dashboardReducer,
    transactions: transactionsReducer,
    reports: reportsReducer,
    location: locationReducer,
    holdings: holdingsReducer,
    onboarding: onboardingReducer,
    moneysoft: moneysoftReducer,
    supersimplifier: SupersimplifierReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
