import { injectable } from 'inversify';
import ILocalStorageService from './ILocalStorage.interface';

@injectable()
class LocalStorageService implements ILocalStorageService {
  get = (key: string): string | null => {
    const { localStorage } = window;
    const value = localStorage.getItem(key);
    return value;
  };

  set = (key: string, value: string): void => {
    const { localStorage } = window;
    localStorage.setItem(key, value);
  };

  clear = (key: string): void => {
    const { localStorage } = window;
    localStorage.removeItem(key);
  };

  clearAll = (): void => {
    const { localStorage } = window;
    localStorage.clear();
  };
}

export default LocalStorageService;
