/* eslint-disable no-debugger */
import container from '@ioc';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import moment from 'moment';
import {
  Portfolio,
  BenchmarkInfo,
  AssetClassList,
  PortfoliosServiceClient,
  ClientAccountsServiceClient,
  PerformanceItem,
  DocumentSummaryList,
  DocumentType,
} from 'clientportalshared';
import {
  IAuthenticationService,
  IAuthenticationService$,
} from '@services/auth/IAuthentication.interface';
import ILoggingService, { ILoggingService$ } from '@services/logging/ILoggingService.interface';
import appConfig from '@config/appConfig';
import {
  portfolioDetailApiThunkPayload,
  portfolioDetailAssetsApiThunkPayload,
  portfolioDetailPerformanceApiThunkPayload,
  portfolioDocumentsThunkPayload,
} from '../../constants';

export const getTop6ReportsAsync = createAsyncThunk<
  DocumentSummaryList,
  { clientAccountKey: string }
>(portfolioDocumentsThunkPayload, async ({ clientAccountKey }) => {
  const authService = container.get<IAuthenticationService>(IAuthenticationService$);
  const loggingService = container.get<ILoggingService>(ILoggingService$);

  const accountsClient = new ClientAccountsServiceClient(
    appConfig.apiEndpointUrl,
    authService,
    loggingService,
    appConfig
  );
  const result = await accountsClient.getDocumentsAsync(
    0,
    6,
    clientAccountKey,
    'Report',
    DocumentType.Report
  );
  return result;
});

export const getPortfolioDetailsAsync = createAsyncThunk<Portfolio, { portfolioKey: string }>(
  portfolioDetailApiThunkPayload,
  async ({ portfolioKey }, thunkApi) => {
    const authService = container.get<IAuthenticationService>(IAuthenticationService$);
    const loggingService = container.get<ILoggingService>(ILoggingService$);

    const portfoliosClient = new PortfoliosServiceClient(
      appConfig.apiEndpointUrl,
      authService,
      loggingService,
      appConfig
    );
    const result = await portfoliosClient.getPortfolioAsync(portfolioKey, true, false, false, true);
    if (result.clientAccountKey) {
      thunkApi.dispatch(getTop6ReportsAsync({ clientAccountKey: result.clientAccountKey }));
    }
    return result;
  }
);

export const getAssetAllocationAsOfDateAsync = createAsyncThunk<
  AssetClassList,
  { portfolioKey: string; allocationsAsOfDate: DateTime }
>(portfolioDetailAssetsApiThunkPayload, async ({ portfolioKey, allocationsAsOfDate }) => {
  const authService = container.get<IAuthenticationService>(IAuthenticationService$);
  const loggingService = container.get<ILoggingService>(ILoggingService$);

  const portfoliosClient = new PortfoliosServiceClient(
    appConfig.apiEndpointUrl,
    authService,
    loggingService,
    appConfig
  );
  const result = await portfoliosClient.getPortfolioAssetClassesAsync(
    portfolioKey,
    allocationsAsOfDate.toJSDate()
  );
  return result;
});

interface benchmarkPerformanceThunkReturn {
  benchmarkPerformace: PerformanceItem[] | undefined;
  benchmarkSelected: BenchmarkInfo | null;
}

export enum PerformanceTimePeriod {
  OneMonth = 'OneMonth',
  ThreeMonth = 'ThreeMonth',
  SixMonth = 'SixMonth',
  OneYear = 'OneYear',
  ThreeYear = 'ThreeYear',
  Max = 'Max',
}

export const getBenchmarkPerformanceDataAsync = createAsyncThunk<
  benchmarkPerformanceThunkReturn,
  {
    portfolioKey: string;
    benchmarkKey: string | undefined;
    benchmarkSelected: BenchmarkInfo | null;
    timePeriod?: PerformanceTimePeriod | null;
    fromDate?: Date;
    toDate?: Date;
  }
>(
  portfolioDetailPerformanceApiThunkPayload,
  async ({ portfolioKey, benchmarkKey, benchmarkSelected, timePeriod, fromDate, toDate }) => {
    const authService = container.get<IAuthenticationService>(IAuthenticationService$);
    const loggingService = container.get<ILoggingService>(ILoggingService$);

    const portfoliosClient = new PortfoliosServiceClient(
      appConfig.apiEndpointUrl,
      authService,
      loggingService,
      appConfig
    );

    // this is workaround for the wealtho2-publicapi-client package that changing time -11 hours before making endpoint call
    const toEndpointDate = (d: Date | undefined) => {
      const momentDate = moment(d).add(11, 'h');
      return momentDate.toDate();
    };

    const result = await portfoliosClient.getAllPortfolioPerformanceAsync(
      portfolioKey,
      benchmarkKey,
      timePeriod,
      toEndpointDate(fromDate),
      toEndpointDate(toDate)
    );
    return {
      benchmarkPerformace: result,
      benchmarkSelected,
    };
  }
);
