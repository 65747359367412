const fusionChartPaletteColors = [
  '#3CABDA',
  '#595AD3',
  '#8DF3DE',
  '#157EFB',
  '#53D769',
  '#8DDCFF',
  '#D7D6D6',
  '#FD9426',
  '#8E8E93',
  '#FC469D',
  '#FECB2F',
  '#FC3D39',
];

export default fusionChartPaletteColors;
