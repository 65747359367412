import { FC, useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@hooks/store';
import {
  getSuperSimplifierAccountsAsync,
  getSuperSimplifierAccountAsync,
} from '@store/reducers/supersimplifier/thunks';
import { ScreenState } from '@src/app/enums';
import {
  SupersimplifierStore,
  selectSupersimplifier,
  setSuperSimplifierAccountParameter,
} from '@store/reducers/supersimplifier';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { BackButton } from '@components/index';
import SupersimplifierTable, {
  SupersimplifierTableRow,
  TableHeaders,
} from '@components/superTables/supersimplifierTable';
import { homeUrl } from '@utils/UrlBuilder';
import { Stack, Alert } from '@mui/material';
import { SuperSimplifierAccountSummary } from 'clientportalshared';
import './styles.scss';

const BenefitStatement: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const supersimplifierStore: SupersimplifierStore = useAppSelector(selectSupersimplifier);

  const [selectedAccount, setSelectedAccount] = useState('');

  const getSuperSimplifierAccount = async (
    accountLis: SuperSimplifierAccountSummary[] | undefined
  ) => {
    if (accountLis?.length && accountLis[0].accountNumber) {
      await dispatch(
        getSuperSimplifierAccountAsync({
          accountNumber: accountLis[0].accountNumber,
        })
      );
    }
  };

  const getPageData = () => {
    dispatch(getSuperSimplifierAccountsAsync())
      .unwrap()
      .then((responce) => {
        if (responce?.data?.length && responce.data[0].accountNumber) {
          setSelectedAccount(responce.data[0].accountNumber);
        }
        getSuperSimplifierAccount(responce.data);
      });
  };

  useEffect(() => {
    getPageData();
  }, []);

  const tableHeaders: TableHeaders = { header1: 'DESCRIPTION', header2: 'VALUE' };
  let coverSummaryTable: SupersimplifierTableRow[] = [];
  let taxTable: SupersimplifierTableRow[] = [];
  let preservationTable: SupersimplifierTableRow[] = [];

  if (
    supersimplifierStore.superSimplifierAccount?.benefits &&
    supersimplifierStore.superSimplifierAccount?.insurances
  ) {
    const {
      preservationComponentPreservedAmount,
      preservationComponentRestrictedNonPreservedAmount,
      preservationComponentUnrestrictedNonPreservedAmount,
      taxationComponentEligibleServiceDate,
      taxationComponentTaxFreeComponentAmount,
      taxationComponentTaxableComponentTaxedAmount,
      taxationComponentTaxableComponentUntaxedAmount,
    } = supersimplifierStore.superSimplifierAccount?.benefits;

    const taxTotal =
      (taxationComponentTaxFreeComponentAmount ?? 0) +
      (taxationComponentTaxableComponentUntaxedAmount ?? 0) +
      (taxationComponentTaxableComponentTaxedAmount ?? 0);

    taxTable = [
      { label: 'Eligible Service Date', value: taxationComponentEligibleServiceDate },
      { label: 'Tax Free Component', value: taxationComponentTaxFreeComponentAmount },
      {
        label: 'Taxable Component (Untaxed)',
        value: taxationComponentTaxableComponentUntaxedAmount,
      },
      { label: 'Taxable Component (Taxed)', value: taxationComponentTaxableComponentTaxedAmount },
      { label: 'Total', value: taxTotal },
    ];

    const preservationTotal =
      (preservationComponentPreservedAmount ?? 0) +
      (preservationComponentRestrictedNonPreservedAmount ?? 0) +
      (preservationComponentUnrestrictedNonPreservedAmount ?? 0);

    preservationTable = [
      { label: 'Preserved', value: preservationComponentPreservedAmount },
      {
        label: 'Restricted, non-preserved (RNP)',
        value: preservationComponentRestrictedNonPreservedAmount,
      },
      {
        label: 'Unrestricted, non-preserved (UNP)',
        value: preservationComponentUnrestrictedNonPreservedAmount,
      },
      { label: 'Total', value: preservationTotal },
    ];

    const { deathInsuredAmount, totalPermanentDisabilityInsuredAmount } =
      supersimplifierStore.superSimplifierAccount?.insurances[0];

    coverSummaryTable = [
      {
        label: 'Death Benefit (including any insurance amount)',
        value: (deathInsuredAmount || 0) + taxTotal,
      },
      {
        label: 'Total and Permanent Disablement Benefit (including any insurance amount)',
        value: (totalPermanentDisabilityInsuredAmount || 0) + taxTotal,
      },
    ];
  }

  const handleAccountChange = (event: SelectChangeEvent) => {
    setSelectedAccount(event.target.value);
    dispatch(setSuperSimplifierAccountParameter(event.target.value));
    dispatch(
      getSuperSimplifierAccountAsync({
        accountNumber: event.target.value,
      })
    );
  };

  let errorMessage = null;
  if (supersimplifierStore.superSimplifierAccountState === ScreenState.ErrorUnableToLoadData) {
    errorMessage =
      'There is currently no benefit statement information for this account. If the account has been recently established it may take a few days for this data to be populated. Please contact your adviser or our client service team for assistance.';
    preservationTable = [];
    taxTable = [];
    coverSummaryTable = [];
  } else if (supersimplifierStore.superSimplifierAccountState === ScreenState.Loading) {
    preservationTable = [];
    taxTable = [];
    coverSummaryTable = [];
  }

  return (
    <Container>
      <BackButton url={homeUrl()} />
      <Grid container className="portfolioContent" rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} rowSpacing={{ xs: 2, md: 2 }}>
              <p className="pageHeader">Benefit Statement</p>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} rowSpacing={{ xs: 2, md: 2 }} sx={{ marginTop: '20px' }}>
              <Select
                labelId="selected-account-select-label"
                id="selected-account-select"
                value={selectedAccount}
                defaultValue={selectedAccount}
                label="ACCOUNT"
                onChange={handleAccountChange}
              >
                {supersimplifierStore?.superSimplifierAccounts?.data?.map((i) => (
                  <MenuItem key={i.accountNumber} value={i.accountNumber ?? ''}>{`${
                    i.accountNumber ?? ''
                  } - ${i.name ?? ''}`}</MenuItem>
                ))}
              </Select>
            </Grid>

            {errorMessage && (
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  rowSpacing={{ xs: 2, md: 2 }}
                  sx={{ marginTop: '20px', marginLeft: '17px' }}
                >
                  <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert variant="outlined" severity="info">
                      {errorMessage}
                    </Alert>
                  </Stack>
                </Grid>
              </Grid>
            )}
            {!errorMessage && (
              <>
                <SupersimplifierTable
                  tableHeaders={tableHeaders}
                  data={preservationTable}
                  header="Preservation components"
                />

                <SupersimplifierTable
                  tableHeaders={tableHeaders}
                  data={taxTable}
                  header="Taxation components"
                />

                <SupersimplifierTable
                  tableHeaders={tableHeaders}
                  data={coverSummaryTable}
                  header="Other Benefits"
                />

                <div>
                  <p className="noticeParagraph">
                    The values above are an estimated account balance based on information provided
                    by the Member Administrator for Super Simplifier.
                  </p>

                  <p className="noticeParagraph">The estimated account balance includes:</p>

                  <div style={{ paddingLeft: '25px' }}>
                    <ul className="noticeList">
                      <li className="noticeLi">
                        All contributions that have been received and allocated.{' '}
                      </li>
                      <li className="noticeLi">
                        Fees, charges and taxes that have already been deducted from the member’s
                        account.
                      </li>
                    </ul>
                  </div>

                  <p className="noticeParagraph">
                    Some transactions may not have been added to the estimated account balance.{' '}
                  </p>

                  <p className="noticeParagraph">
                    For example, if the member has made a recent contribution, we may not have
                    received and allocated the payment into their account yet. Also, this account
                    balance may not yet include all deductions for taxes, fees and costs because
                    these are applied at specific times.
                  </p>

                  <p className="noticeParagraph">
                    The value of your investment returns, fees and other costs are confirmed and
                    applied to the member annual statement each year or an exit statement if the
                    member closes their account.
                  </p>
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BenefitStatement;
