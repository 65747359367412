import { FC, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import usePortfolioDetailData from '@hooks/usePortfolioDetailData';
import {
  IconHeading,
  PortfolioDetailsLoader,
  BackButton,
  NoDataContainer,
  UnableToLoadData,
  PageNotFound,
} from '@components/index';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { dateFormatterToLocalIso } from '@utils/GenericUtils';
import investmentsImg from '@icons/investments.svg';
import assetImg from '@icons/asset.svg';
import reportImg from '@icons/report.svg';
import dollarImg from '@icons/dollar.svg';
import strings from '@strings/LocalisedStrings';
import { ScreenState } from '@enums/index';
import { allHoldingsUrl, allReportsUrl, allTransactionsUrl, homeUrl } from '@utils/UrlBuilder';
import TransactionCard from './TransactionCard';
import ReportCard from './ReportCard';
import PerformanceChart from './PerformanceChart';
import InvestmentPortfolio from './InvestmentPortfolio';
import AssetChart from './AssetChart';
import styles from './index.module.scss';
import { Button } from '@mui/material';
import './index.module.scss';

const PortfolioScreen: FC = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const { isLoading, isInvalidPortfolioId, portfolioDetailsStore, loadPortfolioDetailsData } =
    usePortfolioDetailData();
  const data2 = portfolioDetailsStore.data;
  const assetClassesNoneZeroValue = data2?.assetClasses?.filter((item) => item.value);

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isInvalidPortfolioId) {
    return <PageNotFound heading={`${strings.pageNotFound}, ${strings.invalidPortfolioId}`} />;
  }
  if (isLoading) {
    return <PortfolioDetailsLoader styles={styles} />;
  }
  if (portfolioDetailsStore.state === ScreenState.ErrorUnableToLoadData)
    return <UnableToLoadData onClick={() => loadPortfolioDetailsData(true)} />;

  return (
    <Container className={styles.portfolioDetails}>
      <BackButton url={homeUrl()} />
      {/* Investment Portfolio */}
      <section>
        <InvestmentPortfolio
          heading={data2.name!}
          currentValue={data2.currentValue!}
          capitalReturn={data2.capitalReturnValue!}
          capitalReturnPercentage={data2.capitalReturnPercentage!}
          incomeReturn={data2.incomeReturnValue!}
          incomeReturnPercentage={data2.incomeReturnPercentage!}
          totalReturn={data2.totalReturnValue!}
          totalReturnPercentage={data2.totalReturnPercentage!}
        />
      </section>
      <hr />
      {/* Performance */}
      <section>
        <Grid container>
          <Grid
            item
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            <IconHeading icon={investmentsImg} title={strings.performance}></IconHeading>
            <InfoOutlinedIcon
              onClick={() => setModalOpen(true)}
              sx={{ cursor: 'pointer', marginLeft: '10px', marginBottom: '10px' }}
            />
          </Grid>
        </Grid>

        <Dialog
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Your portfolio performance is calculated using a daily compounded Time Weighted Rate
              of Return (TWRR) methodology. The TWRR methodology breaks up the return on an
              investment portfolio into daily intervals. The TWRR provides the rate of return for
              each day. The TWRR is calculated by multiplying each day&apos;s rate of return by each
              other. By isolating daily returns, the result is more accurate than simply taking the
              beginning balance and ending balance of the time invested.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModalOpen(false)} autoFocus className="okButton">
              {strings.ok}
            </Button>
          </DialogActions>
        </Dialog>
        <PerformanceChart />
      </section>
      <hr />
      {/* Asset Allocation */}
      <section>
        <IconHeading icon={assetImg} title={strings.assetAllocation} />
        <AssetChart
          data={assetClassesNoneZeroValue!}
          assetAllocationState={portfolioDetailsStore.assetAllocationState}
          inceptionDate={data2.inceptionDate!}
        />
        <div className={styles.linkContainer}>
          <Link
            to={allHoldingsUrl(
              id,
              dateFormatterToLocalIso(portfolioDetailsStore.assetAllocationAsOfDate)
            )}
            className={styles.link}
          >
            {strings.viewAllHoldings}
          </Link>
        </div>
      </section>
      <hr />
      {/* Reports */}
      <section>
        <IconHeading
          icon={reportImg}
          title={strings.reportsHeading}
          subTitle={strings.recentReportHoldings}
          style={{ marginBottom: '2rem' }}
        />
        {data2?.recentDocuments?.data?.length === 0 ? (
          <NoDataContainer emptyText={strings.noReports} />
        ) : (
          <>
            <Box mb={4} className={styles.reportsContainer}>
              <Grid container spacing={1}>
                {data2.recentDocuments?.data?.map((item) => (
                  <Grid item xs={12} md={4} key={item.documentKey}>
                    <ReportCard
                      key={item.documentKey}
                      heading={item.name!}
                      date={item.createdOnUtc!}
                      attachmentUrl={item.url!}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <div className={styles.linkContainer}>
              <Link to={allReportsUrl(id)} className={styles.link}>
                {strings.viewAllReports}
              </Link>
            </div>
          </>
        )}
      </section>
      <hr />
      {/* Transactions */}
      <section>
        <IconHeading
          icon={dollarImg}
          title={strings.transactionsHeading}
          subTitle={strings.recentTransactions}
          style={{ marginBottom: '2rem' }}
        />
        {data2.recentTransactions!.length === 0 ? (
          <NoDataContainer emptyText={strings.noTransactions} style={{ marginBottom: '1rem' }} />
        ) : (
          <>
            <Box mt={4} mb={4}>
              <Grid container spacing={1}>
                {data2.recentTransactions!.slice(0, 5).map((item) => (
                  <Grid item xs={12} key={item.transactionKey}>
                    <TransactionCard key={item.transactionKey} transactionItem={item} />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <div className={styles.linkContainer}>
              <Link to={allTransactionsUrl(id)} className={styles.link}>
                {strings.viewAllTransactions}
              </Link>
            </div>
          </>
        )}
      </section>
    </Container>
  );
};

export default PortfolioScreen;
