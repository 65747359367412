/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { getColorStyle, numberFormatterDollar } from '@utils/GenericUtils';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import './_moneysoftCard.scss';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from '@mui/material';
import { useAppDispatch } from '@hooks/store';
import { getMoneysoftInviteClientAsync } from '@store/reducers/moneysoft/thunks';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const MoneysoftCard = (Props: {
  name: string | undefined;
  currentBalance: number | undefined;
  hasAccount: boolean | undefined;
  hasMoneysoftAccess: boolean | undefined;
  moneysoftInviteClient: () => void;
  handleNavigationToMoneysoft: () => void;
}): JSX.Element => {
  const Name = Props.name!;

  const [openCard, setOpenCard] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);

  const handleClickOpen = () => {
    setOpenCard(true);
  };

  const handleClose = () => {
    setOpenCard(false);
  };

  const handleCloseSpinner = () => {
    setSpinner(false);
  };
  const handleToggleSpinner = () => {
    setSpinner(!spinner);
  };

  const dispatch = useAppDispatch();

  const handleRegister = async () => {
    handleToggleSpinner();
    setOpenCard(false);
    await dispatch(getMoneysoftInviteClientAsync()).then(() => handleCloseSpinner());
  };

  return (
    <Grid item xs={12} md={6} className="moneysoftCardWrapper">
      {Props.hasMoneysoftAccess && !Props.hasAccount && (
        <Grid>
          <Item className="moneysoftCard">
            <div className="moneysoftCardLeft">
              <h4 className="heading">{Name} - Non Platform Assets</h4>
              <p className={`netMoneysoftReturn ${getColorStyle(1, true)}`}>
                Your adviser has registered you to use Moneysoft.
              </p>
              <p className={`netMoneysoftReturn ${getColorStyle(1, true)}`}>
                Click or tap + button to complete setting up your account.
              </p>
            </div>
            <div className="moneysoftCardRight">
              <AddBoxOutlinedIcon color="info" sx={{ fontSize: 40 }} onClick={handleClickOpen} />
            </div>
          </Item>

          <Dialog
            open={openCard}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" sx={{ color: '#05b28e' }}>
              Register for Moneysoft
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                By accepting your adviser&apos;s invitation and signing up an account, you are agree
                to our{' '}
                <Link
                  target="_blank"
                  sx={{ textDecoration: 'none', fontWeight: 'bold' }}
                  href="https://www.moneysoft.com.au/terms-of-service/"
                >
                  terms of use
                </Link>{' '}
                and{' '}
                <Link
                  target="_blank"
                  sx={{ textDecoration: 'none', fontWeight: 'bold' }}
                  href="https://www.moneysoft.com.au/privacy/"
                >
                  privacy policy
                </Link>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="inherit" id="cancelDialogBox" onClick={handleClose}>
                Cancel
              </Button>
              <Button color="success" id="registerMoneysoft" onClick={handleRegister} autoFocus>
                Register
              </Button>
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={spinner}
                onClick={handleCloseSpinner}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </DialogActions>
          </Dialog>
        </Grid>
      )}
      {Props.hasAccount && Props.hasMoneysoftAccess && (
        <Grid>
          <Item className="moneysoftCard">
            <Button onClick={Props.handleNavigationToMoneysoft} sx={{ textAlign: 'left' }}>
              <div className="moneysoftCardLeft">
                <h4 className="heading">{Name} - Non Platform Assets</h4>
                <h2>{numberFormatterDollar(Props.currentBalance)}</h2>
              </div>
            </Button>
          </Item>
        </Grid>
      )}
    </Grid>
  );
};

export default MoneysoftCard;
