/* eslint-disable no-console */
import User from '@services/user/User.model';
import { injectable } from 'inversify';
import { LogLevel } from '@enums/index';
import { Severity } from '@sentry/types';
import ILogTransport from './ILogTransport.interface';
import LogEntryModel from './LogEntry.model';

@injectable()
export default class ConsoleLogTransport implements ILogTransport {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  init = (): void => {};

  log = (entry: LogEntryModel): void => {
    switch (entry.level) {
      case Severity.fromString(LogLevel.Debug):
        console.debug(entry.message);
        break;
      case Severity.fromString(LogLevel.Info):
        console.info(entry.message, entry.data);
        break;
      case Severity.fromString(LogLevel.Error):
        console.error(entry.message);
        break;
      case Severity.fromString(LogLevel.Fatal):
        console.error(entry.message);
        break;
      default:
        throw new Error('Invalid loglevel');
    }
  };

  registerUser = (user: User): void => {
    console.info(user);
  };

  page = (title: string, url: string): void => {
    const entry = new LogEntryModel('Navigated to a new page', Severity.fromString(LogLevel.Info), {
      title,
      url,
    });
    this.log(entry);
  };
}
