import { injectable } from 'inversify';
import ISessionStorageService from './ISessionStorage.interface';

@injectable()
class UniversalSessionStorageService implements ISessionStorageService {
  get = (key: string): string | null => {
    const { sessionStorage } = window;
    const value: string | null = sessionStorage.getItem(key);
    return value;
  };

  set = (key: string, value: string): void => {
    const { sessionStorage } = window;
    sessionStorage.setItem(key, value);
  };

  clear = (key: string): void => {
    const { sessionStorage } = window;
    sessionStorage.removeItem(key);
  };

  clearAll = (): void => {
    const { sessionStorage } = window;
    sessionStorage.clear();
  };
}

export default UniversalSessionStorageService;
