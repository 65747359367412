import { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Redirect } from 'react-router-dom';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { appStoreUrl, homeUrl, playStoreUrl } from '@utils/UrlBuilder';
import { CommonLoader } from '@components/index';
import appStoreImage from '@images/download-app-store.png';
import playStoreImage from '@images/download-play-store.png';
import strings from '@strings/LocalisedStrings';
import mobile1 from '@images/mobile1.png';
import mobile2 from '@images/mobile2.png';
import mobile3 from '@images/mobile3.png';
import desktop1 from '@images/desktop1.png';
import desktop2 from '@images/desktop2.png';
import styles from './index.module.scss';

const Home: FC = (): JSX.Element => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  const handleLoginPressed = async () => {
    await loginWithRedirect();
  };

  if (isLoading) return <CommonLoader />;
  if (isAuthenticated) return <Redirect to={homeUrl()} />;
  return (
    <Container className={styles.homePage}>
      <Typography variant="h4">Dash Client Portal</Typography>

      <Typography variant="body1" component="p">
        {strings.dashClientPortal}
      </Typography>

      <Typography variant="h4" className={styles.name}>
        App Features
      </Typography>

      <Typography variant="body1" component="p">
        {strings.dashClientPortalDesc}
      </Typography>

      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 2, sm: 4, md: 12 }}
        style={{ paddingTop: '20px', maxWidth: '1920px' }}
      >
        <Grid item xs={2} sm={4} md={4} style={{ textAlign: 'center' }}>
          <img src={mobile1} alt="mobile1" width="234" />
          <Typography variant="body1" component="p" style={{ textAlign: 'center' }}>
            Access a snapshot of your account across all portfolios
          </Typography>
        </Grid>
        <Grid item xs={2} sm={4} md={4} style={{ textAlign: 'center' }}>
          <img src={mobile2} alt="mobile2" width="234" />
          <Typography variant="body1" component="p" style={{ textAlign: 'center' }}>
            Per portfolio performance and asset valuations
          </Typography>
        </Grid>
        <Grid item xs={2} sm={4} md={4} style={{ textAlign: 'center' }}>
          <img src={mobile3} alt="mobile3" width="234" />
          <Typography variant="body1" component="p" style={{ textAlign: 'center' }}>
            Deep dive into an asset cost base and recent transactions
          </Typography>
        </Grid>
      </Grid>

      <Typography variant="h4" className={styles.name}>
        Web Features
      </Typography>

      <Typography variant="body1" component="p">
        The client portal can also be accessed via a supported browser on devices such as Laptops,
        PCs, Tablets and mobiles. When accessing on larger screens, you will be able to get greater
        depth of portfolio data and additional filtering and sorting functions.
      </Typography>

      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 2, sm: 4, md: 12 }}
        style={{ paddingTop: '20px', maxWidth: '1920px' }}
      >
        <Grid item xs={2} sm={6} md={6} style={{ textAlign: 'center' }}>
          <img src={desktop1} alt="desktop1" width="350" />
          <Typography
            variant="body1"
            component="p"
            style={{ textAlign: 'center', paddingTop: '20px' }}
          >
            Detailed portfolio charting allows you to customise the date range that you are
            reviewing as well as comparing portfolios to selected benchmarks.
          </Typography>
        </Grid>
        <Grid item xs={2} sm={6} md={6} style={{ textAlign: 'center' }}>
          <img src={desktop2} alt="desktop2" width="350" />
          <Typography
            variant="body1"
            component="p"
            style={{ textAlign: 'center', paddingTop: '20px' }}
          >
            Easily search, filter, sort and download your holdings and transactions data.
          </Typography>
        </Grid>
      </Grid>

      <Typography variant="h4" className={styles.name}>
        Access
      </Typography>

      <Typography variant="body1" component="p">
        Access the Dash client portal in a browser or app is available in a few easy steps:
      </Typography>

      <ol style={{ marginLeft: '30px' }}>
        <li>
          <Typography variant="body1" component="p">
            Have your adviser create your log in (if you don’t already have one). To access the app
            you must be a client of a financial adviser that is using Dash wealth management
            software to manage your investments. Your financial adviser can initiate the
            registration process.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" component="p">
            Complete the registration process by following the instructions in your welcome email
            and creating a new password.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" component="p">
            Go to <a href="https://portal.dash.com.au">portal.dash.com.au</a> to access the browser
            based version of the client portal or download the mobile app from the links below.
          </Typography>
          <Link href={appStoreUrl()} color="inherit" target="_blank">
            <img
              src={appStoreImage}
              alt={strings.appleLink}
              style={{ paddingBottom: '10px', paddingRight: '10px' }}
            />
          </Link>
          <Link href={playStoreUrl()} color="inherit" target="_blank">
            <img
              src={playStoreImage}
              alt={strings.googlePlayLink}
              style={{ paddingBottom: '10px' }}
            />
          </Link>
        </li>
        <li>
          <Typography variant="body1" component="p">
            Login to the mobile app Login to the portal or mobile app using your registered email
            and password. With the app on a mobile device you can also use biometric logins via
            fignerprint &amp; facial recognition.
          </Typography>
        </li>
      </ol>

      <div className={styles.bottomContainer}>
        <Button variant="contained" onClick={handleLoginPressed}>
          {strings.login}
        </Button>
      </div>
    </Container>
  );
};

export default Home;
