import { CSSProperties, FC } from 'react';
import styles from './index.module.scss';

interface emptyDataTextProps {
  emptyText: string;
  style?: CSSProperties;
  className?: string;
}

const NoDataContainer: FC<emptyDataTextProps> = ({
  emptyText,
  className = '',
  style,
}): JSX.Element => (
  <div style={style} className={`${styles.noData} ${className}`}>
    {emptyText}
  </div>
);

NoDataContainer.defaultProps = {
  style: undefined,
  className: '',
};

export default NoDataContainer;
