import { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {
  dateFormatterToIso,
  numberFormatterDollar,
  percentageFormatter,
} from '@utils/GenericUtils';
import { selectedHoldingsUrl } from '@utils/UrlBuilder';
import { AssetCardProps } from '@src/app/types';
import buttonBackArrowImg from '@icons/button_back_arrow.svg';
import styles from './AssetCard.module.scss';

const AssetCard: FC<AssetCardProps> = ({
  color,
  assetClass,
  assetAllocationAsOfDate,
}): JSX.Element => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const percentageOfPortfolio = assetClass.percentageOfPortfolio!;
  const name = assetClass.name!;
  const value = assetClass.value!;
  const assetId = assetClass.assetClassKey!;

  return (
    <Box
      className={styles.assetCard}
      onClick={() =>
        history.push(
          selectedHoldingsUrl(id, assetId.toString(), dateFormatterToIso(assetAllocationAsOfDate))
        )
      }
    >
      <Grid container spacing={1} wrap="nowrap">
        <Grid item xs={1} style={{ paddingLeft: '3px' }}>
          <Box width={18} height={18} bgcolor={color} borderRadius="50%" />
        </Grid>
        <Grid item xs={2} className={styles.assetPercentage}>
          <Typography variant="subtitle2" component="span" style={{ marginLeft: '0px' }}>
            {percentageFormatter(percentageOfPortfolio)}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="subtitle2"
            noWrap
            style={{
              paddingLeft: '0px',
            }}
          >
            {name}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2" ml="auto">
            {numberFormatterDollar(value)}
          </Typography>
        </Grid>
        <Grid item xs={1} style={{ paddingRight: '10px', paddingLeft: '5px' }}>
          <img className={`rotateArrow ${styles.arrow}`} src={buttonBackArrowImg} alt="arrow" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AssetCard;
