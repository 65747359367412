import { injectable } from 'inversify';
import ICookieStorageService from './ICookieStorage.interface';

@injectable()
class CookieStorageService implements ICookieStorageService {
  get = (key: string): string | undefined => {
    const allCookies: string[] = document.cookie.split(';');
    const cookie: string | undefined = allCookies.find((keys: string) =>
      keys.startsWith(`${key}=`)
    );
    const cookieValue: string | undefined = cookie ? cookie.split('=')[1] : undefined;
    return cookieValue;
  };

  set = (key: string, value: string | number, expiresInSeconds = 300): void => {
    const date = new Date();
    const milliseconds: number = expiresInSeconds * 1000;
    date.setTime(date.getTime() + milliseconds);
    document.cookie = `${key}=${value}; expires=${date.toUTCString()};`;
  };

  clear = (key: string): void => {
    const date = new Date();
    const milliseconds: number = 1 * 1000;
    date.setTime(date.getTime() - milliseconds);
    document.cookie = `${key}=''; expires=${date.toUTCString()};`;
  };

  clearAll = (): void => {
    const cookies = document.cookie.split(';');
    cookies.forEach((cookie) => {
      document.cookie = `${cookie}; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    });
  };
}

export default CookieStorageService;
