import 'reflect-metadata';
import { Provider as IOCProvider } from 'inversify-react';
import { BrowserRouter as Router } from 'react-router-dom';
import container from '@ioc';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { SnackbarProvider } from 'notistack';
import { Auth0Scope } from 'clientportalshared';
import AppShell from '@src/app/shell/App.component';
import { store } from '@src/app/store';
import { Auth0Provider } from '@auth0/auth0-react';
import WebFont from 'webfontloader';
import '@styles/index.scss';
import ReactFC from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import TimeSeries from 'fusioncharts/fusioncharts.timeseries';
import { loginCallbackUrl } from '@utils/UrlBuilder';
import { errorHandler } from '@utils/ErrorBoundaryUtils';
import { ErrorFallbackComponent } from '@components/index';
import appConfig from './app/config/appConfig';

const Root = (): JSX.Element => {
  useEffect(() => {
    // Fusion Charts specific license implementation, disable linting for the section
    /* eslint-disable */
    // @ts-ignore
    FusionCharts.options['license'](appConfig.fusionChartsLicense);
    /* eslint-enable */
    ReactFC.fcRoot(FusionCharts, TimeSeries);
    WebFont.load({
      google: {
        families: ['Roboto', 'sans-serif'],
      },
    });
  }, []);

  return (
    <IOCProvider container={container}>
      <React.StrictMode>
        <Provider store={store}>
          <Router>
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent} onError={errorHandler}>
              <Auth0Provider
                domain={appConfig.auth0Domain}
                clientId={appConfig.auth0ClientId}
                redirectUri={loginCallbackUrl()}
                audience={appConfig.auth0Audience}
                scope={Auth0Scope.StandardScopes}
              >
                <SnackbarProvider hideIconVariant>
                  <AppShell />
                </SnackbarProvider>
              </Auth0Provider>
            </ErrorBoundary>
          </Router>
        </Provider>
      </React.StrictMode>
    </IOCProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));
