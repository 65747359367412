/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js';
import { inject, injectable } from 'inversify';
import { Auth0Scope } from 'clientportalshared';
import appConfig from '@config/appConfig';
import ICookieStorageService, {
  ICookieStorageService$,
} from '@services/storage/cookie/ICookieStorage.interface';
import ILocalStorageService, {
  ILocalStorageService$,
} from '@services/storage/local/ILocalStorage.interface';
import ISessionStorageService, {
  ISessionStorageService$,
} from '@services/storage/session/ISessionStorage.interface';
import { loginCallbackUrl } from '@utils/UrlBuilder';
import AuthenticationStateEnum from './AuthenticationState.enum';
import { IAuthenticationService } from './IAuthentication.interface';

@injectable()
export default class JWTAuthenticationService implements IAuthenticationService {
  private cookieStorageService: ICookieStorageService;

  private localStorageService: ILocalStorageService;

  private sessionStorageService: ISessionStorageService;

  private authenticationState: AuthenticationStateEnum;

  private authToken: string | null;

  private auth0Client: Auth0Client | null;

  constructor(
    @inject(ICookieStorageService$) cookieStorageService: ICookieStorageService,
    @inject(ILocalStorageService$) localStorageService: ILocalStorageService,
    @inject(ISessionStorageService$) sessionStorageService: ISessionStorageService
  ) {
    this.authenticationState = AuthenticationStateEnum.UNKNOWN;
    this.authToken = null;
    this.auth0Client = null;

    this.cookieStorageService = cookieStorageService;
    this.localStorageService = localStorageService;
    this.sessionStorageService = sessionStorageService;
  }

  async init(): Promise<void> {
    const auth0 = await createAuth0Client({
      domain: appConfig.auth0Domain,
      client_id: appConfig.auth0ClientId,
      audience: appConfig.auth0Audience,
      redirect_uri: loginCallbackUrl(),
      scope: Auth0Scope.StandardScopes,
    });
    this.auth0Client = auth0;

    const token = this.cookieStorageService.get('app:authToken');
    if (token) {
      this.authenticationState = AuthenticationStateEnum.LOGGED_IN;
      this.setToken(token);
    } else {
      this.authenticationState = AuthenticationStateEnum.LOGGED_OFF;
    }
  }

  getToken(): string | null {
    return this.authToken;
  }

  getAccessToken(): string | undefined {
    return this.authToken || undefined;
  }

  async getAccessTokenUsingRefreshToken(): Promise<string | undefined> {
    if (!this.auth0Client) throw new Error('Auth0 client not yet initialized');

    const r = await this.auth0Client.getTokenSilently();
    this.authToken = r;
    return this.authToken;
  }

  checkTokenExpiry(): void {
    const token = this.getToken();
    if (!token) {
      this.forcedLogout();
    } else {
      const parsedJWT = JSON.parse(atob(token.split('.')[1])); // Need to change this JWT model
      if (parsedJWT && parsedJWT.exp) {
        const currentTime = new Date();
        const expiryTime = new Date(parsedJWT.exp);
        if (currentTime > expiryTime) {
          this.forcedLogout();
        }
      }
    }
  }

  setToken(jwtToken: string): void {
    this.authToken = jwtToken;
    this.cookieStorageService.set(
      'app:authToken',
      jwtToken,
      (process.env.REACT_APP_AUTH_COOKIE_EXPIRATION as number | undefined) || 300
    );
    this.authenticationState = AuthenticationStateEnum.LOGGED_IN;
  }

  getAuthenticationState(): AuthenticationStateEnum {
    return this.authenticationState;
  }

  logoutAsync(): void {
    this.clearData();
    this.authenticationState = AuthenticationStateEnum.LOGGED_OFF;
  }

  forcedLogout(): void {
    this.clearData();
    this.authenticationState = AuthenticationStateEnum.FORCED_LOGGED_OFF;
  }

  private clearData(): void {
    this.authToken = null;

    this.cookieStorageService.clear('app:authToken');
    this.localStorageService.clearAll();
    this.sessionStorageService.clearAll();
  }
}
