import { interfaces } from 'inversify';
import { Screen } from '@enums/index';

export interface ICommunicationService {
  getAsync: <T>(
    path: string,
    params?: Record<string, unknown>,
    screen?: Screen,
    removeBaseUrl?: boolean
  ) => Promise<T>;
  postAsync: <T>(
    path: string,
    body: Record<string, unknown> | FormData,
    params?: Record<string, unknown>,
    screen?: Screen,
    removeBaseUrl?: boolean
  ) => Promise<T>;
  postAnonymousAsync: <T>(
    path: string,
    body: Record<string, unknown> | FormData,
    params?: Record<string, unknown>,
    screen?: Screen,
    removeBaseUrl?: boolean
  ) => Promise<T>;
  downloadPdfAsync: (attachmentUrl: string, filename: string) => Promise<void>;
}

const ICommunicationService$: interfaces.ServiceIdentifier<ICommunicationService> =
  Symbol('ICommunicationService');

export { ICommunicationService$ };
