import { createSlice } from '@reduxjs/toolkit';
import { BenchmarkInfo, PerformanceItem, Portfolio } from 'clientportalshared';
import { ScreenState } from '@enums/index';
import {
  getAssetAllocationAsOfDateAsync,
  getPortfolioDetailsAsync,
  getBenchmarkPerformanceDataAsync,
  getTop6ReportsAsync,
} from './thunks';
import type { RootState } from '../../index';

export interface PortfolioDetailsStore {
  state: ScreenState;
  assetAllocationState: ScreenState;
  benchmarkPerformanceState: ScreenState;
  data: Portfolio;
  benchmarkSelected: BenchmarkInfo | null;
  benchmarkPerformance: PerformanceItem[] | undefined;
  error?: string;
  assetAllocationAsOfDate: Date | string;
  isInvalidPortfolioId?: boolean;
}

const initialState = {
  benchmarkPerformanceState: ScreenState.SuccessDataLoaded,
  assetAllocationState: ScreenState.SuccessDataLoaded,
  assetAllocationAsOfDate: new Date().toISOString(),
} as PortfolioDetailsStore;

export const PortfolioDetailsSlice = createSlice({
  name: 'portfolioDetails',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getPortfolioDetailsAsync.fulfilled, (prevState, action) => {
        return {
          ...prevState,
          state: ScreenState.SuccessDataLoaded,
          data: action.payload,
        };
      })
      .addCase(getPortfolioDetailsAsync.pending, (prevState) => {
        return { ...prevState, state: ScreenState.Loading };
      })
      .addCase(getPortfolioDetailsAsync.rejected, (prevState, action) => {
        return {
          ...prevState,
          state: ScreenState.ErrorUnableToLoadData,
          error: action.error.message,
        };
      })
      .addCase(getBenchmarkPerformanceDataAsync.fulfilled, (prevState, action) => {
        return {
          ...prevState,
          benchmarkPerformanceState: ScreenState.SuccessDataLoaded,
          benchmarkSelected: action.payload.benchmarkSelected,
          benchmarkPerformance: action.payload.benchmarkPerformace?.map((b) => ({
            ...b,
            metric1: b.metric1! * 100,
            metric2: b.metric2! * 100,
          })),
        };
      })
      .addCase(getBenchmarkPerformanceDataAsync.pending, (prevState) => {
        return { ...prevState, benchmarkPerformanceState: ScreenState.Loading };
      })
      .addCase(getBenchmarkPerformanceDataAsync.rejected, (prevState, action) => {
        return {
          ...prevState,
          benchmarkPerformanceState: ScreenState.ErrorUnableToLoadData,
          error: action.error.message,
        };
      })
      .addCase(getAssetAllocationAsOfDateAsync.fulfilled, (prevState, action) => {
        return {
          ...prevState,
          assetAllocationState: ScreenState.SuccessDataLoaded,
          data: {
            ...prevState.data,
            assetClasses: action.payload.data!,
          },
          assetAllocationAsOfDate: action.payload.asOfDate!,
        };
      })
      .addCase(getAssetAllocationAsOfDateAsync.pending, (prevState) => {
        return {
          ...prevState,
          assetAllocationState: ScreenState.Loading,
        };
      })
      .addCase(getAssetAllocationAsOfDateAsync.rejected, (prevState, action) => {
        return {
          ...prevState,
          assetAllocationState: ScreenState.ErrorUnableToLoadData,
          error: action.error.message,
        };
      })
      .addCase(getTop6ReportsAsync.fulfilled, (prevState, action) => {
        return {
          ...prevState,
          data: {
            ...prevState.data,
            recentDocuments: action.payload,
          },
        };
      });
  },
});

export const selectPortfolioDetails = (state: RootState): PortfolioDetailsStore =>
  state.portfolioDetails;

export default PortfolioDetailsSlice.reducer;
