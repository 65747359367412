import { FC, useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@hooks/store';
import {
  getSuperSimplifierAccountsAsync,
  getSuperSimplifierAccountAsync,
} from '@store/reducers/supersimplifier/thunks';
import { ScreenState } from '@src/app/enums';
import {
  SupersimplifierStore,
  selectSupersimplifier,
  setSuperSimplifierAccountParameter,
} from '@store/reducers/supersimplifier';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { BackButton } from '@components/index';
import ListedBeneficiaries, { Beneficiary } from '@components/superTables/listedBeneficiaries';
import { homeUrl } from '@utils/UrlBuilder';
import { Alert, Stack } from '@mui/material';
import { SuperSimplifierAccountSummary } from 'clientportalshared';

const Beneficiaries: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const supersimplifierStore: SupersimplifierStore = useAppSelector(selectSupersimplifier);

  const [selectedAccount, setSelectedAccount] = useState('');

  const getSuperSimplifierAccount = async (
    accountLis: SuperSimplifierAccountSummary[] | undefined
  ) => {
    if (accountLis?.length && accountLis[0].accountNumber) {
      await dispatch(
        getSuperSimplifierAccountAsync({
          accountNumber: accountLis[0].accountNumber,
        })
      );
    }
  };

  const getPageData = () => {
    dispatch(getSuperSimplifierAccountsAsync())
      .unwrap()
      .then((responce) => {
        if (responce?.data?.length && responce.data[0].accountNumber) {
          setSelectedAccount(responce.data[0].accountNumber);
        }
        getSuperSimplifierAccount(responce.data);
      });
  };

  useEffect(() => {
    getPageData();
  }, []);

  let beneficiaries: Beneficiary[] = [];

  if (supersimplifierStore.superSimplifierAccount?.beneficiaries) {
    beneficiaries = supersimplifierStore.superSimplifierAccount?.beneficiaries;
  }

  const handleAccountChange = (event: SelectChangeEvent) => {
    setSelectedAccount(event.target.value);
    dispatch(setSuperSimplifierAccountParameter(event.target.value));
    dispatch(
      getSuperSimplifierAccountAsync({
        accountNumber: event.target.value,
      })
    );
  };

  let errorMessage = null;
  if (supersimplifierStore.superSimplifierAccountState === ScreenState.ErrorUnableToLoadData) {
    errorMessage =
      'There is currently no beneficiary information for this account. If the account has been recently established it may take a few days for this data to be populated. Please contact your adviser or our client service team for assistance.';
    beneficiaries = [];
  } else if (supersimplifierStore.superSimplifierAccountState === ScreenState.Loading) {
    beneficiaries = [];
  }

  return (
    <Container>
      <BackButton url={homeUrl()} />
      <Grid container className="portfolioContent" rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} rowSpacing={{ xs: 2, md: 2 }}>
              <p className="pageHeader">Beneficiaries</p>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} rowSpacing={{ xs: 2, md: 2 }} sx={{ marginTop: '20px' }}>
              <Select
                labelId="selected-account-select-label"
                id="selected-account-select"
                value={selectedAccount}
                defaultValue={selectedAccount}
                label="ACCOUNT"
                onChange={handleAccountChange}
              >
                {supersimplifierStore?.superSimplifierAccounts?.data?.map((i) => (
                  <MenuItem key={i.accountNumber} value={i.accountNumber ?? ''}>{`${
                    i.accountNumber ?? ''
                  } - ${i.name ?? ''}`}</MenuItem>
                ))}
              </Select>
            </Grid>

            {errorMessage && (
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  rowSpacing={{ xs: 2, md: 2 }}
                  sx={{ marginTop: '20px', marginLeft: '17px' }}
                >
                  <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert variant="outlined" severity="info">
                      {errorMessage}
                    </Alert>
                  </Stack>
                </Grid>
              </Grid>
            )}

            {!errorMessage && (
              <>
                <ListedBeneficiaries tableData={beneficiaries} />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Beneficiaries;
