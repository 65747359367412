/* eslint-disable no-param-reassign */
import { FC } from 'react';
import { DateTime } from 'luxon';
import {
  MUIDataTableState,
  MUIDataTableColumn,
  MUIDataTableCustomFilterListOptions,
} from 'mui-datatables';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { DateTimeFormats } from '@enums/index';
import strings from '@strings/LocalisedStrings';

interface NumberRangePickerProps {
  filterList: MUIDataTableState['filterList'];
  onChange: (val: string | string[], index: number, column: MUIDataTableColumn) => void;
  index: number;
  column: MUIDataTableColumn;
  title: string;
}

interface DateRangePickerProps {
  filterList: MUIDataTableState['filterList'];
  onChange: (val: string | string[], index: number, column: MUIDataTableColumn) => void;
  index: number;
  column: MUIDataTableColumn;
}

export const customRender = (
  filters: string[],
  title: string,
  formatter?: (v: string) => string
): string[] => {
  if (formatter) {
    if (filters[0] && filters[1]) {
      return [`Min ${title}: ${formatter(filters[0])}, Max ${title}: ${formatter(filters[1])}`];
    }
    if (filters[0] && formatter(filters[0])) {
      return [`Min ${title}: ${formatter(filters[0])}`];
    }
    if (filters[1] && formatter(filters[1])) {
      return [`Max ${title}: ${formatter(filters[1])}`];
    }
    return [];
  }

  if (filters[0] && filters[1]) {
    return [`Min ${title}: ${filters[0]}, Max ${title}: ${filters[1]}`];
  }
  if (filters[0]) {
    return [`Min ${title}: ${filters[0]}`];
  }
  if (filters[1]) {
    return [`Max ${title}: ${filters[1]}`];
  }
  return [];
};

export const customUpdate: MUIDataTableCustomFilterListOptions['update'] = (
  filterList,
  _filterPos,
  index
): string[][] => {
  filterList[index] = ['', ''];
  return filterList;
};

export const NumberRangePicker: FC<NumberRangePickerProps> = ({
  filterList,
  onChange,
  index,
  column,
  title,
}): JSX.Element => {
  const currentFilter = filterList[index];

  return (
    <>
      <Typography variant="subtitle2" mb={1}>
        {title}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            type="number"
            label={strings.from}
            value={currentFilter[0] ? currentFilter[0] : ''}
            onChange={(e) => {
              currentFilter[0] = e.target.value;
              onChange(currentFilter, index, column);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="number"
            label={strings.to}
            value={currentFilter[1] ? currentFilter[1] : ''}
            onChange={(e) => {
              currentFilter[1] = e.target.value;
              onChange(currentFilter, index, column);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const DateRangePicker: FC<DateRangePickerProps> = ({
  filterList,
  onChange,
  index,
  column,
}): JSX.Element => {
  const currentFilter = filterList[index];

  return (
    <>
      <Typography variant="subtitle2" mb={1}>
        {strings.date}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              label={strings.from}
              inputFormat={DateTimeFormats.YearMonthDay}
              value={currentFilter[0] ? DateTime.fromISO(currentFilter[0]) : null}
              onChange={(newValue) => {
                if (newValue) {
                  const newValueString = newValue.toISO();
                  currentFilter[0] = newValueString;
                  onChange(currentFilter, index, column);
                }
              }}
              renderInput={(params) => <TextField {...params} error={false} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              label={strings.to}
              inputFormat={DateTimeFormats.YearMonthDay}
              value={currentFilter[1] ? DateTime.fromISO(currentFilter[1]) : null}
              onChange={(newValue) => {
                if (newValue) {
                  const newValueString = newValue.toISO();
                  currentFilter[1] = newValueString;
                  onChange(currentFilter, index, column);
                }
              }}
              renderInput={(params) => <TextField {...params} error={false} />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </>
  );
};
