import { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { rootUrl } from '@utils/UrlBuilder';
import { useAppSelector } from '@hooks/store';
import { selectAuthState } from '@store/reducers/auth';
import AuthenticationStateEnum from '@services/auth/AuthenticationState.enum';
import { RouteConfig } from './route.config';

const ProtectedRoute = (props: RenderSingleRouteProps): JSX.Element => {
  const { route } = props;
  const Component: FC<Record<string, unknown>> = route.component;
  const authState = useAppSelector(selectAuthState);
  const isAuthorized = authState === AuthenticationStateEnum.LOGGED_IN;

  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(componentProps) =>
        isAuthorized ? <Component {...componentProps} /> : <Redirect to={rootUrl()} />
      }
    />
  );
};

interface RenderSingleRouteProps {
  route: RouteConfig;
}

const RenderRoute = (props: RenderSingleRouteProps): JSX.Element => {
  const { route } = props;
  const Component = route.component;
  const renderedRoute = (
    <Route
      path={route.path}
      exact={route.exact}
      render={(componentProps) => <Component {...componentProps} />}
    />
  );

  if (route.protected) {
    return <ProtectedRoute {...props} />;
  }

  return renderedRoute;
};

interface RenderRouteProps {
  routes: readonly RouteConfig[];
}

const RenderRoutes = (props: RenderRouteProps): JSX.Element => {
  const { routes } = props;
  const renderedRoutes = routes.map((route) => {
    return <RenderRoute route={route} key={route.key} />;
  });

  return <>{renderedRoutes}</>;
};

export { RenderRoute };

export default RenderRoutes;
