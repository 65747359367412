/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Severity } from '@sentry/react';
import User from '@services/user/User.model';
import { injectable } from 'inversify';
import { LogLevel } from '@enums/index';
import ILogTransport from './ILogTransport.interface';
import LogEntryModel from './LogEntry.model';

@injectable()
export default class SentryLogTransport implements ILogTransport {
  init = (): void => {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || 'production',
      release: process.env.REACT_APP_VERSION,

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    });
  };

  log = (entry: LogEntryModel): void => {
    let sentryLevel: Severity;

    if (entry.level === Severity.fromString(LogLevel.Debug)) sentryLevel = Severity.Debug;
    if (entry.level === Severity.fromString(LogLevel.Error)) sentryLevel = Severity.Error;
    if (entry.level === Severity.fromString(LogLevel.Fatal)) sentryLevel = Severity.Fatal;
    else sentryLevel = Severity.Info;

    if (
      entry.level === Severity.fromString(LogLevel.Debug) ||
      entry.level === Severity.fromString(LogLevel.Info)
    ) {
      Sentry.addBreadcrumb({
        ...entry,
        level: sentryLevel,
      });
    } else {
      const data = entry.data ? entry.data : { message: '' };
      data.message = entry.message;
      Sentry.captureException(entry.error, {
        extra: data,
        level: sentryLevel,
      });
    }
  };

  registerUser = (user: User): void => {
    Sentry.setUser({
      email: user.emailAddress,
      username: user.username,
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
    });
  };

  page = (title: string, url: string): void => {
    const entry = new LogEntryModel('Navigated to a new page', Severity.fromString(LogLevel.Info), {
      title,
      url,
    });
    this.log(entry);
  };
}
