import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import { AssetClassInfo } from 'clientportalshared';
import Stack from '@mui/material/Stack';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import usePortfolioDetailData from '@hooks/usePortfolioDetailData';
import { getAssetAllocationAsOfDateAsync } from '@store/reducers/portfolioDetails/thunks';
import { SystemMinimumDate } from '@utils/GenericUtils';
import fusionChartPaletteColors from '@constants/paletteColors';
import {
  PaginationComponent,
  DoughnutChart,
  CustomSkeleton,
  UnableToLoadData,
  NoDataContainer,
} from '@components/index';
import strings from '@strings/LocalisedStrings';
import { ScreenState } from '@enums/index';
import { AssetCardProps, AssetChartData } from '@src/app/types';
import styles from './AssetChart.module.scss';
import AssetCard from './AssetCard';
import DateRangePicker from '@components/DateRangePicker';
import { DateRange } from '../../store';

interface AssetChartProps {
  assetAllocationState: ScreenState;
  data: AssetClassInfo[];
  inceptionDate: Date;
}

const AssetChart: FC<AssetChartProps> = ({
  data,
  assetAllocationState,
  inceptionDate,
}): JSX.Element => {
  const dispatch = useDispatch();
  const { loadPortfolioDetailsData } = usePortfolioDetailData();
  const { id } = useParams<{ id: string }>();
  const { portfolioDetailsStore } = usePortfolioDetailData();

  const [chartData, setChartData] = useState<AssetChartData[]>([]);
  const [assetCardData, setAssetCardData] = useState<AssetCardProps[]>([]);
  const [assetAllocationAsOfDate] = useState<DateTime | null>(DateTime.now());

  const [dateParameter, setDateParameter] = useState({
    dateFrom: DateTime.fromISO(SystemMinimumDate).toISODate(),
    dateTo: DateTime.now().toISODate(),
  });

  const updateAssetDates = (dates: DateRange) => {
    setDateParameter(dates);
    dispatch(
      getAssetAllocationAsOfDateAsync({
        portfolioKey: id,
        allocationsAsOfDate: DateTime.fromJSDate(new Date(dates.dateTo)),
      })
    );
  };

  useEffect(() => {
    const tempChartData: AssetChartData[] = [];
    const tempAssetCardData: AssetCardProps[] = [];

    data.forEach((item, index) => {
      const assignedColor = fusionChartPaletteColors[index % 12];

      tempChartData.push({
        label: item.name!,
        value: item.percentageOfPortfolio!,
        color: assignedColor,
      });

      tempAssetCardData.push({
        color: assignedColor,
        assetClass: item,
        assetAllocationAsOfDate: assetAllocationAsOfDate!.toJSDate(),
      });
    });

    setChartData(tempChartData);
    setAssetCardData(tempAssetCardData);
  }, [data]);

  const AssetsContainer: FC<{ items: AssetCardProps[] }> = ({ items }): JSX.Element => (
    <div className={styles.assetsContainer}>
      {items.map((item) => (
        <AssetCard
          key={item.assetClass.assetClassKey}
          color={item.color}
          assetClass={item.assetClass}
          assetAllocationAsOfDate={new Date(portfolioDetailsStore.assetAllocationAsOfDate)}
        />
      ))}
    </div>
  );

  if (assetAllocationState === ScreenState.ErrorUnableToLoadData)
    return <UnableToLoadData onClick={() => loadPortfolioDetailsData(true)} />;

  return (
    <Box mt={4}>
      <Grid container spacing={2} rowSpacing={4} ml={0}>
        <Grid item xs={1} sx={{ marginBottom: '15px' }}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DateRangePicker
              dateRange={dateParameter}
              setDateRange={updateAssetDates}
              inceptionDate={inceptionDate.toString()}
              isDateOnly
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={11}></Grid>
      </Grid>

      {assetAllocationState === ScreenState.Loading ? (
        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
          <CustomSkeleton variant="circular" height={395} width={395} />
          <Stack spacing={1} width="50%">
            <CustomSkeleton variant="rectangular" height={54} numberOfItems={6} />
          </Stack>
        </Stack>
      ) : (
        <>
          {!data?.length ? (
            <NoDataContainer emptyText={strings.noAssetAllocations} style={{ marginTop: '2rem' }} />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <DoughnutChart chartData={chartData} />
              </Grid>
              <Grid item xs={12} md={6}>
                <PaginationComponent
                  itemsPerPage={7} // 395 / 54 (heightOfDonut / heightOfAssetClass)
                  Container={AssetsContainer}
                  items={assetCardData}
                  outerContainerClassName={styles.paginationOuterContainer}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Box>
  );
};

export default AssetChart;
