import { FC } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import appStoreImage from '@images/download-app-store.png';
import playStoreImage from '@images/download-play-store.png';
import strings from '@localizedstrings/LocalisedStrings';
import {
  appStoreUrl,
  playStoreUrl,
  wealthO2TermsAndConditionsUrl,
  wealthO2PrivacyPolicyUrl,
  contactUsUrl,
  wealthO2HomePageUrl,
} from '@utils/UrlBuilder';
import './_footer.scss';

const Footer: FC = (): JSX.Element => {
  return (
    <footer>
      <Container>
        <Box sx={{ typography: 'body1' }} className="appFooterLinks">
          <Link href={wealthO2TermsAndConditionsUrl()} color="inherit" target="_blank">
            {strings.termsAndConditions}
          </Link>
          <Link href={wealthO2PrivacyPolicyUrl()} color="inherit" target="_blank">
            {strings.privacyPolicy}
          </Link>
          <Link href={contactUsUrl()} color="inherit" target="_blank">
            {strings.contactUs}
          </Link>
          <Link href={wealthO2HomePageUrl()} color="inherit" target="_blank">
            &copy; {strings.dashTechnologyGroup}
          </Link>
          <Box className="appFooterMobileLinks">
            <Link href={appStoreUrl()} color="inherit" target="_blank">
              <img src={appStoreImage} alt={strings.appleLink} />
            </Link>
            <Link href={playStoreUrl()} color="inherit" target="_blank">
              <img src={playStoreImage} alt={strings.googlePlayLink} />
            </Link>
          </Box>
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;
