/* eslint-disable no-param-reassign */
import axios, { AxiosInstance, AxiosError, AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';
import { Severity } from '@sentry/types';
import { IAuthenticationService$ } from '@services/auth/IAuthentication.interface';
import type { IAuthenticationService } from '@services/auth/IAuthentication.interface';
import getApiMockDataForScreen from '@mocks/ApiResponse';
import { HttpStatusCode, MimeType, HttpHeaders, Screen } from '@enums/index';
import ApiError from '@services/errors/Api.error';
import AuthenticationStateEnum from '@services/auth/AuthenticationState.enum';
import { ILoggingService$ } from '@services/logging/ILoggingService.interface';
import type ILoggingService from '@services/logging/ILoggingService.interface';
import { store } from '@store/index';
import { setAuthState } from '@store/reducers/auth';
import appConfig from '@config/appConfig';
import { saveAs } from 'file-saver';
import { read as readEmail } from 'eml-format';
import { ICommunicationService } from './ICommunication.interface';

@injectable()
export default class AxiosCommunicationService implements ICommunicationService {
  private axiosInstance: AxiosInstance;

  private authService: IAuthenticationService;

  private loggingService: ILoggingService;

  constructor(
    @inject(IAuthenticationService$) jwtAuthenticationService: IAuthenticationService,
    @inject(ILoggingService$) loggingService: ILoggingService
  ) {
    this.axiosInstance = axios.create({
      baseURL: appConfig.apiEndpointUrl,
      headers: {
        [HttpHeaders.Accept]: MimeType.Json,
        [HttpHeaders.ContentType]: MimeType.Json,
      },
    });
    this.loggingService = loggingService;
    this.authService = jwtAuthenticationService;
  }

  async getAsync<T>(
    path: string,
    params: Record<string, unknown> = {},
    screen: Screen | undefined,
    removeBaseUrl?: boolean
  ): Promise<T> {
    let startTime: Date;
    try {
      if (appConfig.devUseWo2MockData) {
        const response = getApiMockDataForScreen(screen);
        return <T>response.data;
      }

      const token = this.authService.getToken() || '';
      startTime = new Date();
      const result = await this.axiosInstance.get<T>(path, {
        headers: {
          [HttpHeaders.Authorization]: `Bearer ${token}`,
        },
        params,
        baseURL: removeBaseUrl ? '' : undefined,
      });
      this.logApiSuccess(result, startTime);

      return result.data;
    } catch (_error) {
      const error = _error as AxiosError;
      this.logApiError(error, startTime!);

      if (error.response?.status) {
        this.processApiError(error, error.response.status);
      }

      throw error;
    }
  }

  async postAsync<T>(
    path: string,
    body: Record<string, unknown> | FormData,
    params: Record<string, unknown> = {},
    screen: Screen | undefined,
    removeBaseUrl?: boolean
  ): Promise<T> {
    let startTime: Date;
    try {
      if (appConfig.devUseWo2MockData) {
        const response = getApiMockDataForScreen(screen);
        return <T>response.data;
      }

      const token = this.authService.getToken() || '';
      startTime = new Date();
      const result = await this.axiosInstance.post<T>(path, body, {
        headers: {
          [HttpHeaders.Authorization]: `Bearer ${token}`,
        },
        params,
        baseURL: removeBaseUrl ? '' : undefined,
      });
      this.logApiSuccess(result, startTime);

      return result.data;
    } catch (_error) {
      const error = _error as AxiosError;
      this.logApiError(error, startTime!);

      if (error.response?.status) {
        this.processApiError(error, error.response.status);
      }

      throw error;
    }
  }

  async postAnonymousAsync<T>(
    path: string,
    body: Record<string, unknown> | FormData,
    params: Record<string, unknown> = {},
    screen: Screen | undefined,
    removeBaseUrl?: boolean
  ): Promise<T> {
    let startTime: Date;
    try {
      if (appConfig.devUseWo2MockData) {
        const response = getApiMockDataForScreen(screen);
        return <T>response.data;
      }

      startTime = new Date();
      const result = await this.axiosInstance.post<T>(path, body, {
        params,
        baseURL: removeBaseUrl ? '' : undefined,
      });
      this.logApiSuccess(result, startTime);

      return result.data;
    } catch (_error) {
      const error = _error as AxiosError;
      this.logApiError(error, startTime!);

      if (error.response?.status) {
        this.processApiError(error, error.response.status);
      }

      throw error;
    }
  }

  downloadPdfAsync(attachmentUrl: string, filename: string): Promise<void> {
    if (appConfig.devUseWo2MockData) {
      return this.axiosInstance({
        url: '../sample.pdf',
        responseType: 'blob',
        baseURL: '',
      }).then((result) => {
        saveAs.saveAs(result.data, filename);
      });
    }

    const startTime = new Date();

    return this.axiosInstance({
      url: attachmentUrl,
      headers: {
        [HttpHeaders.Accept]: MimeType.Pdf,
        [HttpHeaders.ContentType]: MimeType.Pdf,
      },
      responseType: 'blob',
      baseURL: '',
    })
      .then((result) => {
        this.logApiSuccess(result, startTime);

        if (filename.endsWith('.eml')) {
          AxiosCommunicationService.downloadAttachments(result.data, filename);
        } else {
          saveAs.saveAs(result.data, filename);
        }
      })
      .catch((error: AxiosError) => {
        this.logApiError(error, startTime);

        if (error.response?.status) {
          this.processApiError(error, error.response.status);
        }

        throw error;
      });
  }

  private processApiError(err: Error, statusCode: HttpStatusCode): void {
    if (statusCode === HttpStatusCode.Unauthorized) {
      this.authService.forcedLogout();
      store.dispatch(setAuthState(AuthenticationStateEnum.FORCED_LOGGED_OFF));
      throw new ApiError(`Session Expired, please login again`, HttpStatusCode.Unauthorized, true);
    }
  }

  private logApiSuccess(result: AxiosResponse, startTime: Date): void {
    const endTime = new Date();
    const durationInMs = endTime.getTime() - startTime.getTime();
    const message = `${result.config.method!.toUpperCase()} ${result.status} (${result.config
      .url!}) +${durationInMs}ms`;

    this.loggingService.log({
      message,
      level: Severity.Info,
      error: 'n/a',
    });
  }

  private logApiError(error: AxiosError, startTime: Date): void {
    const endTime = new Date();
    const durationInMs = endTime.getTime() - startTime.getTime();
    const message = `${error.config.method!.toUpperCase()} ${error.response?.status || ''} (${
      error.config?.url || ''
    }) +${durationInMs}ms`;

    this.loggingService.log({
      message,
      level: Severity.Error,
      error,
    });
  }

  private static downloadAttachments(data: Blob, filename: string): void {
    const reader = new FileReader();
    reader.onload = () => {
      readEmail(reader.result, (error, email) => {
        if (error) throw error;
        if (!email.attachments.length) {
          saveAs.saveAs(data, filename);
          return;
        }
        email.attachments.forEach((a, i) => {
          const filenameRegex = /name[^;=]*=((['"]).*?\2|[^;]*)/;
          const matches = filenameRegex.exec(a.contentType);
          const attachmentName =
            matches != null && matches[1]
              ? matches[1].replace(/['"]/g, '').replace(/[ \r\n]+/g, ' ')
              : `Email Attachment ${i}.pdf`;
          saveAs.saveAs(new Blob([a.data]), attachmentName);
        });
      });
    };
    reader.readAsText(data);
  }
}
