import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ScreenState } from '@enums/index';
import { MoneysoftFinancialDetailsViewModel, MoneysoftITokenViewModel } from 'clientportalshared';
import {
  getMoneysoftFinancialDetailsAsync,
  getMoneysoftInviteClientAsync,
  getMoneysoftSingleSignOnTokenAsync,
} from './thunks';
import type { RootState } from '../../index';

export interface MoneysoftStore {
  moneysoftFinancialDetailsState: ScreenState;
  moneysoftInviteClientState: ScreenState;
  moneysoftSingleSignOnTokenState: ScreenState;
  moneysoftFinancialDetails: MoneysoftFinancialDetailsViewModel | undefined;
  moneysoftInviteClient: MoneysoftITokenViewModel | undefined;
  moneysoftSingleSignOnToken: MoneysoftITokenViewModel | undefined;
  moneysoftAsAtDate: string | null;
  error: string | undefined;
}

const initialState = {
  moneysoftFinancialDetailsState: ScreenState.SuccessDataLoaded,
  moneysoftInviteClientState: ScreenState.SuccessDataLoaded,
  moneysoftSingleSignOnTokenState: ScreenState.SuccessDataLoaded,
  moneysoftFinancialDetails: undefined,
  moneysoftInviteClient: undefined,
  moneysoftSingleSignOnToken: undefined,
  moneysoftAsAtDate: null,
} as MoneysoftStore;

export const MoneysoftSlice = createSlice({
  name: 'moneysoft',
  initialState,
  reducers: {
    setMoneysoftAsAtDateParameter: (state, action: PayloadAction<string | null>) => {
      return { ...state, moneysoftAsAtDate: action.payload };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getMoneysoftFinancialDetailsAsync.fulfilled, (prevState, action) => {
        return {
          ...prevState,
          moneysoftFinancialDetailsState: ScreenState.SuccessDataLoaded,
          moneysoftFinancialDetails: action.payload,
        };
      })
      .addCase(getMoneysoftFinancialDetailsAsync.pending, (prevState) => {
        return { ...prevState, moneysoftFinancialDetailsState: ScreenState.Loading };
      })
      .addCase(getMoneysoftFinancialDetailsAsync.rejected, (prevState, action) => {
        return {
          ...prevState,
          moneysoftFinancialDetailsState: ScreenState.ErrorUnableToLoadData,
          error: action.error.message,
        };
      })

      .addCase(getMoneysoftInviteClientAsync.fulfilled, (prevState, action) => {
        return {
          ...prevState,
          moneysoftInviteClientState: ScreenState.SuccessDataLoaded,
          moneysoftInviteClient: action.payload,
        };
      })
      .addCase(getMoneysoftInviteClientAsync.pending, (prevState) => {
        return { ...prevState, moneysoftInviteClientState: ScreenState.Loading };
      })
      .addCase(getMoneysoftInviteClientAsync.rejected, (prevState, action) => {
        return {
          ...prevState,
          moneysoftInviteClientState: ScreenState.ErrorUnableToLoadData,
          error: action.error.message,
        };
      })

      .addCase(getMoneysoftSingleSignOnTokenAsync.fulfilled, (prevState, action) => {
        return {
          ...prevState,
          moneysoftSingleSignOnTokenState: ScreenState.SuccessDataLoaded,
          moneysoftSingleSignOnToken: action.payload,
        };
      })
      .addCase(getMoneysoftSingleSignOnTokenAsync.pending, (prevState) => {
        return { ...prevState, moneysoftSingleSignOnTokenState: ScreenState.Loading };
      })
      .addCase(getMoneysoftSingleSignOnTokenAsync.rejected, (prevState, action) => {
        return {
          ...prevState,
          moneysoftSingleSignOnTokenState: ScreenState.ErrorUnableToLoadData,
          error: action.error.message,
        };
      });
  },
});

export const { setMoneysoftAsAtDateParameter } = MoneysoftSlice.actions;

export const selectMoneysoft = (state: RootState): MoneysoftStore => state.moneysoft;

export default MoneysoftSlice.reducer;
