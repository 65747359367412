/* eslint-disable no-debugger */
import { FC, useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Grid, Stack } from '@mui/material';
import { useAppSelector, useAppDispatch } from '@hooks/store';
import { selectDashboard, DashboardStore, getDashboardAsync } from '@store/reducers/dashboard';
import { selectMoneysoft, MoneysoftStore } from '@store/reducers/moneysoft';
import { dateFormatter, numberFormatterDollar } from '@utils/GenericUtils';
import { moneySoftUrl } from '@utils/UrlBuilder';
import {
  PortfolioCard,
  MoneysoftCard,
  CustomSkeleton,
  CommonLoader,
  AreaTimeSeriesChart,
  UnableToLoadData,
} from '@components/index';
import { useHistory } from 'react-router-dom';
import ReturnValueDisplayMode from '@src/app/enums/ReturnValueDisplayMode.enum';
import { PerformanceChartData } from '@src/app/types';
import strings from '@localizedstrings/LocalisedStrings';
import { ScreenState, Screen, DateTimeFormats } from '@src/app/enums';
import './dashboard.scss';
import {
  getMoneysoftFinancialDetailsAsync,
  getMoneysoftInviteClientAsync,
} from '@store/reducers/moneysoft/thunks';
import { getSuperSimplifierAccountsAsync } from '@store/reducers/supersimplifier/thunks';
import { DateTime } from 'luxon';

const Home: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const dashboardDataStore: DashboardStore = useAppSelector(selectDashboard);
  const moneysoftStore: MoneysoftStore = useAppSelector(selectMoneysoft);
  const history = useHistory();

  const dashboardData = dashboardDataStore.data;
  const [chartData, setChartData] = useState<PerformanceChartData[]>([]);

  const getDashboardDataAsync = async () => {
    await dispatch(getDashboardAsync());
    await dispatch(
      getMoneysoftFinancialDetailsAsync({
        balanceAsAt: DateTime.now().toISO().split('T')[0],
      })
    );
  };

  const moneysoftInviteClient = async () => {
    await dispatch(getMoneysoftInviteClientAsync());
  };

  const handleNavigationToMoneysoft = () => {
    history.push(moneySoftUrl());
  };

  const { user, isLoading } = useAuth0<{
    email: string;
    name: string;
    // eslint-disable-next-line camelcase
    given_name: string;
    // eslint-disable-next-line camelcase
    family_name: string;
  }>();

  useEffect(() => {
    if (dashboardDataStore.state === ScreenState.SuccessDataLoaded) {
      if (Object.keys(dashboardData).length > 0) {
        const tempChartData: PerformanceChartData[] = [];
        if (dashboardData && dashboardData.valueOverTimeList) {
          dashboardData.valueOverTimeList.forEach((item) => {
            tempChartData.push({
              label: dateFormatter(item.date!, false, DateTimeFormats.FcDateFormat),
              value: item.value!,
            });
          });
        }
        setChartData(tempChartData);
      }
    } else {
      getDashboardDataAsync();
      dispatch(getSuperSimplifierAccountsAsync());
    }
  }, [dashboardData]);
  const RenderPortfolios: FC = (): JSX.Element | null => {
    if (dashboardData.portfolios.length <= 0) return null;
    if (dashboardData.portfolios.length === 1)
      return (
        <Grid container>
          <PortfolioCard
            {...dashboardData.portfolios[0]}
            key={dashboardData.portfolios[0].clientAccountKey}
            mode={ReturnValueDisplayMode.ValueAndPercentage}
            singleItem
          />
        </Grid>
      );
    return (
      <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={{ md: 2 }}>
        {dashboardData.portfolios.map((portfolio) => (
          <PortfolioCard
            {...portfolio}
            key={portfolio.portfolioKey}
            mode={ReturnValueDisplayMode.ValueAndPercentage}
          />
        ))}
      </Grid>
    );
  };

  if (!dashboardDataStore.state || dashboardDataStore.state === ScreenState.Loading)
    return <CommonLoader />;
  if (dashboardDataStore.state === ScreenState.ErrorUnableToLoadData) {
    return <UnableToLoadData onClick={getDashboardDataAsync} />;
  }

  let balanceFromMoneysoft = 0;

  if (moneysoftStore.moneysoftFinancialDetails?.financialAccountDetails?.length) {
    moneysoftStore.moneysoftFinancialDetails?.financialAccountDetails.forEach((lineItem) => {
      if (lineItem.assetType !== 2) {
        balanceFromMoneysoft += lineItem.currentBalance ?? 0;
      } else {
        balanceFromMoneysoft -= lineItem.currentBalance ?? 0;
      }
    });
  }

  const yourNetWorthTotal = (dashboardData.currentValue ?? 0) + balanceFromMoneysoft;

  return (
    <Container>
      {Object.keys(dashboardData).length === 0 ? (
        <Stack spacing={1}>
          <CustomSkeleton variant="text" />
          <CustomSkeleton variant="rectangular" height={150} numberOfItems={2} />
        </Stack>
      ) : (
        <Box className="dashboardContent">
          <Grid
            container
            className="portfolioContent"
            rowSpacing={{ xs: 2, md: 2 }}
            columnSpacing={{ xs: 2, md: 2 }}
          >
            <Grid
              item
              xs={12}
              md={3.5}
              mb={4}
              rowSpacing={{ xs: 2, md: 2 }}
              className="portfolioInfo"
            >
              <p className="name">Hi {!isLoading ? user?.given_name : ''}!</p>
              <h5>{strings.yourNetWorth}</h5>
              <p className="netWorth">{numberFormatterDollar(yourNetWorthTotal, 2)}</p>
              <h5>{`${strings.totalReturnDate} ${dateFormatter(dashboardData.inceptionDate)}`}</h5>
              <p className="netReturn">
                {numberFormatterDollar(dashboardData.totalReturnValue, 2)}
              </p>
            </Grid>
            <Grid item xs={12} md={8.5} className="portfolioChart">
              <AreaTimeSeriesChart chartData={chartData} screen={Screen.Portfolio} />
            </Grid>
          </Grid>
          <Box className="portfolioCards">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={{ md: 2 }}>
                <RenderPortfolios />
                {moneysoftStore.moneysoftFinancialDetails?.hasMoneysoftAccess && (
                  <Grid container>
                    <MoneysoftCard
                      name={dashboardData.name}
                      currentBalance={balanceFromMoneysoft}
                      hasAccount={moneysoftStore.moneysoftFinancialDetails?.hasAccount}
                      hasMoneysoftAccess={
                        moneysoftStore.moneysoftFinancialDetails?.hasMoneysoftAccess
                      }
                      moneysoftInviteClient={moneysoftInviteClient}
                      handleNavigationToMoneysoft={handleNavigationToMoneysoft}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default Home;
